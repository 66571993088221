import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import AttributesService from '@services/AttributesService';
import SpinnerAtom from '@atoms/Spinner';
import IAssetAttributesGroupFormOrganism from './interface';
import { FormProvider, useForm } from "react-hook-form";
import AttributesFormGroupMolecule from '@molecules/AttributesFormGroup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { MakeResolver } from '@utils/AttributesForm';
import { values } from 'lodash';
import { AttributeModel } from '@models/AttributeModel';

const AttributesGroupFormOrganism = ({ model, group, model_id, model_type, showTitle, onSave, personAssignedGroup }: IAssetAttributesGroupFormOrganism) => {
    
    const formInstance = useForm({
		mode: 'onChange',
		reValidateMode: 'onSubmit',
        resolver: yupResolver(MakeResolver(group, group.attributes))
	});

	const { getValues, formState, handleSubmit: handleHookSubmit } = formInstance;

    const appendFormValues = (formValues: any) => {
        const array = model.attributes.filter((group: AttributeModel) => group.id !== formValues[0].id);
        array.push(formValues[0]);
        return array;
    }

    const { mutate: saveAttributesMutation, isLoading } = useMutation((values: any) =>
        AttributesService.save(model_id, model_type, appendFormValues(values)).then((res: any) => {
            toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });
            // Feedback method
            if(onSave && !res.hasErrors()) {
                onSave(res.getData());
            }
        }));

    const handleSubmit = () => {
        let values: any = null;
        /**
         * TODO SPOSTA IN UN FOGLIO CENTRALIZZATO
         */
        if(Object.keys(getValues()).length) {
            values = [];
            let attributes = getValues()[group.alias].reduce((prevVal: any, currVal: any) => {
                let array: any = [];
                // Get the fields array
                Object.keys(currVal).forEach((id: any) => {
                    array.push({attribute_id: Number(id), value: currVal[id]});
                });
                prevVal.push(array);
                return prevVal;
            }, []);
            values.push({id: group.id, attributes: attributes});
        }
        saveAttributesMutation(values);
    };

    return (
        <>
            {
                group ? 
                <React.Fragment>
                    <Box>
                        <FormProvider {...formInstance }>
                            <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
                                <Box>
                                    <AttributesFormGroupMolecule
                                        formControl={formInstance.control}
                                        personAssignedGroup={personAssignedGroup}
                                        patch={true}
                                        showTitle={showTitle}
                                        group={group}/>
                                </Box>
                                {
                                    formState.isDirty ? 
                                        <Box mt={1} sx={{ textAlign: 'right'}}>
                                            <Button type='submit' variant='contained' color={'info'} disabled={!formState.isValid} sx={{display: 'flex', alignItems: 'center'}}>
                                                {
                                                    isLoading ?
                                                        <SpinnerAtom size={'18px'}/>
                                                    : <Typography>Save</Typography>
                                                }
                                            </Button>
                                        </Box>
                                    : null
                                }
                            </form>
                        </FormProvider>
                    </Box>
                </React.Fragment>
                : null
            }
    </>);
};

export default AttributesGroupFormOrganism;
