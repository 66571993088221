import { CoreBaseService } from '@core/services/Base.service';

// Reference model
import { CertificationModel } from '@models/CertificationModel';

class CertificatiosService extends CoreBaseService {
    constructor() {
        super(CertificationModel);
    }

    update(values: any, certification_id: number) {
        return this.put(values, certification_id);
    }
}

export default new CertificatiosService();
