import { CoreBaseModel } from '@core/models/Base.model';

export class AssetsModel extends CoreBaseModel {
    available: boolean;
    category: any;
    category_id: number;
    created_at: string;
    id: number;
    location: any;
    location_id: number;
    name: string;
    status: any;
    status_id: number;
    type: any;
    type_id: number;
    updated_at: string;
    deleted: boolean;
    attributes: Array<any>;

    constructor(data: any) {
        super();
        if (data) {
            Object.assign(this, data);
        }
        if(!this.attributes) {
            this.attributes = [];
        }
    }

    static getResourceName() {
        return 'assets';
    }

    getAttributes(alias?: string) {
        if(alias && Array.isArray(this.attributes)) {
            const group = this.attributes.find((group: any) => group.alias === alias);
            if(group) return group.attributes;
            return [];
        } else {
            return this.attributes ? this.attributes : [];
        }
    }
}
