import { styled } from '@mui/system';


export const StyledTimelineContainer = styled('div')(() => ({
    fontFamily: '"PT Sans", sans-serif',
    width: '100%',
    margin: 'auto',
    display: 'block',
    position: 'relative',
    '& ul': {
        padding: 0,
        margin: 0,
        marginTop: '1em',
        display: 'inline-block',
        width: '100%',
        '& li': {
            listStyle: 'none',
            margin: 'auto',
            marginLeft: '1em',
            minHeight: '50px',
            borderLeft: '1px dashed rgb(56, 56, 56)',
            padding: '0 0 50px 30px',
            position: 'relative',
            '&:last-child': {
                borderLeft: 0
            },
            '&:before': {
                position: 'absolute',
                left: '-18px',
                top: '-5px',
                content: '" "',
                border: '8px solid rgb(239 239 239)',
                borderRadius: '500%',
                background: '#1565c0',
                height: '20px',
                width: '20px',
                transition: 'all 500ms ease-in-out'
            }
        }
    }
}));