import * as React from 'react';
import { Grid, Box, Button, CircularProgress, Alert, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectMolecule }  from '@molecules/AsyncSelect';
import { Trans } from '@lingui/macro';
import TextFieldAtom from '@atoms/TextField';
import IActivityCreateOrganism, { IFormValues } from './interface';
import createActivitySchema from '@validation/createActivity';
import { FormProvider, useForm } from "react-hook-form";
import DateFieldMolecule from '@molecules/DateField';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import ActivitiesService from '@services/ActivitiesService';
import SwitchMolecule from '@molecules/Switch';
import moment from 'moment';
import { AutocompleteMolecule } from '@molecules/AsyncAutocomplete';
import { UserModel } from '@models/UserModel';
import { IDS } from '@utils/constants';
import { ClientModel } from '@models/ClientModel';
import { fetchFilter } from '@stores/reducers/filtersReducer';
import { useAppDispatch } from '@stores/hooks';
import { useSelector } from 'react-redux';

/**
 * Including a double-layout mode ('patch' and 'normal')
 * @param mode 
 * @returns 
 */
const ActivityCreateOrganism = ({ mode, activity, onNewActivity }: IActivityCreateOrganism) => {
	
	const [ sErrors, setSErrors ] = React.useState(null);
	const [ msg, setMsg ] = React.useState('');
	const [ success, setSuccess ] = React.useState<boolean>(false);
	const notificationChannel = useSelector((state: any) => state.app.notificationChannel);

	const dispatch = useAppDispatch();

	const formInstance = useForm<IFormValues>({
		defaultValues: {
			type_id: activity ? activity.type_id ? activity.type_id : undefined : '',
			category_id: activity ? activity.category_id ? activity.category_id : 1 : 1,
			date: activity ? activity.date ? activity.date : undefined : undefined,
			user_id: activity ? activity.user_id ? activity.user_id : undefined : undefined,
			partner_id: activity ? activity.partner_id ? activity.partner_id : undefined : undefined,
			asset_id: activity ? activity.asset_id ? activity.asset_id : '' : '',
			customer_id: activity ? activity.customer_id ? activity.customer_id : undefined : undefined,
			client_job_id: activity ? activity.client_job_id ? activity.client_job_id : undefined : undefined,
			job_id: activity ? activity.job_id ? activity.job_id : undefined : undefined,
			is_purchase: activity ? activity.is_purchase ? true : false : false,
			notes: ''
		},
		mode: 'onChange',
		reValidateMode: 'onSubmit',
		resolver: yupResolver(createActivitySchema),
	});
	
	const { mutate: createActivityMutation, isLoading } = useMutation(
		(formValues:IFormValues) => (mode === 'patch' && activity
			? ActivitiesService.put(formValues, activity.id)
			: ActivitiesService.create(formValues)).then((res: any) => {
			setMsg(res.getMsgString())
			if(!res.hasErrors()) {
				dispatch(fetchFilter('activities'));
				if(mode !== 'patch' && onNewActivity) {
					onNewActivity(res.getData())
				}
				setSuccess(true);
				// Set notification
				notificationChannel.trigger(`client-inspector-notification`, {
					type: 'message',
					toId: res.getData().user.id,
					action: 'new-activity'
				});
				toast(res.getMsgString(), {
					type: 'success'
				});
			} else {
				setSErrors(res.getErrors())
				toast(res.getMsgString(), {
					type: 'error'
				});
			}
		})
	);

	const {
		control,
		getValues,
		setValue,
		watch,
		formState: { isValid },
		handleSubmit: handleHookSubmit,
	} = formInstance;

	const inspector = watch('is_purchase');

	React.useEffect(() => {
		if(!inspector) {
			setValue('partner_id', null);
		}
	},
	[inspector, setValue]);

	const handleSubmit = (data: IFormValues) => {
		const values = {...data};
		if(data.date) {
			values.date = moment(data.date).format('YYYY/MM/DD');
		}
		createActivityMutation(values);
	}

	return (
		<Box sx={{ width: '100%' }}>
			<form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
				<FormProvider {...formInstance }>
					{
						success ? (
							<React.Fragment>
								{
									msg ? 
										<Box mt={2}><Alert severity="success">{ msg }</Alert></Box>
									: null
								}
							</React.Fragment>
						) : (
						<React.Fragment>
							{ /* Step 1 */ }
							<Box>
								{
									mode !== 'patch' ?
										<Typography><b><Trans>Core info</Trans></b></Typography>
									: null
								}
								<Grid container mt={1} spacing={2}>
									<Grid item xs={12} sm={12} md={mode === 'patch' ? 3 : 6}>
										<SelectMolecule
											required={true}
											control={control}
											sError={sErrors ? sErrors['type_id'] : undefined}
											controlName="type_id"
											storeName={'activities'}
											storeCollection="types.activities"
											optionValue={'id'}
											variant={'outlined'}
											label={<Trans>Activity type</Trans>}
										></SelectMolecule>
									</Grid>
									<Grid item xs={12} sm={12} md={mode === 'patch' ? 3 : 6}>
										<DateFieldMolecule
											control={control}
											controlName={'date'}
											inputFormat={'dd/MM/yyyy'}
											label={<Trans>Date</Trans>}
											sError={sErrors ? sErrors['date'] : undefined}
										></DateFieldMolecule>
									</Grid>
									{
										mode === 'patch' ?
											<Grid item xs={12} sm={12} md={3}>
												<SelectMolecule
													control={control}
													controlName="user_id"
													sError={sErrors ? sErrors['user_id'] : undefined}
													storeName={'activities'}
													optionValue={'id'}
													except={(user: UserModel) => user.role ? user.role.id !== IDS.ROLES.ADMIN : false}
													storeCollection="users"
													optionLabel={(user) => `${user.name} ${user.surname ? user.surname : ''}`}
													variant={'outlined'}
													label={<Trans>Owner</Trans>}
												></SelectMolecule>
											</Grid>
										: null
									}
									{
										mode === 'patch' ?
											<Grid item xs={12} sm={12} md={3}>
												<SelectMolecule
													control={control}
													controlName="asset_id"
													sError={sErrors ? sErrors['asset_id'] : undefined}
													storeName={'activities'}
													optionValue={'id'}
													storeCollection="assets"
													optionLabel="name"
													variant={'outlined'}
													label={<Trans>Asset</Trans>}
												></SelectMolecule>
											</Grid>
										: null
									}
								</Grid>
								{
									mode !== 'patch' ?
										<Grid container mt={1} spacing={2}>
											<Grid item xs={12} sm={12} md={6}>
												<AutocompleteMolecule
													control={control}
													controlName="user_id"
													listId={'activities-create-owner'}
													storeCollection="users"
													variant={'outlined'}
													except={(user: UserModel) => user.role ? user.role.id !== IDS.ROLES.USER : false}
													optionValue={'id'}
													emptyValue={{full_name: ''}}
													getOptionLabel={(user:any) => `${user.full_name}`}
													optionLabel={'name'}
													label={<Trans>Owner</Trans>}
												></AutocompleteMolecule>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<AutocompleteMolecule
													control={control}
													controlName="asset_id"
													listId={'activities-create-asset'}
													sError={sErrors ? sErrors['asset_id'] : undefined}
													storeCollection="assets"
													variant={'outlined'}
													optionValue={'id'}
													emptyValue={{name: ''}}
													getOptionLabel={(asset:any) => `${asset.name}`}
													optionLabel={'name'}
													label={<Trans>Asset</Trans>}
												></AutocompleteMolecule>
											</Grid>
										</Grid>
									: null
								}

								<Grid container mt={1} spacing={2}>
									<Grid item xs={12} sm={12} md={mode === 'patch' ? 3 : 6}>
										<AutocompleteMolecule
											control={control}
											controlName="customer_id"
											listId={'activities-create-customer'}
											sError={sErrors ? sErrors['customer_id'] : undefined}
											storeCollection="customers"
											variant={'outlined'}
											except={(client: ClientModel) => client.type ? client.type.id !== IDS.CUSTOMERS.CLIENT : false}
											optionValue={'id'}
											emptyValue={{name: ''}}
											getOptionLabel={(asset:any) => `${asset.name}`}
											optionLabel={'name'}
											label={<Trans>Client</Trans>}
										></AutocompleteMolecule>
									</Grid>
									<Grid item xs={12} sm={12} md={mode === 'patch' ? 3 : 6}>
										<SelectMolecule
											control={control}
											controlName="category_id"
											sError={sErrors ? sErrors['category_id'] : undefined}
											optionValue={'id'}
											storeCollection="categories.activities"
											variant={'outlined'}
											label={<Trans>Status</Trans>}
										></SelectMolecule>
									</Grid>

									{
										mode === 'patch' ?
										<>
											<Grid item xs={12} sm={12} md={3}>
												<TextFieldAtom
													controlName={'job_id'}
													variant={'outlined'}
													label={<Trans>Job id</Trans>}/>
													{
														sErrors && sErrors['job_id'] ? 
															<Alert severity='error' icon={false}>
																{ sErrors['job_id'] }
															</Alert>
														: null
													}
												<TextFieldAtom
													controlName={'client_job_id'}
													variant={'outlined'}
													label={<Trans>Client job id</Trans>}/>
													{
														sErrors && sErrors['client_job_id'] ? 
															<Alert severity='error' icon={false}>
																{ sErrors['client_job_id'] }
															</Alert>
														: null
													}
											</Grid>
											
											<Grid item xs={12} sm={12} md={6}>
												
											</Grid>
										</>
									: null
									}
								</Grid>
								<Grid container mt={1} spacing={2}>
									<Grid item xs={12} sm={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
										<SwitchMolecule
											controlName={'is_purchase'}
											control={control}
											label={'Purchase inspector'}
										/>
										{
											sErrors && sErrors['is_purchase'] ? 
												<Alert severity='error' icon={false}>
													{ sErrors['is_purchase'] }
												</Alert>
											: null
										}
									</Grid>
									<Grid item md={6}>
									{
										getValues('is_purchase') ? 
											<AutocompleteMolecule
												control={control}
												controlName="partner_id"
												sError={sErrors ? sErrors['partner_id'] : undefined}
												listId={'activities-create-partner'}
												storeCollection="partners"
												variant={'outlined'}
												emptyValue={{name: ''}}
												optionValue={'id'}
												getOptionLabel={(partner:any) => `${partner.name}`}
												optionLabel={'name'}
												label={<Trans>Partner</Trans>}
											></AutocompleteMolecule>
										: null
									}
									</Grid>
								</Grid>
							</Box>
							{ /* Step 2 */ }
							<Box mt={2}>
								{
									mode !== 'patch' ?
										<Typography><b><Trans>Extra</Trans></b></Typography>
									: null
								}
								{
									mode !== 'patch' ?
										<Grid container mt={1} spacing={2}>
											<Grid item xs={12} sm={12} md={4}>
												<TextFieldAtom
													controlName={'job_id'}
													variant={'outlined'}
													label={<Trans>Job id</Trans>}/>
													{
														sErrors && sErrors['job_id'] ? 
															<Alert severity='error' icon={false}>
																{ sErrors['job_id'] }
															</Alert>
														: null
													}
											</Grid>
											<Grid item xs={12} sm={12} md={4}>
												<TextFieldAtom
													controlName={'client_job_id'}
													variant={'outlined'}
													label={<Trans>Client job id</Trans>}/>
													{
														sErrors && sErrors['client_job_id'] ? 
															<Alert severity='error' icon={false}>
																{ sErrors['client_job_id'] }
															</Alert>
														: null
													}
											</Grid>
											<Grid item sx={{display: 'flex', alignItems: 'center'}} xs={12} sm={12} md={4}>
												
											</Grid>
										</Grid>
									: null
								}
								{
									mode !== 'patch' ? 
										<Grid container mt={1} spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12}>
												<TextFieldAtom
													controlName={'notes'}
													maxRows={4}
													minRows={2}
													variant={'outlined'}
													label={<Trans>Notes</Trans>}
													multiline={true}
												/>
												{
													sErrors && sErrors['notes'] ? 
														<Alert severity='error' icon={false}>
															{ sErrors['notes'] }
														</Alert>
													: null
												}
											</Grid>
										</Grid>
									: null
								}
								{
									sErrors ? 
										<Alert severity="error">{ msg }</Alert>
									: null
								}
								<Box mt={2} sx={{textAlign:'right'}}>
									<Button type="submit" disabled={!isValid} variant="contained">
									{
										isLoading ?
											<CircularProgress />
										: mode === 'patch' ? <Trans>Update activity</Trans> : <Trans>Create activity</Trans>
									}
									</Button>
								</Box>
							</Box>
						</React.Fragment>)
					}
				</FormProvider>
			</form>
		</Box>
	);
}

export default ActivityCreateOrganism;