import { useState, Fragment, useMemo, useCallback } from 'react';
import { Grid, Button, Typography, Drawer } from '@mui/material';
import { Trans, t } from '@lingui/macro';
import ContainerAtom from '@atoms/Container';
import LayoutBase from '@layouts/base';
import BoxTotalAtom from '@atoms/BoxTotal';
import { PageHeader, StyledGridItem, StyledHeaderContainer } from './styled';
import { filterSelector, filtersTypeSelector, filtersCategoriesSelector } from '@stores/selectors/filtersSelector';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@molecules/Modal';
import NewPersonOrganism from '@organisms/PersonCreate';
import LayoutModal from '@layouts/modal';
import PaginatedListMolecule from '@molecules/PaginatedList';
import { IMBOption } from '@molecules/MultiButtonMolecule/interface';
import PeopleDetailOrganism from '@organisms/PeopleDetail';
import { useQuery } from 'react-query';
import PeopleService from '@services/PeopleService';
import { toast } from 'react-toastify';
import DrawerLayout from '@layouts/drawer';
import type { IPeopleFilterProps } from '@organisms/PeopleFilter/interface';
import { peopleFilterInitialValues } from './data';
import PeopleFilterOrganism from '@organisms/PeopleFilter';
import PeopleRowMolecule from '@molecules/PeopleRow';
import { PeopleModel } from '@models/PeopleModel';
import Stack from '@mui/material/Stack';
import { useConfirm } from 'material-ui-confirm';
import SelectedFilterMolecule from '@molecules/SelectedFilter';
import type { ISelectedFilterItem } from '@molecules/SelectedFilter/interface';
import { useAppSelector } from '@stores/hooks';
import isEqual from 'lodash/isEqual';
import { deleteItem } from '@stores/reducers/filtersReducer';
import { useAppDispatch } from '@stores/hooks';


const ActivitiesPage = () => {

    const dispatch = useAppDispatch();
    const [ filter, setFilter ] = useState<IPeopleFilterProps>(peopleFilterInitialValues);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ drawerDetailID, setDrawerDetailID ] = useState<any>({});
    const locationsData = useAppSelector(filterSelector('locations'));
    const [ state, setState ] = useState({right: false});
    const [ totals, setTotals ] = useState<any>({});
    const [ newRow, setNewRows ] = useState<PeopleModel | undefined>(undefined);
    const [ toDelete, setToDeleteFromList ] = useState<number | undefined>(undefined)
    const [ updatedItem, setUpdated ] = useState<PeopleModel | undefined>(undefined);
    const isNameChanged = !isEqual(filter.search, peopleFilterInitialValues.search);
    const typesData = useAppSelector(filtersTypeSelector('users'));
    const rolesData = useAppSelector(filtersCategoriesSelector('users'));
    const isLocationChanged = !isEqual(filter.location_id, peopleFilterInitialValues.location_id);
    const isRoleChanged = !isEqual(filter.category_id, peopleFilterInitialValues.category_id);
    const isTypeChanged = !isEqual(filter.type_id, peopleFilterInitialValues.type_id);
    const isInactiveChanged = !isEqual(filter.inactive, peopleFilterInitialValues.inactive);
    const confirm = useConfirm();

    const toggleDrawer =
        (anchor: any, open: boolean) =>
        (event: KeyboardEvent | MouseEvent) => {
          if (
              event.type === 'keydown' &&
              ((event as KeyboardEvent).key === 'Tab' ||
                (event as KeyboardEvent).key === 'Shift')
          ) {
              return;
          }
          setState({ ...state, [anchor]: open });
    };
    
	useQuery('activities-total', () => PeopleService.getTotals().then((res: any) => { setTotals(res.getData()) }), {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        refetchOnMount: false
    });

    const handleActionSelect = (param: IMBOption) => {
        if(param.label === 'Details') {
            setDrawerDetailID(param.data.personId);
            setState({ ...state, right: true });
        } else if (param.label === 'Delete') {
            confirm({ description: 'This action is permanent!' })
            .then(() => { 
                PeopleService.delete(param.data.personId).then((res: any) => {
                    toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });
                    if(!res.hasErrors()) {
                        setToDeleteFromList(param.data.personId);
                        // Remove from store
                        dispatch(deleteItem({what: 'users', id: param.data.personId}));
                    }
                });
            });
        }
    }

    const locations = useMemo(() => {
        return locationsData.map((location) => ({
            ...location,
            value: location.id,
            label: location.name,
        }));
    }, [locationsData]);
    
    const types = useMemo(() => {
        return typesData.map((type) => ({
            ...type,
            value: type.id,
            label: type.name,
        }));
    }, [typesData]);

    const roles = useMemo(() => {
        return rolesData.map((role) => ({
            ...role,
            value: role.id,
            label: role.name,
        }));
    }, [rolesData]);

    const filterSelections = useMemo((): ISelectedFilterItem[] => {
        return [
            { label: `${t`Name`}: ${filter.search}`, value: 'search', isChanged: isNameChanged },
            {
                label: `${t`Department`}: ${
                    (locations.find((location) => location.id === Number(filter.location_id)) || { name: '' }).name
                }`,
                value: 'location_id',
                isChanged: isLocationChanged,
            },
            // TODO fix non si rimuove !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            {
                label: `${t`Role`}: ${
                    (roles.find((role) => role.id === Number(filter.category_id)) || { name: '' }).name
                }`,
                value: 'category_id',
                isChanged: isRoleChanged,
            },
            {
                label: `${t`Seniority`}: ${
                    (types.find((type) => type.id === Number(filter.type_id)) || { name: '' }).name
                }`,
                value: 'type_id',
                isChanged: isTypeChanged,
            },
            {
                label: `${t`Inactive`}: ${filter.inactive ? t`inactive` : t`active`}`,
                value: 'inactive',
                isChanged: isInactiveChanged,
            },
        ];
    }, [filter, locations, types, roles, isNameChanged, isRoleChanged, isLocationChanged, isTypeChanged, isInactiveChanged]);


    const handleDeleteFilter = useCallback((key: string | 'all') => {
        switch (key) {
            case 'all':
                setFilter(peopleFilterInitialValues);
                break;

            default:
                setFilter((prevState) => ({
                    ...prevState,
                    [key]: peopleFilterInitialValues[key as keyof IPeopleFilterProps],
                }));
        }
    }, []);

    return (
        <>
            <Fragment key={'right'}>
                <Drawer
                  anchor={'right'}
                  open={state.right}
                  onClose={toggleDrawer('right', false)}>
                  <DrawerLayout onCloseClick={() => setState({right: false})}>
                    <PeopleDetailOrganism onUpdate={setUpdated} id={drawerDetailID} />
                  </DrawerLayout>
                </Drawer>
            </Fragment>
            <LayoutBase>
                <ContainerAtom>
                    <PageHeader>
						<Typography variant="h4"><Trans>People</Trans></Typography>
						<Button onClick={() => setModalOpen(true)} variant="outlined"><AddIcon></AddIcon> <Trans>New person</Trans></Button>
                    </PageHeader>
                    <Modal open={modalOpen} set_open={setModalOpen}>
                        <LayoutModal title={<Trans>New person</Trans>}>
                          <NewPersonOrganism
                            onNewPerson={(person: PeopleModel) => setNewRows(person)}/>
                        </LayoutModal>
                    </Modal>
                    <Grid my={2} container spacing={1}>
                        {
                          Object.keys(totals).map((totalKey, index) => {
                              return (
                                <Grid key={index} item md={totalKey !== 'total' ? 4 : 4} xs={6}>
                                    <BoxTotalAtom 
                                        totalInteger={totals[totalKey]} 
                                        label={
                                            totalKey === 'active' ? 
                                                <Trans>Active resources</Trans> : 
                                            totalKey === 'all' ? 
                                                <Trans>All</Trans> : 
                                            totalKey === 'expired_certifications' ? 
                                                <Trans>With expiring certificates</Trans> :  
                                            totalKey
                                        } 
                                    />
                                </Grid>
                              )
                          })
                        }
                    </Grid>
                    <Stack mb={2} direction="row" spacing={2} alignItems="center">
                        <SelectedFilterMolecule items={filterSelections} handleDelete={handleDeleteFilter} />
                    </Stack>
                    <Grid container spacing={3}>
                        <Grid item md={3} xs={12}>
                            <PeopleFilterOrganism
                                filter={filter}
                                setFilter={setFilter}
                            />
                        </Grid>
                        <Grid item md={9} xs={12}>
                            {filter && (
                                <PaginatedListMolecule
                                    service={PeopleService}
                                    newRow={newRow}
                                    updated={updatedItem}
                                    perPage={10}
                                    idToDelete={toDelete}
                                    filter={filter}
                                    renderFunc={(item: PeopleModel) => {
                                        return <PeopleRowMolecule person={item} onActionSelected={handleActionSelect} />;
                                    }}
                                    header={
                                        <StyledHeaderContainer sx={{ paddingY: '10px' }}>
                                            <Grid container spacing={0}>
                                                <StyledGridItem sx={{paddingLeft: '5px'}} item md={2} xs={12}>
                                                    <Trans>Internal</Trans>
                                                </StyledGridItem>
                                                <StyledGridItem item md={2} xs={12}>
                                                    <Trans>Name</Trans>
                                                </StyledGridItem>
                                                <StyledGridItem item md={2} xs={12}>
                                                    <Trans>Role</Trans>
                                                </StyledGridItem>
                                                <StyledGridItem item md={2} xs={12}>
                                                    <Trans>Seniority</Trans>
                                                </StyledGridItem>
                                                <StyledGridItem item md={2} xs={12}>
                                                    <Trans>Department</Trans>
                                                </StyledGridItem>
                                                <StyledGridItem item md={2} xs={12}></StyledGridItem>
                                            </Grid>
                                        </StyledHeaderContainer>
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                </ContainerAtom>
            </LayoutBase>
        </>
    )
}

export default ActivitiesPage