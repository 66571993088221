import React, { useEffect} from 'react';
import Typography from '@mui/material/Typography';
import { Tab, Box, Tabs } from '@mui/material';
import ITabsMolecule, { ITabOptions } from './interface';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const TabsMolecule = ({ tabsLabels, onChange }: ITabsMolecule) => {

    const [value, setValue] = React.useState(0);

    useEffect(() => {
      if(onChange) {
        onChange(tabsLabels[value]);
      }
    }, [value]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                  {
                      tabsLabels.map((tab:ITabOptions, index:number) => {
                          return (<Tab key={index} label={tab.label} {...a11yProps(index)} />)
                      })
                  }
                </Tabs>
            </Box>
            {
                tabsLabels.map((tab:ITabOptions, index:number) => {
                  if(tab.child) {
                    return (
                        <TabPanel key={index} value={value} index={index}>
                          { tab.child }
                        </TabPanel>
                    )
                  }
                })
            }
        </>
    )
}

export default TabsMolecule;