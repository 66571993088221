import { useState } from 'react';
import type INoteDetail from './interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import TextFieldAtom from '@atoms/TextField';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';
import NotesService from '@services/NotesService';
import { useUser } from '@hooks';
import DatePrintAtom from '@atoms/DatePrint';

const schemaA = Yup.object().shape({
    text: Yup.string(),
});

interface IFormValues {
    text: string;
}

const NoteDetailOrganism = ({ note, onSave }: INoteDetail) => {

    const user = useUser();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formInstance = useForm<IFormValues>({
        mode: 'onChange',
        defaultValues: { text: note.text },
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schemaA),
    });

    const {
        formState: { isValid, isDirty },
        handleSubmit: handleHookSubmit,
    } = formInstance;

    const handleSubmit = (formValues: any) => {
        setIsLoading(true);
        NotesService.put(formValues, note.id).then((res: any) => {
            setIsLoading(false);
            toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });

            if (!res.hasErrors()) {
                onSave?.();
            }
        });
    };

    return (
        <>
            <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
                <FormProvider {...formInstance}>
                    <Box sx={{ minWidth: '350px' }}>
                        <TextFieldAtom
                            controlName={'text'}
                            inputProps={
                                { readOnly: ! user.can('notes.edit') }
                            }
                            maxRows={6}
                            minRows={2}
                            variant={'outlined'}
                            label={<Trans>Note</Trans>}
                            multiline={true}
                        />
                    </Box>
                    <Typography variant="caption">
                        <DatePrintAtom format="DD/MM/YYYY">{note.created_at}</DatePrintAtom>
                    </Typography>
                </FormProvider>
                {isDirty && user.can('notes.edit') && (
                    <Box mt={1} sx={{ textAlign: 'right' }}>
                        <Button type="submit" disabled={!isValid} variant="contained">
                            {isLoading ? <CircularProgress /> : <Trans>Update</Trans>}
                        </Button>
                    </Box>
                )}
            </form>
        </>
    );
};

export default NoteDetailOrganism;
