import Grid from '@mui/material/Grid';
import { Link, useLocation } from "react-router-dom";
import { Trans } from '@lingui/macro';
import LayoutBase from '@layouts/base';
import { StyledMenuContainer } from './styled';
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useSelector } from 'react-redux';
import { MenuModel } from '@models/MenuModel';


const LayoutSettings = ({ children }: any) => {

    const location = useLocation();

    const settingsPage = useSelector(
        (state: any) => state.menu.items.find((item: MenuModel) => item.slug === 'settings'));

    return (
        <>
            <LayoutBase withoutContainer={true}>
                <Grid container>
                    <Grid sx={{ height: '100%', paddingTop: '65px', position: 'sticky', top: 0}} item md={2} sm={12} paddingX={'10px'}>
                        <StyledMenuContainer>
                            <Paper variant='outlined' elevation={0}>
                                <MenuList
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                >
                                    {
                                        settingsPage && settingsPage.children ?
                                            settingsPage.children.map((page: MenuModel) => {
                                                return (<Link className={`${ location.pathname === page.route ? 'active' : ''}`} to={page.route}>
                                                <MenuItem>
                                                    { page.name }
                                                </MenuItem>
                                            </Link>)
                                            })
                                        : null
                                    }
                                    
                                </MenuList>
                            </Paper>    
                        </StyledMenuContainer>
                    </Grid>
                    <Grid paddingY={'60px'} paddingX={'10px'} item sm={12} md={10}>
                        { children }
                    </Grid>
                </Grid>
            </LayoutBase>
        </>
    )
}


export default LayoutSettings;