import ActivityDetailFormOrganism from '@organisms/ActivityDetailForm';
import IActivityDetailResumeOrganism from './interface';
import { Box, Grid, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import { StyledValueTitle, StyledValueText } from './styled';
import ChipAtom from '@atoms/Chip';
import DatePrintAtom from '@atoms/DatePrint';

const ActivityDetailResumeOrganism = ({ activity, onSave, isEdit, onCancel, hide, verbose }: IActivityDetailResumeOrganism) => {

  return (
    <>
        {
            activity && isEdit && <ActivityDetailFormOrganism onCancel={onCancel} onSave={onSave} mode="patch" activity={activity}></ActivityDetailFormOrganism>
        }
        {
            activity && !isEdit && <Box>
                <Grid container>
                    <Grid item md={6} sm={6} xs={6}>
                        <Box>
                            <StyledValueTitle variant='h6'>
                                <Trans>Internal job ID</Trans>
                            </StyledValueTitle>
                            <StyledValueText>{ activity?.job_id ? activity?.job_id : '--' }</StyledValueText>
                        </Box>
                    </Grid>
                    <Grid item md={verbose.includes('asset.make') ? 4 : 6} sm={verbose.includes('asset.make') ? 4 : 6} xs={verbose.includes('asset.make') ? 4 : 6}>
                        <StyledValueTitle variant='h6'>Department</StyledValueTitle>
                        <StyledValueText>
                            { activity.asset?.location ? activity.asset.location.name : '--' }
                        </StyledValueText>
                    </Grid>
                </Grid>
                <Box>
                {
                    ! hide.includes('owner') && 
                        <Grid container mt={1} spacing={2}>
                            <Grid item md={6} sm={6} xs={6}>
                                <StyledValueTitle variant='h6'>
                                    <Trans>Owner</Trans>
                                </StyledValueTitle>
                                <StyledValueText>{ activity.user ? activity.user.full_name : '--' }</StyledValueText>
                            </Grid>
                            <Grid item sm={12} md={6} xs={6}>
                                <Grid container>
                                    <Grid item md={6}>
                                        <StyledValueTitle variant='caption'>Role</StyledValueTitle>
                                        <StyledValueText>
                                            { activity.user?.category ? activity.user?.category.name : '--' }
                                        </StyledValueText>
                                    </Grid>
                                    <Grid item md={6}>
                                        <StyledValueTitle variant='caption'>Seniority</StyledValueTitle>
                                        <StyledValueText>
                                            { activity.user?.type ? activity.user.type.name : '--' }
                                        </StyledValueText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>	
                    }
                    <Grid container mt={1} spacing={2}>
                        <Grid item sm={12} md={verbose.includes('asset.make') ? 3 : 6} xs={verbose.includes('asset.make') ? 3 : 6}>
                            <StyledValueTitle variant='h6'>
                                <Trans>Asset</Trans>
                            </StyledValueTitle>
                            <StyledValueText>{ activity.asset?.name ? activity.asset.name : '--' }</StyledValueText>
                        </Grid>
                        <Grid item sm={12} md={verbose.includes('asset.make') ? 9 : 6} xs={verbose.includes('asset.make') ? 9 : 6}>
                            <Grid container spacing={1}>
                                {
                                    verbose.includes('asset.make') ? 
                                        <Grid item md={4} sm={4} xs={4}>
                                            <StyledValueTitle variant='h6'>Make</StyledValueTitle>
                                            <StyledValueText>
                                                { activity.asset?.type ? activity.asset.type.name : '--' }
                                            </StyledValueText>
                                        </Grid>
                                    : null
                                }
                                <Grid item md={verbose.includes('asset.make') ? 4 : 6} sm={verbose.includes('asset.make') ? 4 : 6} xs={verbose.includes('asset.make') ? 4 : 6}>
                                    <StyledValueTitle variant='h6'>Category</StyledValueTitle>
                                    <StyledValueText>
                                        { activity.asset?.category ? activity.asset.category.name : '--' }
                                    </StyledValueText>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container mt={1} spacing={2}>
                        <Grid item sm={12} md={6} xs={6}>
                            <StyledValueTitle variant='h6'>
                                <Trans>Activity type</Trans>
                            </StyledValueTitle>
                            <StyledValueText>
                                <ChipAtom color={'info'} variant='filled' label={activity.type ? activity.type.name : '--'} />
                            </StyledValueText>
                        </Grid>
                        <Grid item sm={12} md={6} xs={6}>
                            <StyledValueTitle variant='h6'>
                                <Trans>Date</Trans>
                            </StyledValueTitle>
                            <StyledValueText>
                            {
                                activity.date ? 
                                    <DatePrintAtom format='DD/MM/yyyy'>{ activity.date }</DatePrintAtom>
                                : '--'
                            }
                            </StyledValueText>
                        </Grid>
                    </Grid>
                    <Grid container mt={1} spacing={2}>
                        <Grid item sm={12} md={6}>
                            <StyledValueTitle variant='h6'>
                                <Trans>Job ID</Trans>
                            </StyledValueTitle>
                            { activity.job_id ? activity.job_id  : '--' }
                        </Grid>
                        <Grid item sm={12} md={6}></Grid>
                    </Grid>
                    <Grid container mt={1} spacing={2}>
                        <Grid item sm={12} md={6} xs={6}>
                            <StyledValueTitle variant='h6'>
                                <Trans>Client</Trans>
                            </StyledValueTitle>
                            <StyledValueText>
                                {
                                    activity.customer ? activity.customer.name : '--'
                                }
                            </StyledValueText>
                        </Grid>
                        <Grid item sm={12} md={6} xs={6}>
                            <StyledValueTitle variant='h6'>
                                <Trans>Client job ID</Trans>
                            </StyledValueTitle>
                            <StyledValueText>
                                {
                                    activity.client_job_id ? activity.client_job_id : '--'
                                }
                            </StyledValueText>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        {
                            ! hide.includes('purchase') && 
                                <Grid item md={6} xs={6}>
                                    <StyledValueTitle variant='h6'>
                                        <Trans>Is purchase</Trans>
                                    </StyledValueTitle>
                                    <StyledValueText>
                                        {
                                            activity.is_purchase ? <Trans>Yes</Trans> : <Trans>No</Trans>
                                        }
                                    </StyledValueText>
                                </Grid>
                        }
                        {
                            ! hide.includes('partner') && 
                                <Grid item md={6} xs={6}>
                                    <StyledValueTitle variant='h6'>
                                        <Trans>Partner</Trans>
                                    </StyledValueTitle>
                                    <StyledValueText>
                                    {
                                        activity.is_purchase ?
                                            activity.partner ? activity.partner.name : '--'
                                        : null
                                    }
                                    </StyledValueText>
                                </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
        }
    </>
  )
};

ActivityDetailResumeOrganism.defaultProps = {
    hide: [],
    verbose: []
};

export default ActivityDetailResumeOrganism;
