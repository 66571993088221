import { Grid, Paper, Box } from '@mui/material';
import { StyledProviderRow } from './styled';
import IProviderRow from './interface';
import MultiButtonMolecule from '@molecules/MultiButtonMolecule';

const ProviderRowMolecule = ({ provider, onActionSelected }: IProviderRow) => {

  const actionSelected = (item: any) => {
    if(onActionSelected) {
      onActionSelected(item, provider);
    }
  }

  return (
    <>
        <Paper sx={{border: '0px'}} elevation={0} variant='outlined'>
			<StyledProviderRow>
				<Grid container sx={{alignItems: 'center'}}>
					<Grid item sx={{display: 'flex', alignItems: 'center'}} md={6} xs={12}>
						<Box mr={1}>
							{ provider.name ? provider.name : '' }
						</Box>
					</Grid>
					<Grid item md={2} xs={12}>
						{ provider.email ? provider.email : '--' }
					</Grid>
					<Grid item md={2} xs={12}>
						{ provider.phone ? provider.phone : '--' }
					</Grid>
					<Grid sx={{textAlign: 'right'}} item md={2} xs={12}>
						<MultiButtonMolecule onSelected={actionSelected} options={[
							{label: 'Edit', id: 1, data: { provider }},
							{label: 'Delete', id: 4, data: { provider }}]}
						/>
					</Grid>
				</Grid>
			</StyledProviderRow>
		</Paper>
    </>
  )
}


export default ProviderRowMolecule;