
import { CoreBaseService } from '@core/services/Base.service';

// Reference model
import { ClientModel } from '@models/ClientModel';


class ClientsService extends CoreBaseService {

    constructor() {
        super(ClientModel);
    }
  
}

export default new ClientsService();