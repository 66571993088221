import { CoreBaseModel } from '@core/models/Base.model';

export class ChatModel extends CoreBaseModel {

    

    constructor(data: any) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    static getResourceName() {
        return 'chats';
    }

}