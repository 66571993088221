import { memo } from 'react';
import { Trans } from '@lingui/macro';
import type { IStatusesMoleculeProps } from './interface';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ChipAtom from '@atoms/Chip';
import StatusLogModel from '@models/StatusLogModel';
import { Alert } from '@mui/material';
import DatePrintAtom from '@atoms/DatePrint';
import EvaluationOrganism from '@organisms/Evaluation';

const StatusesMolecule = ({ status_logs, activity }: IStatusesMoleculeProps) => {

    return (
        <>
            {
                status_logs && status_logs.length ? 
                    status_logs.map((log: StatusLogModel, index: number) => {
                        return (
                            <Grid container key={index}>
                                <Grid item md={8}>
                                    <Box mb={1}>
                                        <ChipAtom size='small' color={log.status.class} label={log.status.name}></ChipAtom>
                                    </Box>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography fontSize={'14px'}>
                                        <DatePrintAtom format='DD/MM/YYYY HH:mm'>{ log.created_at }</DatePrintAtom>
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    })
                : <Alert severity='info'><Trans>No status update</Trans></Alert>
            }
            <EvaluationOrganism activity={activity}/>
        </>
    );
};

export default memo(StatusesMolecule);
