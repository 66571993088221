import { useState, memo, Fragment } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IMaintenancesCreateOrganismProps, IFormValues } from './interfaces';
import { t, Trans } from '@lingui/macro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid, CircularProgress } from '@mui/material';
import { Alert } from '@mui/material';
import MaintenancesService from '@services/MaintenancesService';
import { MaintenanceModel } from '@models/MaintenanceModel';
import { FormProvider, useForm } from "react-hook-form";
import { SelectMolecule }  from '@molecules/AsyncSelect';
import DateFieldMolecule from '@molecules/DateField';
import createMaintenanceSchema from '@validation/createMaintenance';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutocompleteMolecule } from '@molecules/AsyncAutocomplete';


const MaintenancesCreateOrganism = ({ model_id, model_type, onCreate }: IMaintenancesCreateOrganismProps) => {

    const [ sErrors, setSErrors ] = useState(null);
    const [ msg, setMsg ] = useState('');
    const [ success, setSuccess ] = useState<boolean>(false);

    const formInstance = useForm<IFormValues>({
		defaultValues: {
			date_at: '',
            user_id: 0,
            status_id: 0,
            model_id,
            model_type,
            notes: ''
		},
		mode: 'onChange',
		reValidateMode: 'onSubmit',
		resolver: yupResolver(createMaintenanceSchema),
	});

    const {
		control,
		formState: { isValid },
		handleSubmit: handleHookSubmit,
	} = formInstance;

    const { mutate: createMaintenanceMutation, isLoading } = useMutation(
		(formValues:IFormValues) => MaintenancesService.create(formValues).then((res: any) => {
			// setMsg(res.getMsgString())
			if(!res.hasErrors()) {
				// setSuccess(true);
                formInstance.reset();
				toast(res.getMsgString(), {
					type: 'success'
				});
                if(onCreate) {
                    onCreate(res.getData() as MaintenanceModel);
                }
			} else {
				setSErrors(res.getErrors())
				toast(res.getMsgString(), {
					type: 'error'
				});
			}
		})
	);

    const handleSubmit = (formValues: IFormValues) => {
        formValues.date_at = (new Date(formValues.date_at)).toISOString().split('T')[0];
        createMaintenanceMutation(formValues);
    }
    
	return (
        <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
            <FormProvider {...formInstance }>
                {
                    success ? (
                        <Fragment>
                            {
                                msg ? 
                                    <Box mt={2}><Alert severity="success">{ msg }</Alert></Box>
                                : null
                            }
                        </Fragment>
                    ) : (
                    <Fragment>
                        <Box>
                            <Grid container mt={1} spacing={2}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <DateFieldMolecule
                                        control={control}
                                        controlName={`date_at`}
                                        inputFormat={'dd/MM/yyyy'}
                                        label={t`Date *`}
                                    ></DateFieldMolecule>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <AutocompleteMolecule
                                        control={control}
                                        sError={sErrors ? sErrors['user_id'] : undefined}
                                        controlName="user_id"
                                        emptyValue={{full_name: ''}}
                                        getOptionLabel={(item: any) => item.full_name}
                                        storeCollection="users"
                                        optionValue={'id'}
                                        variant={'outlined'}
                                        label={<Trans>Person</Trans>}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container mt={1} spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <SelectMolecule
                                        control={control}
                                        sError={sErrors ? sErrors['status_id'] : undefined}
                                        controlName="status_id"
                                        required={true}
                                        emptyValue={0}
                                        storeCollection="statuses.maintenances"
                                        optionValue={'id'}
                                        variant={'outlined'}
                                        label={<Trans>Status</Trans>}
                                    ></SelectMolecule>
                                    {/*
                                    <TextFieldAtom
                                        controlName={'notes'}
                                        maxRows={4}
                                        minRows={2}
                                        variant={'outlined'}
                                        label={<Trans>Notes</Trans>}
                                        multiline={true}
                                    />
                                    */}
                                    {
                                        sErrors && sErrors['notes'] ? 
                                            <Alert severity='error' icon={false}>
                                                { sErrors['notes'] }
                                            </Alert>
                                        : null
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={2}>	
                            {
                                sErrors ? 
                                    <Alert severity="error">{ msg }</Alert>
                                : null
                            }
                            <Box mt={2} sx={{textAlign:'right'}}>
                                <Button type="submit" disabled={!isValid} variant="contained">
                                {
                                    isLoading ?
                                        <CircularProgress />
                                    : <Trans>Create maintenance</Trans>
                                }
                                </Button>
                            </Box>
                        </Box>
                    </Fragment>)
                }
            </FormProvider>
        </form>
	);
};

export default memo(MaintenancesCreateOrganism);
