import { memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'lodash/isEqual';
import type { IAssetsFilterValues, AssetsFilterKeysType } from '@pages/assets/interface';
import type { IAssetsFilterOrganismProps } from './interface';
import { assetsFilterValidationSchema } from '@validation/assetsFilter';
import SwitchMolecule from '@molecules/Switch';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextFieldAtom from '@atoms/TextField';
import Typography from '@mui/material/Typography';
import { SelectMolecule } from '@molecules/AsyncSelect';


const AssetsFilterOrganism = ({ filter, setFilter }: IAssetsFilterOrganismProps) => {
    const formMethods = useForm<IAssetsFilterValues>({
        mode: 'onChange',
        defaultValues: filter,
        reValidateMode: 'onSubmit',
        resolver: yupResolver(assetsFilterValidationSchema),
    });

    const { watch, control, handleSubmit, reset } = formMethods;
    const values = watch();
    const isAvailableForSearch = !isEqual(filter, values);

    useEffect(() => {
        reset(filter);
    }, [filter, reset]);

    const handleFormSubmit = (data: IAssetsFilterValues) => {
        setFilter(data);
    };

    return (
        <Paper variant='outlined' elevation={0} sx={{ paddingY: 2 }}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <FormProvider {...formMethods}>
                    <Stack spacing={2}>
                        <Stack sx={{ paddingX: 2 }}>
                            <Button
                                sx={{ height: '48px' }}
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!isAvailableForSearch}
                                type="submit"
                            >
                                <Trans>Apply filters</Trans>
                            </Button>
                        </Stack>

                        <Divider />

                        <Stack sx={{ paddingX: 2 }}>
                            <TextFieldAtom<AssetsFilterKeysType>
                                sx={{ height: '48px', input: { height: '48px', paddingY: 0 } }}
                                controlName="search"
                                label={t`Name`}
                                maxRows={1}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Stack>

                        <Divider />

                        <Stack sx={{ paddingX: 2 }}>
                            <Typography>
                                <Trans>General</Trans>
                            </Typography>
                        </Stack>

                        <Stack sx={{ paddingX: 2 }}>
                            <SelectMolecule
                                control={formMethods.control}
                                controlName="category_id"
                                optionValue={'id'}
                                emptyValue={''}
                                variant={'outlined'}
                                label={'Category'}
                                storeCollection="categories.assets"
                                optionLabel={'name'}
                            ></SelectMolecule>
                        </Stack>

                        <Divider />

                        <Stack sx={{ paddingX: 2 }}>
                            <SelectMolecule
                                control={formMethods.control}
                                controlName="type_id"
                                optionValue={'id'}
                                emptyValue={''}
                                variant={'outlined'}
                                label={t`Make`}
                                storeCollection="types.assets"
                                optionLabel={'name'}
                            ></SelectMolecule>
                        </Stack>

                        <Divider />

                        <Stack
                            sx={{ paddingX: 2, label: { margin: '0 -10px 0 0' } }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography>
                                <Trans>Available</Trans>
                            </Typography>
                            <SwitchMolecule<AssetsFilterKeysType> controlName="available" control={control} />
                        </Stack>
                    </Stack>
                </FormProvider>
            </form>
        </Paper>
    );
};

export default memo(AssetsFilterOrganism);
