import { memo, useCallback, useState, useMemo } from 'react';

import { v4 as uuid } from 'uuid';
import { Plural, t, Trans } from '@lingui/macro';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import type { IDropzoneFile } from '@interfaces/IDropzoneFile';
import type { IDropzoneAreaProps } from './interface';
import { Container, TextContainer } from './styled';

const DropzoneArea = ({ isLoading, setFiles }: IDropzoneAreaProps) => {
  const [filesCount, setFilesCount] = useState<number>(0);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      setFilesCount(acceptedFiles.length);
      setFiles(
        acceptedFiles.map(
          (file): IDropzoneFile => ({
            id: uuid(),
            file,
            name: file.name,
          }),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ maxFiles: 10, multiple: true, onDrop });

  const text = useMemo(() => {
    if (isLoading) {
      return <Plural value={filesCount} one="# file is uploading" other="# files are uploading" />;
    }

    return isDragActive ? t`Drop the files here ...` : t`Drag and drop a file here or click`;
  }, [isDragActive, isLoading, filesCount]);

  return (
    <Container {...getRootProps()} isDragActive={isDragActive || isLoading}>
      <input {...getInputProps()} />
      <TextContainer>
        {/* pLURAL BROKEN LINGUI
        <Trans />
        <p>{text}</p>
        */}
        {
          isLoading ? 
            <p><Trans>Uploading documents...</Trans></p>
          : <p><Trans>Drag and drop a file here or click</Trans></p>
        }
        <CloudUploadIcon />
      </TextContainer>
    </Container>
  );
};

export default memo(DropzoneArea);
