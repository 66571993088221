
import { CoreBaseModel } from '@core/models/Base.model';
import StatusLogModel from '@models/StatusLogModel';

export class ActivityModel extends CoreBaseModel {

    date: string;
    asset: any;
    asset_id: number;
    client_job_id: string;
    created_at: string;
    customer: any;
    category: any;
    category_id: number;
    customer_id: number;
    status_logs: Array<StatusLogModel>;
    id: number;
    is_purchase: boolean;
    job_id: string;
    user: any;
    partner: any;
    partner_id: number;
    type: any;
    type_id: number;
    status: any;
    status_id: number;
    updated_at: string;
    user_id: number;
    next_status: {class: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined, id: number, name: string, evaluation: boolean, alias: string };

    constructor(data: any) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    static getResourceName() {
        return 'activities';
    }

    static getSubTypesList() {
        return [
            { object: 'status_logs', model: StatusLogModel },
        ];
    }

    readableDate(format = 'dd/mm/yy') {
        const date = new Date(this.date);
        format = format.replace('dd', String(date.getDate()));
        format = format.replace('mm', String(date.getMonth()));
        format = format.replace('yy', String(date.getFullYear()));
        return format;
    }

    checkedIn() {
        return this.status.alias === 'checkin';
    }

    lastCheckin(what: 'created_at' | 'id'): string | number | undefined {
        const log = this.status_logs.find((log: StatusLogModel) => log.status.alias === 'checkin');
        if(what && log) {
            return log[what];
        } else {
            return undefined;
        }
    }

    nextStatus() {
        return this.next_status;
    }

}