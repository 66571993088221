import { createSlice } from '@reduxjs/toolkit'

export const appSlide = createSlice({
  name: 'app',
  initialState: {
    pusherInstance: null,
    notificationChannel: null,
    presenceChannel: null
  },
  reducers: {
    setPusherInstance: (state, action) => {
      state.pusherInstance = action.payload
    },
    setPresenceChannel: (state, action) => {
      state.presenceChannel = action.payload
    },
    setNotificationChannel: (state, action) => {
      state.notificationChannel = action.payload
    },
  },
})

export const { setPusherInstance, setPresenceChannel, setNotificationChannel } = appSlide.actions

export default appSlide.reducer
