import { useState, Fragment, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import ContainerAtom from '@atoms/Container';
import LayoutBase from '@layouts/base';
import { PageHeader } from './styled';
import PaginatedListMolecule from '@molecules/PaginatedList';
import NotificationsFilterOrganism from '@organisms/NotificationsFilter';
import { useDispatch } from 'react-redux';
import { set } from '@stores/reducers/titleReducer';
import NotificationsService from '@services/NotificationsService';
import NotificationRowMolecule from '@molecules/NotificationRow';
import { NotificationModel } from '@models/NotificationModel';
import { startChat } from '@stores/reducers/chatReducer';


const NotificationsPage = () => {

  const [filter, setFilter] = useState<Object | undefined>({});
  const dispatch = useDispatch();

  useEffect(() => {
		dispatch(set('Notifications'))
	}, [dispatch])


  const handleFilterChange = (values: any) => {
    setFilter(values);
  };

  const handleActionSelect = (notification: NotificationModel) => {
    dispatch(startChat({activity_id: notification.notification.model.activity_id}));
  };

  return (
    <>
		<LayoutBase>
			{
				<Fragment>
					<ContainerAtom>
						<PageHeader>
							<Typography variant="h4">
								<Trans>Notifications</Trans>
							</Typography>
						</PageHeader>
						<Box mb={2}>
							<NotificationsFilterOrganism onFormChange={handleFilterChange}></NotificationsFilterOrganism>
						</Box>
						<Box mt={1}>
							<PaginatedListMolecule
								renderFunc={(item: NotificationModel) => {
									return <NotificationRowMolecule notification={ new NotificationModel(item) } onNotificationSelected={handleActionSelect} />;
								}}
								service={NotificationsService}
								perPage={10}
								filter={filter}
							/>
						</Box>
					</ContainerAtom>
				</Fragment>
			}
		</LayoutBase>
    </>
  );
};

export default NotificationsPage;
