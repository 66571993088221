import { useState, useEffect } from 'react';
import { Alert, Box } from '@mui/material';
import VideoChat from '@organisms/VideoChat';
import { useUser } from '@hooks';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { ADMIN_ID } from '@utils/constants';
import { useDevice } from "@hooks";


const VideoCallOrganism = ({ activity, onHangUp, iAmInspector, isFullScreen, onRequestFullscreen }: any) => {
    
	const pusher = useSelector((state: any) => state.app.pusherInstance);
	const device = useDevice();
	const presenceChannel = useSelector((state: any) => state.app.presenceChannel);
	const [ peerSharing, setPeerSharing ] = useState(false);
    const auth = useUser();
	const [ renderKey, setRenderKey ] = useState(0);

	// Check if receiver is online
	const webRTCMembersOnline = useSelector((state: any) => state.chat.webrtcUsersOnline);
  	const receiverIsOnline = webRTCMembersOnline.find((member: any) => member.id === activity.user.id) ? true : false;

	const endTheCall = () => {
        presenceChannel.trigger(`client-signal-${iAmInspector ? ADMIN_ID : activity.user.id}`, {
            type: 'message',
            action: 'modal-closed'
        });
        setRenderKey(renderKey + 1);
		setPeerSharing(false);
		if(onHangUp) {
			onHangUp();
		}
    }

	useEffect(() => {
		if(presenceChannel) {
			presenceChannel.bind(`client-signal-${auth.user.id}`, (signal:any) => {
				if(signal.type === 'message') {
					if(signal.action === 'modal-closed') {
						setRenderKey(renderKey + 1);
						setPeerSharing(false);
					}
				}
			});
		}
	  }, [presenceChannel]);

	return (
		<Box key={renderKey} sx={{ width: '100%', textAlign:'center' }}>
			{
				receiverIsOnline ? <VideoChat
					iAmInspector={iAmInspector}
					device={device}
					presenceChannel={presenceChannel}
					onSharing={() => setPeerSharing(true)}
					receiverIsOnline={receiverIsOnline}
					pusher={pusher}
					onHangUp={endTheCall}
					auth={auth}
					isFullScreen={isFullScreen}
					onRequestFullscreen={onRequestFullscreen}
					activity={activity}/>
				: <Box mt={3}>
					<Alert severity='warning'>
						<Trans>User is offline</Trans>
					</Alert>
				</Box>
			}
		</Box>
	);
}

export default VideoCallOrganism;