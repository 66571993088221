import { memo, useCallback, useState } from 'react';
import { Trans } from '@lingui/macro';
import type { IDropzoneFile } from '@interfaces/IDropzoneFile';
import type { IDropzoneProps } from './interface';
import Stack from '@mui/material/Stack';
import DropzoneArea from '@atoms/DropzoneArea';
import DropzoneAttachments from '@atoms/DropzoneAttachments';
import Button from '@mui/material/Button';

const DropzoneOrganism = ({ isLoading, handleUploadFiles, handleCloseDropzone }: IDropzoneProps) => {
  const [files, setFiles] = useState<IDropzoneFile[]>([]);

  const handleDeleteFile = useCallback((id: string) => {
    setFiles((prevState) => prevState.filter((file) => file.id !== id));
  }, []);

  return (
    <Stack spacing={1}>
      <DropzoneArea isLoading={isLoading} setFiles={setFiles} />
      <DropzoneAttachments isDisabled={isLoading} files={files} handleDelete={handleDeleteFile} />
      <Stack spacing={2} sx={{ mt: 2 }} direction="row" justifyContent="flex-end" alignItems="center">
        <Button
          variant="outlined"
          className='cancel-btn'
          onClick={() => !isLoading && handleCloseDropzone()}
          disabled={isLoading}
        >
          <Trans>Cancel</Trans>
        </Button>
        {!!files.length && (
          <Button
            variant="contained"
            color="success"
            onClick={() => !isLoading && handleUploadFiles(files)}
            disabled={isLoading}
          >
            <Trans>UPLOAD DOCUMENTS</Trans>
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(DropzoneOrganism);
