import { useState, Fragment, useEffect } from 'react';
import { Grid, Box, Button, Typography, Drawer } from '@mui/material';
import { Trans } from '@lingui/macro';
import ContainerAtom from '@atoms/Container';
import LayoutBase from '@layouts/base';
import BoxTotalAtom from '@atoms/BoxTotal';
import { PageHeader } from './styled';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@molecules/Modal';
import NewActivityOrganism from '@organisms/ActivityCreate';
import LayoutModal from '@layouts/modal';
import PaginatedListMolecule from '@molecules/PaginatedList';
import ActivityRowMolecule from '@molecules/ActivityRow';
import ActivityRowInspectorMolecule from '@molecules/ActivityRow/InspectorVersion';
import { ActivityModel } from '@models/ActivityModel';
import { IMBOption } from '@molecules/MultiButtonMolecule/interface';
import ActivitiesFilterOrganism from '@organisms/ActivitiesFilter';
import { useQuery } from 'react-query';
import ActivitiesService from '@services/ActivitiesService';
import { toast } from 'react-toastify';
import DrawerLayout from '@layouts/drawer';
import { useConfirm } from 'material-ui-confirm';
import { useUser } from '@hooks';
import { useDispatch } from 'react-redux';
import { set } from '@stores/reducers/titleReducer';
import ActivityChatOrganism from '@organisms/ActivityChat';
import { closeChat } from '@stores/reducers/chatReducer';
import { useSearchParams } from 'react-router-dom';
import { startChat } from '@stores/reducers/chatReducer';
import VideoCallOrganism from '@organisms/VideoCall';
import OnlineOfflineMemberIconMolecule from '@molecules/OnlineOfflineMemberIcon';
import { useSelector } from 'react-redux';
import ActivityDetailOrganism from '@organisms/ActivityDetail';
import moment from 'moment';


const ActivitiesPage = () => {

	const presenceChannel = useSelector((state: any) => state.app.presenceChannel);
	const [filter, setFilter] = useState<Object | undefined>({});
	const [ modalOpen, setModalOpen ] = useState(false);
	const [ videoCallFullscreen, setVideoCallFullscreen ] = useState(false);
	const [ videoCall, setVideoCall ] = useState<any | undefined>(undefined);
	const [ chat, setChat ] = useState<any>(null);
	const [ drawerDetailID, setDrawerDetailID ] = useState<any>({});
	const [ state, setState ] = useState({ right: false });
	const [ selectedActivity, setSelectedActivity ] = useState<ActivityModel | null>(null);
	const [ totals, setTotals ] = useState<any>({});
	const [ newRow, setNewRows ] = useState<ActivityModel | undefined>(undefined);
	const [ updatedItem, setUpdated ] = useState<ActivityModel | undefined>(undefined);
	const [ toDelete, setToDeleteFromList ] = useState<number | undefined>(undefined);
	const confirm = useConfirm();
	const dispatch = useDispatch();
	const user = useUser();
	const [ searchParams, setSearchParams ] = useSearchParams();

	useEffect(() => {
		dispatch(set('Activities'))
	}, [dispatch])

	useEffect(() => {
		if(searchParams.get("chat")) {
			dispatch(startChat({activity_id: searchParams.get("chat")}));
		}
	}, [searchParams])


	const toggleDrawer = (anchor: any, open: boolean) => (event: KeyboardEvent | MouseEvent) => {
		if (
			event.type === 'keydown' &&
			((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
		) {
			return;
		}
		if(chat) {
			setChat(null);
			// @ts-ignore
			dispatch(closeChat({activity_id: selectedActivity.id}));
		}
		setState({ ...state, [anchor]: open });
	};


	const { refetch: refetchTotals } = useQuery(
		'activities-total',
		() =>
		ActivitiesService.getTotals().then((res: any) => {
			setTotals(res.getData());
		}),
		{
			refetchOnWindowFocus: false,
			cacheTime: 0,
			refetchOnMount: false,
		},
	);


	const itemUpdated = (activity: ActivityModel | undefined) => {
		setUpdated(activity);
		refetchTotals();
	}

	const onNewActivity = (activity: ActivityModel) => {
		setNewRows(activity)
	}

	const handleFilterChange = (values: any) => {
		if (values.date_start) {
			values.date_start = moment(values.date_start).format('yyyy-MM-DD');
		}
		if (values.date_end) {
			values.date_end = moment(values.date_end).format('yyyy-MM-DD');
		}
		setFilter(values);
	};

  const handleActionSelect = (param: IMBOption) => {
	if(param.label === 'Open chat') {
		setChat({}); // TODO call dispatch(startChat()) and remove this action
		setSelectedActivity(param.data.activity);
		setState({ ...state, right: true });
	} else {
		if(chat) {
			setChat(null);
		}
	}
	if (param.label === 'Video call') {
		setVideoCall(param.data);
		setState({ ...state, right: true });
		setSelectedActivity(null);
	} else if (param.label === 'Details') {
      setDrawerDetailID(param.data.activityId);
      setState({ ...state, right: true });
    } else if (param.label === 'Copy') {
      ActivitiesService.copyActivity(param.data.activityId).then((res: any) => {
        if (!res.hasErrors()) {
          setNewRows(res.getData());
          toast(res.getMsgString(), {
            type: 'success',
          });
        } else {
          toast(res.getMsgString(), {
            type: 'error',
          });
        }
      });
    } else if (param.label === 'Delete') {
      confirm({ description: 'This action is permanent!' })
      .then(() => { 
        ActivitiesService.delete(param.data.activityId).then((res: any) => {
          if(!res.hasErrors()) {
            setToDeleteFromList(param.data.activityId);
          }
          toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });
        });
       });
    }
  };

  const closeVideChatDrawer = () => {
	presenceChannel.trigger(`client-signal-${videoCall.activity?.user.id}`, {
		type: 'message',
		action: 'modal-closed'
	});
	toggleDrawer('right', false);
	setVideoCall(null);
	setState({right: false});
  }

  useEffect(() => {
	if(presenceChannel) {
		presenceChannel.bind(`client-signal-${user.user.id}`, (signal:any) => {
			if(signal.type === 'message') {
				if(signal.action === 'modal-closed') {
					setState({right: false});
				}
			}
		});
	}
  }, [presenceChannel]);

  return (
    <>
		<Fragment key={'right'}>
			<Drawer anchor={'right'} open={state.right && !chat && !videoCall} onClose={toggleDrawer('right', false)}>
				<DrawerLayout onCloseClick={() => setState({right: false})}>
					<ActivityDetailOrganism onUpdate={itemUpdated} id={drawerDetailID} />
				</DrawerLayout>
			</Drawer>
			<Drawer anchor={'right'} open={state.right && chat && selectedActivity ? true : false} onClose={toggleDrawer('right', false)}>
				<DrawerLayout onCloseClick={() => setState({right: false})}>
					<Box>
						<Box>
							<Trans>Activity</Trans>: { selectedActivity?.job_id }
						</Box>
						<Box>
							<Trans>Inspector</Trans>: { selectedActivity?.user?.full_name }
						</Box>
					</Box>
					<ActivityChatOrganism activity={selectedActivity}/>
				</DrawerLayout>
			</Drawer>
			<Modal fullscreen={videoCallFullscreen} open={state.right && videoCall && !selectedActivity ? true : false} set_open={closeVideChatDrawer}>
                <LayoutModal title={<Box>Call { videoCall ? videoCall.activity?.user?.full_name : '' }</Box>}>
					{
						videoCall && <Box>
							<VideoCallOrganism
								onHangUp={closeVideChatDrawer}
								isFullScreen={videoCallFullscreen}
								onRequestFullscreen={() => setVideoCallFullscreen(videoCallFullscreen ? false : true)}
								activity={videoCall.activity}
							/>
						</Box>
					}
                </LayoutModal>
            </Modal>
		</Fragment>
		<LayoutBase>
		{
			user.isAdmin() ?
				<Fragment>
					<ContainerAtom>
					<PageHeader>
						<Typography variant="h4">
						<Trans>Activities</Trans>
						</Typography>
						<Button onClick={() => setModalOpen(true)} variant="outlined">
						<AddIcon></AddIcon> <Trans>New activity</Trans>
						</Button>
					</PageHeader>
					<Modal open={modalOpen} set_open={setModalOpen}>
						<LayoutModal title={<Trans>New activity</Trans>}>
							<NewActivityOrganism onNewActivity={onNewActivity}></NewActivityOrganism>
						</LayoutModal>
					</Modal>
					<Grid my={2} container spacing={1}>
						{Object.keys(totals).map((totalKey, index) => {
						return (
							<Grid key={index} item md={totalKey !== 'total' ? 2 : 4} xs={6}>
								<BoxTotalAtom
									totalInteger={totals[totalKey]}
									label={
									totalKey === 'today' ? (
										<Trans>Today</Trans>
									) : totalKey === 'tomorrow' ? (
										<Trans>Tomorrow</Trans>
									) : totalKey === 'week' ? (
										<Trans>Week</Trans>
									) : totalKey === 'month' ? (
										<Trans>Month</Trans>
									) : totalKey === 'total' ? (
										<Trans>Total</Trans>
									) : (
										totalKey
									)
									}
								/>
							</Grid>
						);
						})}
					</Grid>
					<Box mb={2}>
						<ActivitiesFilterOrganism onFormChange={handleFilterChange}></ActivitiesFilterOrganism>
					</Box>
					<Box mt={1}>
						{filter ? (
						<PaginatedListMolecule
							idToDelete={toDelete}
							updated={updatedItem}
							renderFunc={(item: ActivityModel) => {
								return <ActivityRowMolecule activity={item} onActionSelected={handleActionSelect} />;
							}}
							service={ActivitiesService}
							perPage={10}
							newRow={newRow}
							filter={filter}
						/>
						) : (
						<></>
						)}
					</Box>
					</ContainerAtom>
				</Fragment>
			: <Box mt={1}>
				<PaginatedListMolecule
					idToDelete={toDelete}
					updated={updatedItem}
					renderFunc={(item: ActivityModel) => {
						return <ActivityRowInspectorMolecule activity={item} onActionSelected={handleActionSelect} />;
					}}
					service={ActivitiesService}
					perPage={10}
					newRow={newRow}
					filter={filter}
				/>
			</Box>
		}
		</LayoutBase>
    </>
  );
};

export default ActivitiesPage;
