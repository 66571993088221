import { CoreBaseModel } from '@core/models/Base.model';
import { CertificationTypeModel } from './CertificationType';

export class CertificationModel extends CoreBaseModel {

    created_at: string;
    expiration:  string;
    id: number;
    level: "success" | "info" | "warning" | "error";
    model_id: number;
    model_type: string;
    type: CertificationTypeModel;
    type_id: number;
    updated_at: string;

    constructor(data: any) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    static getSubTypesList() {
        return [
            { object: 'type', model: CertificationTypeModel },
        ];
    }

    static getResourceName() {
        return 'certifications';
    }

    expired() {
        var now = new Date();
        var expirationDate = new Date(this.expiration);
        if(now.getTime() === expirationDate.getTime()) {
            return false;
        }
        if(expirationDate.getTime() < now.getTime()) {
            return true;
        }
    }
}
