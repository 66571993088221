import Moment from 'react-moment';
import 'moment-timezone';

interface IDatePrintAtom {
    children: any;
    format?: string;
    utc?: boolean;
    tz?: string | null;
}

const DatePrintAtom = ({  children, format, utc, tz }: IDatePrintAtom) => {
  return (
    <>
        <Moment utc={utc} tz={tz ? tz : undefined} format={format}>{ children }</Moment>
    </>
  )
}

DatePrintAtom.defaultProps = {
  	utc: true,
  	tz: 'Europe/Rome',
	  children: null
}

export default DatePrintAtom;