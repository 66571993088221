import { useEffect, useState } from 'react';
import { Grid, Paper, Box } from '@mui/material';
import { Trans } from '@lingui/macro';
import { SelectMolecule } from '@molecules/AsyncSelect';
import AsyncAutocompleteMolecule, { AutocompleteMolecule } from '@molecules/AsyncAutocomplete';
import IActivitiesFilterOrganism from './interface';
import { yupResolver } from '@hookform/resolvers/yup';
// https://blog.logrocket.com/using-material-ui-with-react-hook-form/
import { FormProvider, useForm} from "react-hook-form";
import * as Yup from 'yup';
import RadioGroupMolecule from '@molecules/RadioGroup';
import TabsMolecule from '@molecules/Tabs';
import ActivitiesService from '@services/ActivitiesService';
import DateFieldMolecule from '@molecules/DateField';

const schemaA = Yup.object().shape({
  user_id: Yup.number().nullable(),
  customer_id: Yup.number().nullable(),
  date_range: Yup.string().nullable(),
  type_id: Yup.number().nullable(),
  // status: Yup.string().nullable(),
  job_id: Yup.string().nullable(),
  date_start: Yup.string().nullable(),
  date_end: Yup.string().nullable(),
});

interface IFormValues {
  user_id: number;
  // status: string;
  date_range: string;
  customer_id: number;
  type_id: number | null;
  job_id: string | undefined;
  date_start: string | undefined;
  date_end: string | undefined;
}


const ActivitiesFilterOrganism = ({ onFormChange }: IActivitiesFilterOrganism) => {

    const [ showRanges, setShowRanges ] = useState<boolean>(false);

    const formMethods = useForm<IFormValues>({
		mode: 'onChange',
		defaultValues: {
            type_id: undefined,
            user_id: undefined,
            customer_id: undefined,
			// status: 'planned',
            date_range: '',
            job_id: ''
		},
		reValidateMode: 'onSubmit',
		resolver: yupResolver(schemaA),
    })

    const setFilterTab = (data: any) => {
        if(data.value !== 'range') {
            setShowRanges(false);
            formMethods.setValue('date_start', '', { shouldValidate: true});
            formMethods.setValue('date_end', '', { shouldValidate: true});
            formMethods.setValue('date_range', data.value, { shouldValidate: true}); // FIX date range 1/11/2022 - 2/11/2022
        } else {
            setShowRanges(true);
        }
    }
    
    useEffect(() => {
      	const watchAll = formMethods.watch((value, { name, type }) => {
			if(onFormChange) {
                console.log(formMethods.getValues());
				onFormChange(formMethods.getValues());
			}
    	});
    	return () => watchAll.unsubscribe();
    }, [formMethods.watch, formMethods, onFormChange]);

    return (
        <>
          <form>
              <FormProvider {...formMethods} >
              <Grid container spacing={1}>
                  {
                      /*
                        <Grid style={{ alignItems: 'center', display: 'flex'}} item md={3} xs={12}>
                            <Paper style={{width:'100%', height: '100%', padding: '3px', display: 'flex', alignItems: 'center'}} variant="outlined">
                                <FormProvider {...formMethods}>
                                    <RadioGroupMolecule
                                        control={formMethods.control}
                                        controlName={'status'}
                                        radios={[{label: <Trans>Planned</Trans>, value: 'planned'}, {label: <Trans>Live</Trans>, value: 'live'}]} />
                                </FormProvider>
                            </Paper>
                        </Grid>
                       */
                  }
                  <Grid item md={12} xs={12}>
                      <Paper elevation={0} variant="outlined">
                              <Grid container p={1} spacing={1}>
                                  <Grid item xs={12} sm={3} md={3}>
                                      <SelectMolecule
                                            control={formMethods.control}
                                            controlName="type_id"
                                            emptyValue={null}
                                            optionValue={'id'}
                                            variant={'outlined'}
                                            label={'Type'}
                                            storeCollection="types.activities"
                                            optionLabel={'name'}
                                      ></SelectMolecule>
                                  </Grid>
                                  <Grid item xs={12} sm={3} md={3}>
                                        <AutocompleteMolecule
                                            control={formMethods.control}
                                            controlName="user_id"
                                            listId={'activities-filter-owner'}
                                            storeCollection="users"
                                            emptyValue={{full_name: ''}}
                                            variant={'outlined'}
                                            optionValue={'id'}
                                            getOptionLabel={(user:any) => `${user.full_name}`}
                                            optionLabel={'name'}
                                            label={<Trans>Owner</Trans>}
                                        ></AutocompleteMolecule>
                                    </Grid>
                                  <Grid item xs={12} sm={3} md={3}>
                                    <AutocompleteMolecule
                                        control={formMethods.control}
                                        controlName="customer_id"
                                        listId={'activities-filter-client'}
                                        storeCollection="customers"
                                        emptyValue={''}
                                        variant={'outlined'}
                                        optionValue={'id'}
                                        optionLabel={'name'}
                                        label={<Trans>Client</Trans>}
                                    ></AutocompleteMolecule>
                                  </Grid>
                                  <Grid item xs={12} sm={3} md={3}>
                                      <AsyncAutocompleteMolecule
                                            control={formMethods.control}
                                            controlName="job_id"
                                            variant={'outlined'}
                                            label={<Trans>Search linked job</Trans>}
                                            service={ActivitiesService}
                                            serviceMethod={'jobs'}
                                      ></AsyncAutocompleteMolecule>
                                  </Grid>
                              </Grid>
                      </Paper>
                  </Grid>
                  <Box mt={2} sx={{ width: '100%'}}>
                    <Grid container>
                        <Grid item sm={12} md={12} lg={12} sx={{maxWidth: '100%'}}>
                            <TabsMolecule
                                onChange={setFilterTab}
                                tabsLabels={[
                                    {label: 'All', value: 'all'},
                                    {label: 'Today', value: 'today'},
                                    {label: 'Tomorrow', value: 'tomorrow'},
                                    {label: 'Current week', value: 'week'},
                                    {label: 'Current month', value: 'month'},
                                    {label: 'Custom range', value: 'range'}
                                ]}
                                />
                            {
                                showRanges ? 
                                    <Box mt={1}>
                           
                                            <Grid px={1} py={0} container spacing={1}>
                                                <Grid item sm={12} md={6} lg={6}>
                                                    <DateFieldMolecule
                                                        control={formMethods.control}
                                                        controlName={'date_start'}
                                                        inputFormat={'dd/MM/yyyy'}
                                                        label={<Trans>From</Trans>}
                                                    ></DateFieldMolecule>
                                                </Grid>
                                                <Grid item sm={12} md={6} lg={6}>
                                                    <DateFieldMolecule
                                                        control={formMethods.control}
                                                        controlName={'date_end'}
                                                        inputFormat={'dd/MM/yyyy'}
                                                        label={<Trans>To</Trans>}
                                                    ></DateFieldMolecule>
                                                </Grid>
                                            </Grid>
                                 
                                    </Box>
                                : null
                            }
                        </Grid>
                    </Grid>
                  </Box>
                </Grid>
                </FormProvider>
          </form>
        </>
    )
}

export default ActivitiesFilterOrganism;