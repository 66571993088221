import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CoreResponseModel } from '@core/models/Response.model';
import NotificationsService from '@services/NotificationsService';


export interface INotificationsState {
    toRead: number;
    isLoading: boolean;
    error: any;
}

const initialState: INotificationsState = {
    toRead: 0,
    isLoading: false,
    error: null
};

export const getNotificationsToRead = createAsyncThunk(
    'toRead/get',
    async (filter: any, { rejectWithValue }): Promise<any> => {
        try {
            const res = (await NotificationsService.getToRead(filter)) as CoreResponseModel;
            if (res.hasErrors()) {
                // @ts-ignore
                throw new Error(res.getMsgString ? res.getMsgString() : 'Errore');
            }
            return res.getData();
        } catch (error: any) {
            console.log('error.message', error.msg);
            throw rejectWithValue(error);
        }
    },
);

export const readAll = createAsyncThunk(
    'toRead/remove',
    async (filter: any, { rejectWithValue }): Promise<any> => {
        try {
            const res = (await NotificationsService.readAll()) as CoreResponseModel;
            if (res.hasErrors()) {
                // @ts-ignore
                throw new Error(res.getMsgString ? res.getMsgString() : 'Errore');
            }
            return res.getData();
        } catch (error: any) {
            console.log('error.message', error.msg);
            throw rejectWithValue(error);
        }
    },
);


export const notificationsReducer = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        method: (state) => {
            
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotificationsToRead.pending, (state) => {
                state.error = null;
                state.isLoading = true;
            })
            .addCase(getNotificationsToRead.fulfilled, (state, { payload }) => {
                if(payload) {
                    state.toRead = payload.to_read;
                    state.isLoading = false;
                    state.error = null;
                }
            })
            .addCase(getNotificationsToRead.rejected, (state, { payload }) => {
                state.error = payload; // msg, fieldErrors
                state.isLoading = false;
            })
            .addCase(readAll.fulfilled, (state, { payload }) => {
                state.toRead = 0;
            })
    },
});

export const { method } = notificationsReducer.actions;

export default notificationsReducer.reducer;
