import React, { useState, useEffect } from 'react';
import { StyledNotificationButton, StyledMenu } from './styled';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useDispatch } from 'react-redux';
import { getNotificationsToRead, readAll } from '@stores/reducers/notificationsReducer';
import { useSelector } from 'react-redux';
import type { AppDispatch } from '@stores/store';
import { Badge } from '@mui/material';
import { messageService } from '@helpers/messagesService';
import NotificationsList from './NotificationsList';


const NotificationBellOrganism = () => {

    const dispatch = useDispatch<AppDispatch>();
    const toRead = useSelector((state: any) => state.notifications.toRead);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if(toRead) {
            dispatch(readAll({}));
        }
    };

    // sposta nello store TODO
    messageService.getMessage().subscribe(message => {
        if(message.text.action === "notifications/to_read") {
            dispatch(getNotificationsToRead({}));
        }
    })

	const handleClose = () => {
	  setAnchorEl(null);
	};

    useEffect(() => {
        dispatch(getNotificationsToRead({}));
    }, [])

    return (
        <React.Fragment>
            <StyledNotificationButton sx={{marginTop: '5px'}} onClick={handleClick}>
                <Badge badgeContent={toRead} color="primary">
                    <NotificationsNoneIcon sx={{fontSize: '28px'}} color='info'/>
                </Badge>
            </StyledNotificationButton>
            <StyledMenu
                id="notifications-menu"
                PaperProps={{  
                    style: {
                      width: 350,
                      minHeight: 40,
                      padding: 0
                    },  
                 }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                    {
                        open && <NotificationsList onNotificationClick={handleClose}></NotificationsList>
                    }
            </StyledMenu>
        </React.Fragment>
    );
};

export default NotificationBellOrganism;
