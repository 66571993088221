import { Trans, t } from '@lingui/macro';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { authLoginRequest } from '@stores/reducers/authReducer';
import { useAppDispatch } from '@stores/hooks';
import { useAuth } from '@hooks';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import loginSchema from '@validation/login';
import ButtonAtom from '@atoms/Button';
import { StyledFormBody } from './styled';
import { ToastContainer, toast } from 'react-toastify';

interface IFormValues {
  email: string;
  password: string;
}

const LoginFormHolder = () => {
  const dispatch = useAppDispatch();
  const { error, isLoading } = useAuth();

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit: handleHookSubmit,
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: {
      email: "admin.test@demo.it",
      password: "@ZT2021test@"
    },
    reValidateMode: 'onSubmit',
    resolver: yupResolver(loginSchema),
  });

  const handleSubmit = (data: IFormValues) => {
    dispatch(authLoginRequest(data));
  };

  useEffect(() => {
    toast(error, {
      type: 'info'
    });
  }, [ error ])

  return (
    <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
      <StyledFormBody>
          <TextField
            {...register('email', { required: true })}
            fullWidth
            type="text"
            label={t`Email`}
            margin="normal"
            autoComplete="email"
            helperText={!!isSubmitted && errors.email && errors.email?.message}
            size="medium"
          />
          <TextField
            {...register('password', { required: true })}
            fullWidth
            type="password"
            label={t`Password`}
            margin="normal"
            autoComplete="current-password"
            helperText={!!isSubmitted && errors.password && errors.password?.message}
            size="medium"
          />
      </StyledFormBody>
      <Box my={1}>
        <ToastContainer />
        {
          error ? 
            <Alert severity="error">{error}</Alert>
          : null
        }
      </Box>
      <ButtonAtom type="submit" label={<Trans>Login</Trans>} disabled={isLoading} loading={isLoading} fullWidth variant="contained" />
    </form>
  );
};

export default LoginFormHolder;
