import { StyledButton } from './styled';
import IButton from './interfaces';
import SpinnerAtom from '@atoms/Spinner';


const ButtonAtom = ({
  primary = false,
  size = 'medium',
  backgroundColor,
  label,
  loading,
  disabled,
  fullWidth,
  onClick,
  variant,
  type,
  ...props
}: IButton) => {
  return (
    <StyledButton type={type} fullWidth={fullWidth} onClick={onClick ? onClick : () => {}} {...props} variant={variant ? variant : 'contained'}>
      {
        loading ?
          <SpinnerAtom />
        : <>{ label }</>
      }
    </StyledButton>
  );
};


export default ButtonAtom;