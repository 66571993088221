import type { IPeopleFilterProps } from '@organisms/PeopleFilter/interface';
import { IDS } from '@utils/constants';

export const peopleFilterInitialValues: IPeopleFilterProps = {
    search: null,
    role_id: IDS.ROLES.USER,
    category_id: null,
    type_id: null,
    location_id: null,
    inactive: null,
};
