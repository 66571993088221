import React, { useEffect } from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import IAttributesForm from './interface';
import TextField from '@atoms/TextField';
import { useFieldArray } from "react-hook-form";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { t } from '@lingui/macro';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Tooltip from '@mui/material/Tooltip';
import { Trans } from '@lingui/macro';
import { COLORS } from '@theme/Colors';

const AttributesFormGroupMolecule = ({ group, formControl, patch, showTitle, personAssignedGroup }: IAttributesForm) => {

	const groupName = group.alias ? group.alias : 'group-alias';

	const gridItemSize = Math.ceil(12 / (patch ? group.attributes[0].length : group.attributes.length));

	const { fields, append, remove, /*, prepend, remove, insert*/ } = useFieldArray({
		control: formControl,
		name: groupName, // unique name for your Field Array
	});

	const reduceAttributes = (attributes: Array<any>, setDefaultValue = true) => attributes.reduce((prevValue: any, currValue: any) => {
		const object = { ...prevValue };
		object[currValue.attribute_id ? currValue.attribute_id : currValue.id] = setDefaultValue ? currValue.value : ''; // default value
		return object;
	}, {});

	const appendFields = () => {
		let fieldsObjectList:any = {};
		if(patch && personAssignedGroup) {
			if(personAssignedGroup.length) {
				fieldsObjectList = [];
				personAssignedGroup.forEach((attribute: any, indexA: number) => {
					fieldsObjectList.push(reduceAttributes(attribute));
				});
			} else {
				fieldsObjectList = reduceAttributes(group.attributes);
			}
		} else {
			fieldsObjectList = reduceAttributes(group.attributes);
		}
		append(fieldsObjectList);
	}

	const appendFieldsRow = () => {
		append(reduceAttributes(patch ? group.attributes : group.attributes, false));
	}

	useEffect(() => {
		if(patch) {
			appendFields();
		}
	}, [group]);

	/**
	 * Attribute grid form input
	 * @param attributesArray 
	 * @param fieldIndex 
	 * @returns 
	 */
	const Attributes = (attributesArray: Array<any>, fieldIndex: string | number) => {
		return attributesArray.map((attribute: any, attIndex: number) => {
			return (
				<Grid key={attIndex} item md={gridItemSize}>
					<Box key={attIndex}>
						{
							attribute.type.alias === 'text' ?
								<TextField
									label={ attribute.label }
									controlName={`${groupName}.${fieldIndex}.${attribute.id}`}
									required={ attribute.is_required } />
							: 	
								<Box key={attIndex}>
									<Trans>Misunderstood type</Trans>
								</Box>
						}
					</Box>
				</Grid>
			)
		})
	}

	const Items = (group: any) => {
		return <React.Fragment>
			{
				fields.map((field: any, fieldIndex: number) => {
					return (
						<Grid sx={{marginBottom: '8px'}} container key={fieldIndex}>
							<Grid item md={group.is_repeater /*&& fields.length > 1*/ ? 11 : 12 }>
							{
								<Grid container spacing={1} key={fieldIndex}>
									{
										Attributes(patch ? group.attributes : group.attributes, fieldIndex)
									}
								</Grid>
							}
							</Grid>
							{
								group.is_repeater /*&& fields.length > 1*/ ?
									<Grid item md={1}>
										<Box sx={{ display: 'flex', alignItems:'center', height: '100%'}}>
											<Button onClick={() => remove(fieldIndex)}>
												<RemoveCircleIcon color={'error'} />
											</Button>
										</Box>
									</Grid>
								: null
							}
						</Grid>
					)
				})
			}	
		</React.Fragment>
	}

	return (
		<Box>
			<React.Fragment>
				<Box mt={1} mb={2}>
					{
						showTitle ? 
							<Typography color={COLORS.blue.primary} variant='body1' sx={{marginBottom: '10px', marginTop: '25px'}}>{ group.name }</Typography>
						: null
					}
					{ Items(group) }
					{
						<Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
							{
								group.is_repeater ?
									<Box mt={1} sx={{ textAlign: 'right'}}>
										<Tooltip placement='top' title={t`Add a new ${group.name.toLowerCase()} detail`}>
											<Button variant='contained' color={'success'} onClick={appendFieldsRow} sx={{display: 'flex', alignItems: 'center'}}>
												<AddCircleIcon />
												<Typography>{ `Add ${group.name.toLowerCase()} detail` }</Typography>
											</Button>
										</Tooltip>
									</Box>
									
								: null
							}
						</Box>
					}
				</Box>
			</React.Fragment>
		</Box>
	)
}

AttributesFormGroupMolecule.defaultProps = {
	showTitle: false
}

export default AttributesFormGroupMolecule;