import * as Yup from 'yup';
import { t } from '@lingui/macro';

const createActivitySchema = Yup.object().shape({
  type_id: Yup.string().required(t`Necessaria`).nullable(),
  category_id: Yup.string().nullable(),
  date: Yup.date().nullable(),
  user_id: Yup.string().nullable(),
  asset_id: Yup.string().nullable(),
  customer_id: Yup.string().nullable(),
  client_job_id: Yup.string().nullable(),
  job_id:  Yup.string().nullable(),
  is_purchase: Yup.boolean().nullable(),
  partner_id: Yup.string().nullable(),
  notes: Yup.string().nullable()
});

export default createActivitySchema;