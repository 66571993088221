import React, { memo, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import type { ICertificationsOrganismProps, IFormValues } from './interface';
import CertificationsService from '@services/CertificationsService';
import { useQuery, useMutation } from 'react-query';
import { CertificationModel } from '@models/CertificationModel';
import SpinnerAtom from '@atoms/Spinner';
import { Box, Typography, Grid, Avatar, Tooltip, Popover, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ReportIcon from '@mui/icons-material/Report';
import { StyledList, StyledCertificationCard, StyledListGrid } from './styled';
import CertificationDetailOrganism from '@organisms/CertificationDetail';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import TextField from '@atoms/TextField';
import { toast } from 'react-toastify';
import DateFieldMolecule from '@molecules/DateField';
import { SelectMolecule }  from '@molecules/AsyncSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import CreateIcon from '@mui/icons-material/Create';
import Alert from '@mui/material/Alert';


const CertificationsOrganism = ({ model_id, model_type, add_cert }: ICertificationsOrganismProps) => {

    const [ certifications, setCertifications ] = useState<Array<CertificationModel>>([]);
    const [ certificationDetail, setCertificationDetail ] = useState<CertificationModel | null>(null);
    const [ anchorEl, setAnchorEl ] = useState<Element | null>(null);
    const open = Boolean(anchorEl);

    let configuration: ObjectShape = {};
    const formSchema: any = {};

    formSchema['expiration'] = Yup.string().required();
    formSchema['level'] = Yup.string();
    formSchema['type_id'] = Yup.number().min(1);

    configuration['certifications'] = Yup.array()
        .of(Yup.object()
        .shape(formSchema))
        .required("Must have fields")
        .min(1, "Minimum of 1 field")

    const yupSchema = Yup.object().shape(configuration);

    const formInstance = useForm<IFormValues>({
		mode: 'onChange',
		reValidateMode: 'onSubmit',
        resolver: yupResolver(yupSchema)
	});

    const { handleSubmit: handleHookSubmit, formState } = formInstance;

    const { fields, append, remove, /*, prepend, remove, insert*/ } = useFieldArray({
		control: formInstance.control,
		name: 'certifications'
	});

    const { isLoading, data } = useQuery(`certifications-${model_type}-${model_id}`, () => CertificationsService.get('', {model_id, model_type}), {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        refetchOnMount: false
    });
    
    const openCertificationDetail = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, certification: CertificationModel) => {
        setCertificationDetail(certification);
        setAnchorEl(event.currentTarget);
    }

    const { mutate: saveAttributesMutation } = useMutation((data: any) => 
        CertificationsService.create(data.values).then((res: any) => {
            toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });
            certifications.push(res.getData());
            // TODO delete only if !res.hasErrors()
            /*
            if(!res.hasErrors()) {
                remove(data.index);
            }
            */
            setCertifications([...certifications]);
        }));

    const closeDetail = () => {
        setCertificationDetail(null);
        setAnchorEl(null);
    }

    useEffect(() => {
        if(!isLoading && data) {
            // @ts-ignore
            setCertifications(data.getData())
        }
    }, [isLoading, data])

    const cancelForm = () => {
        formInstance.reset();
    }

    const certificationModified = (certification: CertificationModel) => {
        closeDetail();
        certifications[certifications.findIndex(
            (cert: CertificationModel) => cert.id === certification.id)] = certification;
        setCertifications([...certifications]);
    }

    const onDelete = (certification: CertificationModel) => {
        closeDetail();
        const updateCerts = certifications.filter((cert: CertificationModel) => cert.id !== certification.id );
        setCertifications([...updateCerts]);
    }

    const addCertification = () => {
        append({level: '', expiration: '', type_id: 0})
    }

    const handleSubmit = (formValues: any) => {
        formValues.certifications.forEach((certification: CertificationModel, index: number) => {
            const data: any = {
                values:  {...certification, ...{model_id, model_type}},
                index: index
            };
            data.values.expiration = (new Date(data.values.expiration)).toISOString().split('T')[0];
            saveAttributesMutation(data);
            formInstance.reset();
        });
    }

    useEffect(() => {
        if(add_cert) {
            addCertification();
        }
    }, [add_cert])

    const Card = (certification: CertificationModel ,certificationIndex:number) => {
        return (<StyledCertificationCard key={certificationIndex} onClick={(event:any) => openCertificationDetail(event, certification)}>
            <Grid container wrap="nowrap" spacing={1}>
                <Grid item>
                    <Avatar sx={{ bgcolor: '#f3f3f3' }}>
                        <CreateIcon className='pencil-icon' color={'info'}></CreateIcon>
                        <Box className='status-icon'>
                            { 
                                certification.expired() ?
                                    <Tooltip title={t`Expired ${certification.expiration}`}>
                                        <ReportIcon color='error'></ReportIcon>
                                    </Tooltip>
                                : <Tooltip title={t`Valid until ${certification.expiration}`}>
                                    <CheckIcon color='success'></CheckIcon>
                                </Tooltip>
                            }
                        </Box>
                    </Avatar>
                </Grid>
                <Grid item xs p={1}>
                    <Box>
                        <Typography variant='body2'>
                            { certification.type.name }
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='caption'>
                            { certification.level }
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </StyledCertificationCard>)
    }


    return (
        <>
            {
                ! isLoading ? 
                    <Box>
                        <StyledList>
                            {
                                certificationDetail ? 
                                    <Popover
                                        id={`detail-${model_id}`}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={closeDetail}
                                        marginThreshold={20}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}>
                                        <Box p={2}>
                                            <CertificationDetailOrganism 
                                                model_type={model_type}
                                                certification={certificationDetail}
                                                onDelete={onDelete}
                                                onSave={certificationModified} />
                                        </Box>
                                    </Popover>
                                : null
                            }
                            {
                            fields.length ? 
                                <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
                                    <b><Typography><Trans>Add new certifications</Trans></Typography></b>
                                    <FormProvider {...formInstance }>
                                        {
                                            fields.map((field: any, fieldIndex: number) => {
                                                return (
                                                    <Grid container spacing={1} mt={1} key={fieldIndex}>
                                                        <Grid item sm={12} md={4}>
                                                            <SelectMolecule
                                                                control={formInstance.control}
                                                                controlName={`certifications.${fieldIndex}.type_id`}
                                                                required={true}
                                                                /*helperText={formState?.errors?.certifications?[0].level}*/
                                                                emptyValue={0}
                                                                optionValue={'id'}
                                                                storeCollection="types.certifications"
                                                                variant={'outlined'}
                                                                label={t`Certification`}
                                                            ></SelectMolecule>
                                                        </Grid>
                                                        <Grid item sm={12} md={4}>
                                                            <TextField
                                                                label={t`Level`}
                                                                controlName={`certifications.${fieldIndex}.level`}
                                                                />
                                                        </Grid>
                                                        <Grid item sm={12} md={fields.length > 1 ? 3 : 4}>
                                                            <DateFieldMolecule
                                                                control={formInstance.control}
                                                                controlName={`certifications.${fieldIndex}.expiration`}
                                                                inputFormat={'dd/MM/yyyy'}
                                                                label={t`Expiring date *`}
                                                            ></DateFieldMolecule>
                                                        </Grid>
                                                        {
                                                            fields.length > 1 && <Grid item sm={12} md={1}>
                                                                <Box sx={{ display: 'flex', alignItems:'center', height: '100%'}}>
                                                                    <Button onClick={() => remove(fieldIndex)}>
                                                                        <RemoveCircleIcon color={'error'} />
                                                                    </Button>
                                                                </Box>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                )
                                            })
                                        }
                                        <Box sx={{display: 'flex', justifyContent: 'end'}}>
                                            <Box mt={2} mr={1} sx={{textAlign: 'right'}}>
                                                <Button
                                                    onClick={cancelForm}
                                                    className='cancel-btn' variant="contained"
                                                    type='submit'>
                                                    <span>{ t`Cancel`}</span>
                                                </Button>
                                            </Box>
                                            <Box mb={4} mt={2} sx={{textAlign: 'right'}}>
                                                <Button
                                                    disabled={!formState.isValid}
                                                    variant='contained'
                                                    color='success'
                                                    type='submit'>
                                                    {fields.length === 1 && <span>{ t`Add certification`}</span> }
                                                    {fields.length > 1 && <span>{ t`Add certifications`}</span> }
                                                </Button>
                                            </Box>
                                        </Box>
                                    </FormProvider>
                                </form>
                            : !certifications.length ? <Alert severity='warning'><Trans>There are no certifications</Trans></Alert> : null
                            }
                            <Grid container>
                                <Grid item md={12}>
                                    <StyledListGrid>
                                        {
                                            certifications.map((certification: CertificationModel, certificationIndex: number) => {
                                                if (certificationIndex % 6 === 0) {
                                                    return (
                                                        <Grid container key={certificationIndex}>
                                                            {
                                                                [0, 1, 2, 3, 4, 5].map((index: number) => {
                                                                    if(certifications[certificationIndex + index]) {
                                                                        return (<Grid item md={2} key={'n' + index}>
                                                                            { Card(certifications[certificationIndex + index], certificationIndex + index) }
                                                                        </Grid>)
                                                                    } else {
                                                                        return <React.Fragment key={'n' + index}></React.Fragment>
                                                                    }
                                                                })
                                                            }
                                                        </Grid>
                                                    )
                                                } else {
                                                    return <React.Fragment key={certificationIndex}></React.Fragment>
                                                }
                                            })
                                        }
                                    </StyledListGrid>
                                </Grid>
                                {
                                    /*
                                        <Grid item md={1}>
                                        <Tooltip title={t`Add certification`}>
                                            <Button onClick={addCertification}>
                                                <AddCircleIcon color='success'></AddCircleIcon>
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                    */
                                }
                                
                            </Grid>
                        </StyledList>
                    </Box>
                : <SpinnerAtom />
            }
        </>
    );
};

export default memo(CertificationsOrganism);
