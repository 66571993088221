
import * as Yup from 'yup';

export interface IActivityChatProps {
    activity: any;
}

export interface IFormValues {
    message: string;
    activity_id: number;
}


export const sendMessageSchema = Yup.object().shape({
  activity_id: Yup.number().required(),
  message: Yup.string().required()
});
