import * as Yup from 'yup';
import { t } from '@lingui/macro';

const createMaintenanceSchema = Yup.object().shape({
  user_id: Yup.number().required(t`Necessaria`).min(1),
  status_id: Yup.number().required(t`Necessaria`).min(1),
  date_at: Yup.string().required(t`Necessaria`),
  notes: Yup.string(),
});

export default createMaintenanceSchema;