import { memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextFieldAtom from '@atoms/TextField';
import SearchIcon from '@mui/icons-material/Search';
import type { IUserFilterProps } from './interface';
import type { IUserFilterOrganismProps } from './interface';
import { Trans } from '@lingui/macro';
import { SelectMolecule } from '@molecules/AsyncSelect';



const UserFilterOrganism = ({ filter, setFilter }: IUserFilterOrganismProps) => {
    
    const formMethods = useForm<IUserFilterProps>({
        mode: 'onChange',
        defaultValues: filter,
        reValidateMode: 'onSubmit'
    });

    const { handleSubmit, reset } = formMethods;

    useEffect(() => {
        reset(filter);
    }, [filter, reset]);

    const handleFormSubmit = (data: IUserFilterProps) => {
        setFilter(data);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <FormProvider {...formMethods}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        {/*
                        <SelectMolecule
                            control={formMethods.control}
                            controlName="role_id"
                            optionValue={'id'}
                            except={[2, 4]}
                            storeCollection="roles"
                            variant={'outlined'}
                            label={<Trans>Role</Trans>}
                        ></SelectMolecule>
                        */}
                    </Grid>
                    <Grid item md={6}>
                        <TextFieldAtom
                            controlName="search"
                            label={<Box sx={{ display: 'flex', alignItems: 'center'}}><SearchIcon></SearchIcon> <Trans>Search</Trans></Box>}
                            maxRows={1}
                        />
                    </Grid>
                </Grid>
            </FormProvider>
        </form>
    );
};

export default memo(UserFilterOrganism);
