import { createTheme } from '@mui/material/styles';

const rawTheme = createTheme({
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    h4: {
      // color: `${blue} !important`
    },
    h5: {
      // color: `${blue} !important`
    },
    h6: {
      fontSize: '0.9rem'
    },
    body2: {
      color: 'black'
    },
    caption: {
      color: 'black',
    },
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
  },
});

export default rawTheme;