
import { useEffect } from 'react';
import { Box } from '@mui/material';
import IAttributesList from './interface';
import { FormProvider, useForm } from "react-hook-form";
import AttributesFormGroupMolecule from '@molecules/AttributesFormGroup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MakeResolver } from '@utils/AttributesForm';


const AttributesFormLayer = ({ list, onFormChange, notifyValidation }: IAttributesList) => {

	const formInstance = useForm({
		mode: 'onChange',
		reValidateMode: 'onSubmit',
		resolver: yupResolver(MakeResolver(list))
	});

	const { watch, getValues, formState } = formInstance;

	useEffect(() => {
		const watchAll = formInstance.watch((value, { name, type }) => {
			if(onFormChange) {
				let data: any = null;
				if(/*format === 'id-value' && */Object.keys(getValues()).length) {
					data = [];
					list.forEach((group: any) => {
						// For each group...
						let attributes = getValues()[group.alias].reduce((prevVal: any, currVal: any) => {
							let array: any = [];
							// Get the fields array
							Object.keys(currVal).forEach((id: any) => {
								array.push({attribute_id: Number(id), value: currVal[id]});
							});
							prevVal.push(array);
							return prevVal;
						}, []);
						data.push({id: group.id, attributes: attributes});
					});
				} else {
					data = getValues();
				}
				onFormChange(data);
			}
	  	});
	 	return () => watchAll.unsubscribe();
  	}, [watch, formInstance, list]);

    useEffect(() => {
		if(notifyValidation) {
			notifyValidation(formState.isValid)
		}
	}, [formState.isValid, formState.errors, notifyValidation])

	return (
		<>
			<Box>
				<FormProvider {...formInstance }>
					{
						list.map((group: any, index: number) => {
							return (
								<Box key={index}>
									<AttributesFormGroupMolecule
										formControl={formInstance.control}
										showTitle={true}
										group={group}/>
								</Box>
							)
						})
					}
				</FormProvider>
			</Box>
		</>
	)
}

export default AttributesFormLayer;