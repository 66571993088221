import { Fragment, useEffect } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import SpinnerAtom from '@atoms/Spinner';
import { useParams } from "react-router-dom";
import DocumentsOrganism from '@organisms/Documents';
import { useDispatch } from 'react-redux';
import { set } from '@stores/reducers/titleReducer';
import { ActivityModel } from '@models/ActivityModel';

interface IDocsPage {
    activity: ActivityModel;
}

const InspectorActivityDocsPage = ({ activity }: IDocsPage) => {

    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(set('Docs'))
    }, [dispatch])

    return (
        <>
            {
                id ? 
                    <Fragment>
                        <Card>
                            <CardContent>
                                <Box mb={1}>
                                    <DocumentsOrganism
                                        model_id={Number(id)}
                                        model_type='activities' />
                                </Box>
                            </CardContent>
                        </Card>
                    </Fragment>
                : <SpinnerAtom size={'50px'}></SpinnerAtom> 
            }
        </>
    );
};

export default InspectorActivityDocsPage;
