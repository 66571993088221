import * as Yup from 'yup';
import { t } from '@lingui/macro';

const createPersonSchema = (mode: string | undefined) => Yup.object().shape({
  type_id: Yup.string(),
  role_id: Yup.string().required(t`Necessaria`),
  name: Yup.string().required(t`Necessaria`),
  surname: Yup.string().required(t`Necessaria`),
  email: Yup.string().required(t`Necessaria`),
  phone: Yup.string().nullable(),
  //password: mode !== 'patch' ? Yup.string().required(t`Necessaria`) : Yup.string(),
  //password_confirmation: mode !== 'patch' ? Yup.string().required(t`Necessaria`) : Yup.string(),
  internal: Yup.boolean().nullable(),
});

export default createPersonSchema;