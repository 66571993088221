import { forwardRef } from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';

const ChipAtom = forwardRef<HTMLDivElement, ChipProps>((props, ref) => {
  return <Chip {...props} ref={ref} />;
});

ChipAtom.defaultProps = {
  variant: 'outlined',
};

export default ChipAtom;
