import { CoreBaseService } from '@core/services/Base.service';

// Reference model
import { PeopleModel } from '@models/PeopleModel';


class PeopleService extends CoreBaseService {

    constructor() {
        super(PeopleModel);
    }

    getTotals() {
        return this.sub('totals').get();
    }
  
}

export default new PeopleService();