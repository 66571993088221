import { Box } from '@mui/material';
import IPeopleDataOrganism from './interface';
import { useQuery } from 'react-query';
import ActivitiesService from '@services/ActivitiesService';
import { useState, useEffect } from 'react';
import SpinnerAtom from '@atoms/Spinner';
import { ActivityModel } from '@models/ActivityModel';
import ActivityRow from '@molecules/ActivityRow';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import { Trans } from '@lingui/macro';

const PeopleDetailJobsOrganism = ({ person }: IPeopleDataOrganism) => {

    const [ results, setResults ] = useState<Array<any>>([]);

    const { isLoading, data } = useQuery(
        `people-maintenances${person ? person.id : ''}`,
        () => ActivitiesService.get('', {user_id: person ? person.id : null}).then((res: any) => res),
        {
          refetchOnWindowFocus: false,
          cacheTime: 0,
          refetchOnMount: false,
        },
    );

    useEffect(() => {
        if(!isLoading && data) {
			// @ts-ignore
            setResults(data.data);
		}
    }
    , [ isLoading, data ])

	return <>
		<Box>
            {
                ! isLoading ? 
                    results && results.length ? <Box>
                        {
                            results.map((activity: ActivityModel) => {
                                return <Paper variant='outlined' sx={{marginBottom: '5px'}}><ActivityRow activity={activity} hideMultButton={true}></ActivityRow></Paper>
                            })
                        }
                    </Box>
                : <Alert severity='warning' icon={false}>
                    <Trans>There are no jobs</Trans>
                    </Alert>
                : <SpinnerAtom></SpinnerAtom>
            }
        </Box>
    </>;
};

export default PeopleDetailJobsOrganism;
