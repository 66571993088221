
import { useAppDispatch } from '@stores/hooks';
import { resetAuthStore } from '@stores/reducers/authReducer';

const LogoutPage = () => {
  const dispatch = useAppDispatch();
  dispatch(resetAuthStore());
  return (
    <></>
  );
};

export default LogoutPage;
