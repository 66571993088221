import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Typography, Card, Grid, CardContent, Button, Tooltip } from '@mui/material';
import SpinnerAtom from '@atoms/Spinner';
import DocumentsOrganism from '@organisms/Documents';
import NotesOrganism from '@organisms/Notes';
import IPeopleDetailOrganism from './interface';
import { t, Trans } from '@lingui/macro';
import TabsMolecule from '@molecules/Tabs';
import { PeopleModel } from '@models/PeopleModel';
import PeopleService from '@services/PeopleService';
import PeopleDetailQualificationsOrganism from '@organisms/PeopleDetail/Qualifications';
import EmailIcon from '@mui/icons-material/Email';
import { toast } from 'react-toastify';
import PeopleDetailJobsOrganism from './Jobs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PersonModel } from '@models/PersonModel';
import Paper from '@mui/material/Paper';


const PeopleDetailOrganism = ({ id, onUpdate }: IPeopleDetailOrganism) => {

  const [ person, setResults ] = useState<PeopleModel | undefined>(undefined);

  const { isLoading, data, refetch } = useQuery(
    `person-detail${id}`,
    () => PeopleService.get(id).then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  const sendPassword = () => {
	toast('FAKE: password has been sent correctly', {
		type: 'success'
	});
  }

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data.data);
    }
  }, [isLoading, data]);

  const assetDetailPanel = () => {
    return (
		<>
			<Box>
				<Typography variant='h4' color="text.secondary" gutterBottom>
					<Trans>Person details</Trans>
				</Typography>
        		<Card variant='outlined' elevation={0} sx={{ minWidth: 275, padding: '' }}>
					<Grid container>
						<Grid item md={9}>
							<CardContent>
								<Box sx={{display: 'flex', alignItems: ''}}>
									<Typography variant='h5' color="text.secondary" gutterBottom>
										{ person?.full_name }
									</Typography>
									<Tooltip title={t`This person is active`}>
										<CheckCircleIcon sx={{marginLeft: '10px'}} fontSize='medium' color='info' />
									</Tooltip>
								</Box>
								<Grid container>
									<Grid item md={4}>
										<b><Trans>Email</Trans></b>
										<Typography variant="body2" color="text.secondary">
											{ person?.email }
										</Typography>
									</Grid>
									<Grid item md={4}>
										<b><Trans>Phone</Trans></b>
										<Typography variant="body2">
											{ person?.phone ? person.phone : '--' }
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Grid>
						<Grid item md={3}>
							<CardContent>
								<Tooltip title={t`Remember personal password to this user`}>
									<Button onClick={sendPassword} variant='outlined'>
										<EmailIcon></EmailIcon>&nbsp;
										<Trans>Send password</Trans>
									</Button>
								</Tooltip>
							</CardContent>
						</Grid>
					</Grid>
				</Card>
				<TabsMolecule
					tabsLabels={[
						{label: 'Qualifications', child: <Box mt={2}><PeopleDetailQualificationsOrganism onUpdate={(person: PersonModel) => { refetch(); onUpdate(person);}} person={person}/></Box>},
						{label: 'Jobs', child: <Box mt={2}><PeopleDetailJobsOrganism person={person}/></Box>},
						{label: 'Notes', child: <Paper variant='outlined' sx={{marginTop: '15px'}}><Box><NotesOrganism model_id={id} model_type={'users'}/></Box></Paper>},
						{label: 'Docs', value: 'week', child: <Paper variant='outlined' sx={{marginTop: '15px'}}><Box><DocumentsOrganism model_id={id} model_type={'users'} /></Box></Paper>}
					]}/>
			</Box>
      </>
    );
  };

  return <>{person ? assetDetailPanel() : <SpinnerAtom />}</>;
};

export default PeopleDetailOrganism;
