import { useState, ChangeEvent, memo, useCallback } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import type { IDropzoneFile } from '@interfaces/IDropzoneFile';
import type { IDocument } from '@interfaces/IDocument';
import type { IDocumentsOrganismProps } from './interfaces';
import { t, Trans } from '@lingui/macro';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import DropzoneOrganism from '@organisms/Dropzone';
import DocumentRowItem from '@molecules/DocumentRowItem';
import DocumentsService from '@services/DocumentsService';
import { Wrapper, GroupActionButton } from './styled';
import { Alert, IconButton } from '@mui/material';
import SpinnerAtom from '@atoms/Spinner';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useConfirm } from 'material-ui-confirm';
import { useUser } from '@hooks';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';


const DocumentsOrganism = ({ model_id, model_type }: IDocumentsOrganismProps) => {
	
	const user = useUser();

	const { data, refetch, isLoading } = useQuery<unknown, unknown, IDocument[]>(
		`activity-documents${model_id}`,
		() => DocumentsService.get('', { model_id, model_type }).then((res: any) => res.data),
		{ refetchOnWindowFocus: false, cacheTime: 0, refetchOnMount: true },
	);

	const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
	const [isFilesUploading, setIsFilesUploading] = useState<boolean>(false);
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [previewFile, setPreviewOpen] = useState<IDocument | null>(null);
	const [blobUrl, setBlobUrl] = useState<any>(null);
	const handlePreviewClose = () => setPreviewOpen(null);
	const items = data || [];
	const rowsCount = items.length;
	const selectedItemsLength = selectedItems.length;
	const hasSelectedItems = !!selectedItems.length;
	const confirm = useConfirm();

	const handleDownload = useCallback((item: IDocument) => {
		DocumentsService.download(item.id, item.file)
		.then((res: any) => {
			toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });
		})
		.catch((res: any) => {
			toast(t`Generic error`, { type: 'error' });
		});
	}, []);

	const handleDelete = useCallback(
		(item: IDocument) => {
			confirm({ description: 'This action is permanent!' })
      		.then(() => {
				DocumentsService.delete(item.id)
					.then((res: any) => {
						toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });
					})
					.catch((res: any) => {
						toast(t`Generic error`, { type: 'error' });
					});
					refetch();
			})
		},
		[refetch],
	);

	const handleView = useCallback(
		(item: IDocument) => {
			DocumentsService.getFile(item.id, item.file)
				.then((res: any) => {
					const dataType = res.headers['content-type'];
					const binaryData: Array<any> = [];
					binaryData.push(res.blob);
					const url = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
					setPreviewOpen(item)
					setBlobUrl(url)
				})
				.catch((res: any) => {
					toast(t`Generic error`, { type: 'error' });
				});
		}, [])

  const handleMultipleDelete = () => {
	confirm({ description: 'This action is permanent!' })
	.then(() => {
		if (selectedItemsLength === 0) {
			return;
		}

		if (selectedItemsLength === 1) {
			handleDelete(items.find(({ id }) => id === selectedItems[0])!);
		}

		Promise.all(selectedItems.map((id) => DocumentsService.delete(id)))
			.then(() => {
				toast(t`Documents deleted successfully`, { type: 'success' });
				setSelectedItems([]);
			})
			.catch(() => {
				toast(t`Generic error`, { type: 'error' });
			})
			.finally(() => {
				refetch();
			});
		})
  	};

	const toggleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedItems(items.map(({ id }) => id));
		} else {
			setSelectedItems([]);
		}
 	};

	const toggleSelectSingleItem = useCallback((event: ChangeEvent<HTMLInputElement>, id: number) => {
		if (event.target.checked) {
			setSelectedItems((prevState) => [...prevState, id]);
		} else {
			setSelectedItems((prevState) => prevState.filter((selectedId) => selectedId !== id));
		}
	}, []);

	const handleCloseDropzone = useCallback(() => {
		setIsCreateMode(false);
	}, []);

	const handleOpenDropzone = () => {
		setIsCreateMode(true);
		setSelectedItems([]);
	};

	const handleUploadFiles = useCallback(
		(files: IDropzoneFile[]) => {
		setIsFilesUploading(true);

		const formData = new FormData();

		files.forEach(({ file }) => formData.append('files[]', file));
		formData.set('model_id', `${model_id}`);
		formData.set('model_type', model_type);

		// Upload documents
		DocumentsService.create(formData)
			.then((res: any) => {
				toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });
				refetch();
				setIsCreateMode(false);
			})
			.catch((res: any) => {
				toast(t`Generic error`, { type: 'error' });
			})
			.finally(() => {
				setIsFilesUploading(false);
			});
		},
		[model_id, model_type, refetch],
	);

	return (
		<Box sx={{ flexGrow: 1, maxWidth: { /*md: 752,*/ xs: '100%' } }}>
			<Box sx={{ display: 'grid', gridTemplateColumns: '100%' }}>
				{isCreateMode ? (
					<Box  p={1}>
						<DropzoneOrganism
							isLoading={isFilesUploading}
							handleUploadFiles={handleUploadFiles}
							handleCloseDropzone={handleCloseDropzone}
						/>
					</Box>
				) : (
				<Wrapper>
					<Stack direction="row" spacing={2} sx={{marginTop: '10px'}}>
						{
							user.can('documents.create') ? 
								<GroupActionButton sx={ !hasSelectedItems ? {margin:'0 auto', maxWidth: '50%'} : {}} color="primary" onClick={handleOpenDropzone}>
									<UploadFileIcon></UploadFileIcon>&nbsp;<Trans>UPLOAD</Trans>
								</GroupActionButton>
							: <></>
						}
						{
							hasSelectedItems && user.can('documents.delete') ?
								<GroupActionButton isVisible={hasSelectedItems} color="error" onClick={handleMultipleDelete}>
									<Trans>Delete</Trans>
								</GroupActionButton>
							: null
						}
					</Stack>
					{
						isLoading && <Box mt={1}><SpinnerAtom></SpinnerAtom></Box>
					}
					{
						!isLoading && !items.length ?
							<Alert sx={{margin: '20px'}} severity='info'><Trans>There are no documents</Trans></Alert>
						: null
					}
					{
						items && items.length ?
							<TableContainer sx={{ my: 2, maxHeight: 440, width: '100%', maxWidth: '100%' }}>
								<Table stickyHeader size="small">
									<TableHead>
										<TableRow>
											<TableCell padding="checkbox">
												{
													user.can('documents.delete') && <Checkbox
														indeterminate={selectedItemsLength > 0 && selectedItemsLength < rowsCount}
														checked={rowsCount > 0 && selectedItemsLength === rowsCount}
														inputProps={{ 'aria-label': t`select all` }}
														onChange={toggleSelectAll}
													/>
												}
											</TableCell>
											<TableCell sx={{display:'none'}} padding="none"></TableCell>
											<TableCell align="center">
												<Trans>Name</Trans>
											</TableCell>
											<TableCell align="center">
												<Trans>Actions</Trans>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody sx={{ width: 'inherit' }}>
									<Dialog
										open={previewFile ? true : false}
										fullScreen
									>
										<DialogTitle>
											<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
												<Trans>Document preview</Trans>
												<IconButton onClick={handlePreviewClose} sx={{ textAlign: 'right'}}>
													<CloseIcon></CloseIcon>
												</IconButton>
											</Box>
										</DialogTitle>
										<DialogContent dividers sx={{textAlign: 'center'}}>
											{ previewFile && previewFile.isImage() && <img alt='preview' title='preview' src={blobUrl} width="100%"/> }
											{ previewFile && previewFile.isPdf() && 
												<object style={{height: '100%', width: '100%'}} type='application/pdf' data={blobUrl}>
													<p>Your web browser doesn't have a PDF plugin.
													Instead you can <b style={{textDecoration:'underline'}} onClick={() => handleDownload(previewFile)}>click here to
													download the PDF file.</b></p>
												</object> }
										</DialogContent>
									</Dialog>
										{items.map((item) => (
											<DocumentRowItem
												key={item.id}
												isSelected={selectedItems.some((id) => id === item.id)}
												item={item}
												toggleSelect={toggleSelectSingleItem}
												handleDownload={handleDownload}
												handleDelete={handleDelete}
												handleView={handleView}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						: null
					}
				</Wrapper>
				)}
			</Box>
		</Box>
	);
};

export default memo(DocumentsOrganism);
