import { useState } from 'react';
import type ICertificationDetail from './interface';
import { IFormValues } from './interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import TextFieldAtom from '@atoms/TextField';
import { Box, Button, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { t, Trans } from '@lingui/macro';
import CertificationsService from '@services/CertificationsService';
import { SelectMolecule }  from '@molecules/AsyncSelect';
import DateFieldMolecule from '@molecules/DateField';
import { useConfirm } from 'material-ui-confirm';


const schemaA = Yup.object().shape({
    expiration: Yup.string().nullable(),
    type_id: Yup.number().required(t`This field is required`).min(1),
    model_id: Yup.number().required(),
    model_type: Yup.string().required(),
    level: Yup.string()
});

const CertificationDetailOrganism = ({ certification, model_type, onSave, onDelete }: ICertificationDetail) => {
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const confirm = useConfirm();

    const formInstance = useForm<IFormValues>({
        mode: 'onChange',
        defaultValues: { 
            expiration: certification.expiration,
            type_id: certification.type.id,
            level: certification.level,
            model_id: certification.id,
            model_type
        },
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schemaA),
    });

    const {
        formState: { isValid, isDirty },
        handleSubmit: handleHookSubmit,
    } = formInstance;

    const handleSubmit = (formValues: any) => {
        setIsLoading(true);
        formValues.expiration = (new Date(formValues.expiration)).toISOString().split('T')[0];
        CertificationsService.update(formValues, certification.id).then((res: any) => {
            setIsLoading(false);
            toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });
            if (!res.hasErrors()) {
                if(onSave) {
                    onSave(res.getData());
                }
            }
        });
    };

    const deleteCertification = () => {
        confirm({ description: 'This action is permanent!' })
        .then(() => {
            CertificationsService.delete(certification.id).then((res: any) => {
                setIsLoading(false);
                toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });
                if (!res.hasErrors()) {
                    if(onDelete) {
                        onDelete(certification);
                    }
                }
            });
        })
    }

    return (
        <>
            {
                certification ? 
                    <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
                        <FormProvider {...formInstance}>
                            <Box sx={{ minWidth: '350px' }}>
                                <SelectMolecule
                                    control={formInstance.control}
                                    controlName="type_id"
                                    required={true}
                                    emptyValue={0}
                                    optionValue={'id'}
                                    storeCollection="types.certifications"
                                    variant={'outlined'}
                                    label={<Trans>Certification</Trans>}
                                ></SelectMolecule>
                            </Box>
                            <Box mt={1} sx={{ minWidth: '350px' }}>
                                <TextFieldAtom
                                    controlName={'level'}
                                    variant={'outlined'}
                                    label={t`Level`}/>
                            </Box>
                            <Box mt={1} sx={{ minWidth: '350px' }}>
                                <DateFieldMolecule
                                    control={formInstance.control}
                                    controlName={'expiration'}
                                    inputFormat={'dd/MM/yyyy'}
                                    label={<Trans>Expiring date</Trans>}
                                ></DateFieldMolecule>
                            </Box>
                        </FormProvider>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                            <Box mt={1} sx={{ textAlign: 'right' }}>
                                <Button onClick={deleteCertification} color='error' variant="contained">
                                    {isLoading ? <CircularProgress /> : <Trans>Delete</Trans>}
                                </Button>
                            </Box>
                            { 1 && (
                                <Box mt={1} sx={{ textAlign: 'right' }}>
                                    <Button type="submit" disabled={!isValid} variant="contained">
                                        {isLoading ? <CircularProgress /> : <Trans>Update</Trans>}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </form>
                : null
            }
        </>
    );
};

export default CertificationDetailOrganism;
