import { CoreBaseService } from '@core/services/Base.service';

// Reference model
import { MenuModel } from '@models/MenuModel';


class MenuService extends CoreBaseService {

    constructor() {
        super(MenuModel);
    }
  
}

export default new MenuService();