import { useState } from 'react';
import { Box, Grid, Typography, Tooltip, IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IPeopleQualificationsOrganism from './interface';
import { Trans, t } from '@lingui/macro';
import ChipAtom from '@atoms/Chip';
import { StyledGeneralDetailBox, StyledGenearalContGrid } from './styled';
import Certifications from '@organisms/Certifications';
import AttributesOrganism from '@organisms/AttributesOrganism';
import EditIcon from '@mui/icons-material/Edit';
import PersonCreateOrganism from '@organisms/PersonCreate';
import { PeopleModel } from "@models/PeopleModel";
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const PeopleDetailQualificationsOrganism = ({ person, onUpdate }: IPeopleQualificationsOrganism) => {

	const [ isEdit, setIsEdit ] = useState<boolean>(false);
	const [ addCertification, setAddCertification ] = useState<number>(0);
	const [ infoIsExpanded, setInfoIsExpanded ] = useState<boolean>(true);

	let isExpanded = true;
	
	const personUpdate = (person: PeopleModel) => {
		setIsEdit(false);
		if(onUpdate) {
			onUpdate(person)
		}
	}

	const accordionChangeStatus = (expanded: boolean) => {
		isExpanded = expanded;
	}

	const edit = (event: any, isEdit: boolean) => {
		if(isExpanded) {
			event?.stopPropagation()
		}
		setIsEdit(isEdit);
	}

	return <>
		{
			person ? 
				<Box mt={3} mb={1}>
					<Paper variant='outlined' sx={{marginBottom: '15px'}}>
					<Accordion onChange={(event: any, expanded: boolean) => accordionChangeStatus(expanded)} defaultExpanded={true} elevation={0} sx={{marginBottom: '10px'}}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1z-content" id="panel1z-header">
							<Typography variant='h5'><b><Trans>General</Trans></b></Typography>
							<Box sx={{textAlign: 'right'}}>
								{
									! isEdit ? 
										<Tooltip title={t`Edit asset`} placement="top">
											<IconButton onClick={(event: any) => edit(event, true)}>
												<EditIcon color='success'></EditIcon>
											</IconButton>
										</Tooltip>
									: <Tooltip title={t`Cancel`} placement="top">
										<IconButton onClick={(event: any) => edit(event, false)}>
											<CancelIcon></CancelIcon>
										</IconButton>
									</Tooltip>
								}
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							{
								! isEdit ?
								<Box>
									<StyledGenearalContGrid container>
										<Grid item md={4}>
											<StyledGeneralDetailBox>
												<Box>
													<Typography variant='h6'>
														<Trans>Role</Trans>
													</Typography>
												</Box>
												<Box>
													<Typography variant='body1'>
														{ person.category ? person.category.name : '--' }
													</Typography>
												</Box>
											</StyledGeneralDetailBox>
										</Grid>
										<Grid item md={4}>
											<StyledGeneralDetailBox>
												<Box>
													<Typography variant='h6'>
														<Trans>Email</Trans>
													</Typography>
												</Box>
												<Box>
													<Typography variant='body1'>
														{ person.email ? person.email : '--' }
													</Typography>
												</Box>
											</StyledGeneralDetailBox>
										</Grid>
										<Grid item md={4}>
											<StyledGeneralDetailBox>
												<Box>
													<Typography variant='h6'>
														<Trans>Phone</Trans>
													</Typography>
												</Box>
												<Box>
													<Typography variant='body1'>
														{ person.phone ? person.phone : '--' }
													</Typography>
												</Box>
											</StyledGeneralDetailBox>
										</Grid>
									</StyledGenearalContGrid>
									<StyledGenearalContGrid container>
										<Grid item md={4}>
											<StyledGeneralDetailBox>
												<Box>
													<Typography variant='h6'>
														<Trans>Department</Trans>
													</Typography>
												</Box>
												<Box>
													<Typography variant='body1'>
														{ person.location ? person.location.name : '--' }
													</Typography>
												</Box>
											</StyledGeneralDetailBox>
										</Grid>
										<Grid item md={4}>
											<StyledGeneralDetailBox>
												<Box>
													<Typography variant='h6'>
														<Trans>Seniority</Trans>
													</Typography>
												</Box>
												<Box>
													{
														person.type ? 
															<ChipAtom label={ person.type.name }></ChipAtom>
														: '--'
													}
												</Box>
											</StyledGeneralDetailBox>
										</Grid>
									</StyledGenearalContGrid>
								</Box>
								: <PersonCreateOrganism onCancel={() => setIsEdit(false)} onUpdate={personUpdate} mode='patch' person={person} hideAttributes={true}></PersonCreateOrganism>
							}
						</AccordionDetails>
					</Accordion>
					</Paper>
					<AttributesOrganism model_type='users' model={person}></AttributesOrganism>
				</Box>
			: null
		}
		{
			person ? 
				<Paper variant='outlined'>
				<Accordion onChange={(event: any, expanded: boolean) => setInfoIsExpanded(expanded)} defaultExpanded={true} elevation={0}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-1a-certifications`} id={`panel-1a-certifications`}>
						<Typography variant='h5'><b><Trans>Certifications</Trans></b></Typography>
						<Tooltip title={t`Add certification`} placement="top">
							<IconButton onClick={(event:any) => { setAddCertification(addCertification + 1); if(infoIsExpanded) { event.stopPropagation();}} }>
								<AddCircleIcon color='success'/>
							</IconButton>
						</Tooltip>
					</AccordionSummary>
					<AccordionDetails>
						<Certifications model_id={person.id} model_type={'users'} add_cert={addCertification}></Certifications>
					</AccordionDetails>
				</Accordion>
				</Paper>
			: null
		}
    </>;
};

export default PeopleDetailQualificationsOrganism;
