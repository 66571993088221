import { CoreBaseService } from '@core/services/Base.service';

// Reference model
import { DocumentModel } from '@models/DocumentModel';

class DocumentsService extends CoreBaseService {
  constructor() {
    super(DocumentModel);
  }

  download(fileId: number, fileName: string) {
    return this.sub(`${fileId}/download`)
      .get(undefined, undefined, true, false, { responseType: 'blob' })
      .then((res: any) => {
        res.download(fileName);
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  getFile(fileId: number, fileName: string) {
    return this.sub(`${fileId}/download`)
      .get(undefined, undefined, true, false, { responseType: 'blob' })
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default new DocumentsService();
