import { CoreBaseModel } from '@core/models/Base.model';

export class CertificationTypeModel extends CoreBaseModel {

    id: number;
    name: string;
    alias: string;
    class: "default" | "error" | "info" | "warning" | "success";

    constructor(data: any) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    static getResourceName() {
        return 'certifications-type';
    }
}
