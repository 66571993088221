import { memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from '@lingui/macro';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextFieldAtom from '@atoms/TextField';
import SearchIcon from '@mui/icons-material/Search';
import type { IClientFilterProps } from './interface';
import type { IClientFilterOrganismProps } from './interface';


const ClientFilterOrganism = ({ filter, setFilter }: IClientFilterOrganismProps) => {
    
    const formMethods = useForm<IClientFilterProps>({
        mode: 'onChange',
        defaultValues: filter,
        reValidateMode: 'onSubmit'
    });

    const { handleSubmit, reset } = formMethods;

    useEffect(() => {
        reset(filter);
    }, [filter, reset]);

    const handleFormSubmit = (data: IClientFilterProps) => {
        setFilter(data);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <FormProvider {...formMethods}>
                <Stack>
                    <TextFieldAtom
                        controlName="search"
                        label={<Box sx={{ display: 'flex', alignItems: 'center'}}><SearchIcon></SearchIcon><Trans>Search</Trans></Box>}
                        maxRows={1}
                    />
                </Stack>
            </FormProvider>
        </form>
    );
};

export default memo(ClientFilterOrganism);
