import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { StyledActivityRow } from './styled';
import ChipAtom from '@atoms/Chip';
import IActivityRow from './interface';
import MultiButtonMolecule from '@molecules/MultiButtonMolecule';
import Tooltip from '@mui/material/Tooltip';
import DatePrintAtom from '@atoms/DatePrint';
import { useSelector } from 'react-redux';
import OnlineOfflineMemberIconMolecule from '@molecules/OnlineOfflineMemberIcon';
import { useUser } from '@hooks';

const ActivityRowMolecule = ({ activity, onActionSelected, hideMultButton }: IActivityRow) => {

  const auth = useUser();

  // Check if user is online
  const webRTCMembersOnline = useSelector((state: any) => state.chat.webrtcUsersOnline);
  const userIsOnline = webRTCMembersOnline.find((member: any) => member.id === activity.user.id);

  const actionSelected = (item: any) => {
    if(onActionSelected) {
      onActionSelected(item)
    }
  }

  return (
    <>
        <Paper sx={{border: '0px'}} elevation={0} variant='outlined'>
            <StyledActivityRow>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item sx={{textAlign:'center', display: 'flex', alignItems: 'center'}} md={1} xs={12}>
                      <Box className='date' mr={1}>
                        <Typography>
                          {
                            activity.date ? 
                              <DatePrintAtom format='DD/MM/yyyy'>{ activity.date }</DatePrintAtom>
                            : '--'
                          }
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid sx={{textAlign:'center', display: 'flex', gap: '10px'}} item md={2} xs={12}>
                      { auth.user.id !== activity.user.id && <OnlineOfflineMemberIconMolecule memberId={activity.user.id} /> }
                      {
                        activity.user ? 
                          <React.Fragment>
                              { `${activity.user.full_name}` }
                          </React.Fragment>
                        : '--'
                      }
                    </Grid>
                    <Grid sx={{textAlign:'center'}} item md={2} xs={12}>
                      { activity.asset ? activity.asset.name : '--' }
                    </Grid>
                    <Grid sx={{textAlign:'center'}} item md={2} xs={12}>
                       <Box>{ activity.customer ? activity.customer.name : '--' }</Box>
                       <Box>{ activity.client_job_id }</Box>
                    </Grid>
                    <Grid sx={{textAlign:'center'}} item md={2} xs={12}>
                      { activity.type ? <Tooltip placement='top' title={activity.type?.name}><ChipAtom label={activity.type?.name} /></Tooltip> : '--' }
                    </Grid>
                    <Grid sx={{textAlign:'center'}} item md={1} xs={12}>
                      { activity.status ? <Tooltip placement='top' title={activity.status?.name}><ChipAtom variant='filled' color={activity.status.class} label={activity.status.name} /></Tooltip> : '--' }
                    </Grid>
                    <Grid sx={{textAlign: 'right'}} item md={2} xs={12}>
                      {
                        ! hideMultButton &&
                            <MultiButtonMolecule onSelected={actionSelected} options={[
                              {label: 'Details', id: 1, data: { activityId: activity.id }},
                              {label: 'Open chat', id: 2, data: { activityId: activity.id, activity }, disabled: auth.user.id !== activity.user.id ? false : true},
                              {label: 'Video call', id: 3, data: { activityId: activity.id, activity }, disabled: (!userIsOnline || auth.user.id === activity.user.id) ? true : false},
                              {label: 'Copy', id: 4, data: { activityId: activity.id }},
                              {label: 'Delete', id: 5, data: { activityId: activity.id }, disabled: auth.user.id !== activity.user.id ? false : true}
                            ]}/>
                      }
                      </Grid>
                </Grid>
            </StyledActivityRow>
        </Paper>
    </>
  )
}


export default ActivityRowMolecule;