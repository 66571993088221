import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const StyledMessage = styled('div')(( props ) => ({
  padding: '5px',
  background: 'white',
  border: '1px solid #d4d4d4',
  marginBottom: '20px',
  borderRadius: '5px',
  maxWidth: '85%',
  position: 'relative',
  '&:hover': {
    '& .actions': {
      visibility: 'visible'
    }
  },
  '& .actions': {
    position: 'relative',
    visibility: 'hidden',
    '& .mod-button': {

    },
  },
  '& .deleting-overlay': {
    position: 'absolute',
    opacity: .5,
    width: '100%',
    top: 0,
    left: 0,
    background: '#dddddd',
    height: '100%'
  },
  '&.mine': {
    background: '#dcf8c6',
    marginLeft: 'auto',
    '& .author': {
      display: 'none',
      marginRight: '0px'
    }
  },
  '& *': {
    color: '#484848',
  },
  '& p.message': {
    color: '#484848',
    fontSize: '14px'
  },
  '& .author': {
    fontWeight: 'bold',
    marginRight: '10px'
  },
  '& .date': {
    fontWeight: '400'
  }
}));