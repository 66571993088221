import { Grid, Paper, Box } from '@mui/material';
import { StyledNotificationRow } from './styled';
import INotificationRow from './interface';
import DatePrintAtom from '@atoms/DatePrint';
import ForumIcon from '@mui/icons-material/Forum';
import MIconAtom from '@atoms/MIcon';
import { t } from '@lingui/macro';


const NotificationRowMolecule = ({ notification, onNotificationSelected }: INotificationRow) => {

	const actionSelected = () => {
		if(onNotificationSelected) {
			onNotificationSelected(notification)
		}
	}

	return (
		<Paper sx={{border: '0px'}} elevation={0} variant='outlined'>
			<StyledNotificationRow>
				<Grid onClick={actionSelected} container sx={{alignItems: 'center'}}>
					<Grid item sx={{textAlign:'center', display: 'flex', alignItems: 'center'}} md={1} xs={12}>
						<Box className='date' mr={1}>
						{
							notification.isChat() && <MIconAtom tooltip={t`Chat message`} icon={<ForumIcon/>}/>
						}
						</Box>
					</Grid>
					<Grid sx={{textAlign:'center'}} item md={2} xs={12}>
						{
							notification.isChat() ? 
								<Box>{ notification.notification.user.full_name}: 
									<Box><b><i>"{ notification.text }"</i></b></Box>
								</Box>
							: ''
						}
					</Grid>
					<Grid sx={{textAlign:'center'}} item md={2} xs={12}>
						<DatePrintAtom utc={false} tz={null} format='DD/MM/YYYY HH:mm'>{ notification.notification.created_at }</DatePrintAtom>
					</Grid>
					<Grid sx={{textAlign:'center'}} item md={2} xs={12}></Grid>
					<Grid sx={{textAlign:'center'}} item md={2} xs={12}></Grid>
					<Grid sx={{textAlign:'center'}} item md={1} xs={12}></Grid>
					<Grid sx={{textAlign: 'right'}} item md={2} xs={12}></Grid>
				</Grid>
			</StyledNotificationRow>
      	</Paper>
  )
}


export default NotificationRowMolecule;