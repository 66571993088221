import * as Yup from 'yup';
import { t } from '@lingui/macro';

const createAssetSchema = Yup.object().shape({
  type_id: Yup.string().required(t`Necessaria`),
  category_id: Yup.string().required(t`Necessaria`),
  location_id: Yup.string().required(t`Necessaria`),
  status_id: Yup.string().required(t`Necessaria`),
  name: Yup.string().required(t`Necessaria`),
  available: Yup.boolean().nullable(),
});

export default createAssetSchema;