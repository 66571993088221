import { Trans } from '@lingui/macro';
import LogoMolecule from '@molecules/Logo';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoginFormHolder from '@organisms/LoginFormHolder';
import { StyledMain, StyledColumnLeft, StyledColumnRight, CardBox } from './styled';


const LoginPage = () => {
  return (
    <StyledMain>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: 'var(--100vh)'}}>
        <Grid container spacing={0} direction="row">
          <StyledColumnLeft item md={7} xs={12}>
            <LogoMolecule size="big"></LogoMolecule>
          </StyledColumnLeft>
          <StyledColumnRight item md={5} xs={12}>
            <CardBox>
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Typography fontSize={16} lineHeight="18px" align="center">
                  <Trans>Welcome</Trans>
                </Typography>
                <LoginFormHolder />
              </Box>
            </CardBox>
          </StyledColumnRight>
        </Grid>
      </Grid>
    </StyledMain>
  );
};

export default LoginPage;
