
import * as Yup from 'yup';

export interface IActivityChatProps {
    id: number;
    activity: any;
    chat: any;
}

export interface IFormValues {
    message: number | string | null;
    receiver_id: number | null;
}


export const sendMessageSchema = Yup.object().shape({
  message: Yup.string().required()
});
