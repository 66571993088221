import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { StyledBody } from './styled';
import MainNavigation from '@organisms/Navigation';
import { useDevice } from '@hooks';
import LogoMolecule from '@molecules/Logo';
import { messageService } from '@utils/messagesService';
import { useLocation } from 'react-router-dom'

function Copyright() {
  const device = useDevice();
  return (
    <>
    {
      device.type === 'mobile' ?
        <Box sx={{textAlign:'center'}}><LogoMolecule href={'/'}></LogoMolecule></Box>
      : <Typography sx={{textAlign: 'center', color: '#333333'}} variant="body2">
        {'TC2 Group: '}
        <Link color={'#333333'} target="_blank" href="https://www.tc2group.it/?lang=en">https://www.tc2group.it</Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
    }
    </>
  );
}


const LayoutBase = ({ children, withoutContainer }: any) => {

  const [ footerFixed, setFooterFixed ] = useState(false);
  const location = useLocation();

  const hideFooter = (location.pathname.includes('inspector') && location.pathname.includes('details'));

  useEffect(() => {
    const subS = messageService.getMessage().subscribe(message => {
        if (message && message.text === 'footer-fixed') {
          setFooterFixed(true)
        } else if (message && message.text === 'footer-relative') {
          setFooterFixed(false)
        }
    });
    return () => {
        subS.unsubscribe();
    };
}, [])

  return (
    <>
        <MainNavigation></MainNavigation>
        <StyledBody>
            {
              ! withoutContainer ?
                  <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="lg">
                      { children }
                  </Container>
              : <Box>{ children }</Box>
            }
            
            { !hideFooter && <Box
              component="footer"
              sx={{
                backgroundColor: '#DDDDDD',
                py: 3,
                px: 2,
                mt: 'auto',
                position: footerFixed ? 'fixed' : 'relative',
                bottom: '0px',
                width: '100%',
                paddingTop: {xs: '6px', md: '20px'},
                paddingBottom: {xs: '6px', md: '20px'}
              }}
            >
                <Container maxWidth="sm">
                    <Copyright />
                </Container>
            </Box> }
        </StyledBody>
    </>
  )
}


export default LayoutBase;