import { useState, Fragment, useEffect } from 'react';
import { Alert, Box, Divider } from '@mui/material';
import { Trans } from '@lingui/macro';
import { StyledTitle } from './styled';
import { ActivityModel } from '@models/ActivityModel';
import { useQuery } from 'react-query';
import ActivitiesService from '@services/ActivitiesService';
import { useDispatch } from 'react-redux';
import { set } from '@stores/reducers/titleReducer';
import ActivityRowInspectorMolecule from '@molecules/ActivityRow/InspectorVersion';
import { messageService } from '@utils/messagesService';
import SpinnerAtom from '@atoms/Spinner';
import { useSelector } from 'react-redux';
import { useUser } from '@hooks';


const InspectorActivitiesPage = () => {
	
	const auth = useUser();
	const [ today, setToday ] = useState([]);
	const [ week, setWeek ] = useState([]);
	const dispatch = useDispatch();
	const notificationChannel = useSelector((state: any) => state.app.notificationChannel);

  	useEffect(() => {
		dispatch(set('Activities'))
	}, [dispatch])

	messageService.sendMessage('hide-back-action');
	messageService.sendMessage('footer-fixed');

	const { isLoading, refetch: refetchDaily } = useQuery(
		'activities-today',
		() =>
			ActivitiesService.get('', {date_range:'today'}).then((res: any) => {
				setToday(res.getData())
			}),
		{
			refetchOnWindowFocus: false,
			cacheTime: 0,
			refetchOnMount: false,
		},
	);

	const { isLoading:isLoadingWeek, refetch: refetchWeekly } = useQuery(
		'activities-thisweek',
		() =>
			ActivitiesService.get('', {date_range:'week'}).then((res: any) => {
				setWeek(res.getData())
			}),
		{
			refetchOnWindowFocus: false,
			cacheTime: 0,
			refetchOnMount: false,
		},
	);

	useEffect(() => {
		if(notificationChannel) {
			notificationChannel.bind("client-inspector-notification", (data: any) => {
				if(data.type === 'message') {
					if(data.toId === auth.user.id) {
						if(data.action === 'new-activity') {
							refetchDaily();
							refetchWeekly()
						}
					}
				}
			});
		}
	}, [notificationChannel])


	return (
		<>
			{
				(isLoadingWeek || isLoading) && <SpinnerAtom size={'50px'}/>
			}
			<Box>
				{
					today.length ? 
						<Box>
							<StyledTitle variant='body2'>
								<b><Trans>Today</Trans></b>
							</StyledTitle>
							<Divider sx={{marginBottom: '10px'}}/>
							{
								today.map((activity: ActivityModel, index: number) => {
									return <ActivityRowInspectorMolecule key={index} activity={activity}></ActivityRowInspectorMolecule>
								})
							}
						</Box>
					: null
				}
				<Divider/>
			</Box>
			<Box sx={{marginBottom: '60px'}}>
				{
					week.length ? 
						<Box>
							<StyledTitle>
								<b><Trans>This week</Trans></b>
							</StyledTitle>
							<Divider sx={{marginBottom: '10px'}}/>
							{
								week.map((activity: ActivityModel, index: number) => {
									return <ActivityRowInspectorMolecule key={index} activity={activity}></ActivityRowInspectorMolecule>
								})
							}
						</Box>
					: null
				}
			</Box>
			{
				!today.length && !week.length && !isLoading && !isLoadingWeek && <Alert severity='warning'>
					<Trans>No activities scheduled</Trans>
				</Alert>
			}
		</>
	);
};

export default InspectorActivitiesPage;
