import React, { useState, useEffect, memo } from 'react';
import IAsyncAutocompleteMolecule from './interface';
import { FormControl, FormHelperText, Autocomplete, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { Controller } from "react-hook-form";
import ConnectForm from '@utils/ConnectForm';
import { UseFormReturn, FieldValues } from "react-hook-form";
import Skeleton from 'react-loading-skeleton';
import Alert from '@mui/material/Alert';
import { GetSubObjectByString } from '@utils/helpers';
import { useSelector } from 'react-redux';

/**
 * Please note:
 * in order to make it work while using a react-hook-form
 * you must wrap this component in a <FormProvider {...formMethods}> provided by react-hook-form
 * import { FormProvider, useForm } from "react-hook-form";
 * <FormProvider {...formMethods}>
 *  <AsyncAutocompleteMolecule controlName="name" label='Pick a name' />
 * </FormProvider>
 * @param IAsyncSelectMolecule 
 * @returns 
 */
const AsyncAutocompleteMolecule = ({ 
    service,
    serviceMethod,
    getOptionLabel,
    label, 
    filters, 
    controlName,
    sError,
    except,
    storeName,
    storeCollection, // can be for instance -> 'types' or 'types.activites' to get a sub list
    cacheTime,
    variant,
    emptyValue,
    listId,
    optionLabel,
    optionValue,
    control }: IAsyncAutocompleteMolecule) => {

    const [ result, setResult ] = useState<Array<any>>([]);

    const { isLoading, data } = useQuery(listId, () => service ? (!serviceMethod ? service.get() : service[serviceMethod]()) : filters, {
        refetchOnWindowFocus: false,
        cacheTime: cacheTime,
        refetchOnMount: false
    });
    
    useEffect(() => {
        if(!isLoading && data) {
            if(except) {
                if(typeof except === 'function') {
                    data.data = data.data.filter((item: any) => {
                        return !except(item);
                    });
                }
                if(Array.isArray(except)) {
                    data.data = data.data.filter((item: any) => {
                        return !except.includes(optionValue ? item[optionValue] : item);
                    });
                }
            }
            // @ts-ignore
            setResult(data.data.filter(function (el) {
                return el != null;
            }));
        }
    }, [isLoading, data]);

    return (
        <FormControl fullWidth>
            {
                isLoading || !result.length ? 
                    <Skeleton height={'3.36rem'}/>
                : 
                    <React.Fragment>
                        <ConnectForm>
                            {({ register, setValue, formState }: UseFormReturn<FieldValues, any>) => 
                                <Controller
                                    render={({ field: { onChange, onBlur, value, ref, ...props } }) => (
                                        <React.Fragment>
                                            <Autocomplete
                                                disablePortal
                                                onChange={(event: any, newValue: any) => {
                                                    setValue(controlName, optionValue ? newValue ? newValue[optionValue] : null : newValue, {shouldValidate: true});
                                                }}
                                                value={value ? result.find((item:any) => item.id === Number(value)) : emptyValue}
                                                options={result}
                                                getOptionLabel={getOptionLabel ? getOptionLabel : (item: any) => item.name ? item.name : item}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => <TextField label={label} {...params} />}
                                                {...props}
                                                />
                                        {
                                            !!formState.errors && formState.errors[controlName] && formState.errors[controlName]?.message ? 
                                                <FormHelperText>{!!formState.errors && formState.errors[controlName] && formState.errors[controlName]?.message as String}</FormHelperText>
                                            : null
                                        }
                                        </React.Fragment>
                                    )}
                                    name={controlName}
                                    control={control}
                                />}
                        </ConnectForm>
                        {
                            sError ? 
                                <Alert severity='error' icon={false}>
                                    { sError }
                                </Alert>
                            : null
                        }
                    </React.Fragment>     
            }
        </FormControl>
    );
}

AsyncAutocompleteMolecule.defaultProps = {
    cacheTime: 0,
    listId: String(Math.random()),
    variant: 'filled',
    optionLabel: 'name',
    optionValue: null
}

export default memo(AsyncAutocompleteMolecule);


const AutocompleteMoleculeB = ({ 
    service,
    serviceMethod,
    getOptionLabel,
    label, 
    filters, 
    emptyValue,
    controlName,
    sError,
    storeName,
    storeCollection, // can be for instance -> 'types' or 'types.activites' to get a sub list
    cacheTime,
    except,
    variant,
    listId,
    optionLabel,
    optionValue,
    control }: IAsyncAutocompleteMolecule) => {

    const list = useSelector(
        (state: any) => storeCollection ? GetSubObjectByString(state.filters.filters, storeCollection): null);

    return (
        list ? 
            <AsyncAutocompleteMolecule 
                filters={{data: list}}
                service={service}
                serviceMethod={serviceMethod}
                getOptionLabel={getOptionLabel}
                label={label}
                except={except}
                controlName={controlName}
                emptyValue={emptyValue}
                sError={sError}
                storeName={storeName}
                storeCollection={storeCollection}
                cacheTime={cacheTime}
                variant={variant}
                listId={listId}
                optionLabel={optionLabel}
                optionValue={optionValue}
                control={control}
            />
        : null
    );
}

AutocompleteMoleculeB.defaultProps = {
    cacheTime: 0,
    listId: String(Math.random()),
    variant: 'filled',
    optionLabel: 'name',
    optionValue: null
}

export const AutocompleteMolecule = memo(AutocompleteMoleculeB);