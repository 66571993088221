import {
  Toolbar,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Box,
  Typography,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import LogoMolecule from "@molecules/Logo";
import {
  StyledHeader,
  StyledToolbar,
  StyledLGMenu,
  StyledButtonLink,
  StyledLGMenuWrapper,
} from "./styled";
import ContainerAtom from "@atoms/Container";
import AvatarAtom from "@atoms/Avatar";
//import useScroll from '@hooks/useScroll';
import { MenuModel } from "@models/MenuModel";
import { useSelector } from "react-redux";
import { useDevice } from "@hooks";
import { useUser } from "@hooks";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { messageService } from "@utils/messagesService";
import NotificationBellOrganism from "@organisms/NotificationBell";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Trans } from "@lingui/macro";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import HomeIcon from '@mui/icons-material/Home';


export default function MainNavigation() {
  const user = useUser();
  //const { scrollDirection } = useScroll();
  const title = useSelector((state: any) => state.title.title);
  const device = useDevice();
  const [showBackAction, setShowBackAction] = useState(false);
  const [beamGranted, setBeamGranted] = useState(false);

  const PUSHER_INSTANCE_ID = process.env.REACT_APP_PUSHER_INSTANCE_ID
    ? process.env.REACT_APP_PUSHER_INSTANCE_ID
    : "";

  const APP_ENV = process.env.REACT_APP_PUSHER_ENV;

  const beamsClient = new PusherPushNotifications.Client({
    instanceId: PUSHER_INSTANCE_ID,
  });

  const beamsStart = (tokenAuth: String) => {
    const tokenProvider = new PusherPushNotifications.TokenProvider({
      url: `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}pusher/beams-auth`,
      headers: {
        Authorization: `Bearer ${tokenAuth}`,
      },
    });

    beamsClient
      .start()
      .then((beamsClient: any) => {
        return beamsClient ? beamsClient.getDeviceId() : null;
      })
      .then((deviceId) =>
        console.log("Successfully registered with Beams. Device ID:", deviceId)
      )
      .then(() => beamsClient.setUserId(user.user.id.toString(), tokenProvider))
      .then(() => beamsClient.getUserId())
      .then((user_id) => {
        console.log(user_id, "Setted User ID");
      })
      .then(() => {
        beamsClient.setDeviceInterests([
          `private-notifications-${APP_ENV}`, // .${user.user.id}
        ]);
      })
      .then(() => beamsClient.getDeviceInterests())
      .then((interests) => {
        console.log("Current interests:", interests);
      })
      .then(() => beamsClient.getRegistrationState())
      .then((state) => {
        // PERMISSION_GRANTED_REGISTERED_WITH_BEAMS
        setBeamGranted(true);
        console.log(state, "Registration state");
      })
      .catch(console.error);
  };

  const handleBeamsStart = () => {
    // Push notifications
    if (user.token) {
      beamsStart(user.token);
    }
  };

  useEffect(() => {
    const subS = messageService.getMessage().subscribe((message) => {
      if (message && message.text === "hide-back-action") {
        setShowBackAction(false);
      }
      if (message && message.text === "show-back-action") {
        setShowBackAction(true);
      }
    });
    return () => {
      subS.unsubscribe();
    };
  }, []);

  const menuPages = useSelector((state: any) => state.menu.items);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const isNotGranted = () => {
    if (device.os === 'ios') return false;
    return Notification.permission !== "granted";
  };

  const displayDesktop = () => {
    return (
      <StyledToolbar>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <LogoMolecule href={"/"}></LogoMolecule>
          {isNotGranted() && !beamGranted && (
            <Button
              variant="contained"
              color="warning"
              onClick={handleBeamsStart}
            >
              <NotificationsActiveIcon />
              &nbsp;<Trans>Activate the notifications</Trans>
            </Button>
          )}
        </Box>
        <StyledLGMenuWrapper>
          <StyledLGMenu>{getMenuButtons(menuPages)}</StyledLGMenu>
          <NotificationBellOrganism />
          <AvatarAtom dropdownMenu={true} />
        </StyledLGMenuWrapper>
      </StyledToolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar sx={{zIndex: 0}}>
        {!user.isInspector() ? (
          <IconButton
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon />
          </IconButton>
        ) : showBackAction ? (
          <ArrowBackIosIcon
            onClick={() => messageService.sendMessage("back")}
          ></ArrowBackIosIcon>
        ) : null}
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div style={{ padding: "20px 30px" }}>
            {getDrawerChoices(menuPages)}
          </div>
        </Drawer>
        <div style={{display: 'flex'}}>
          {device.type !== "mobile" && <LogoMolecule href={"/"}></LogoMolecule>}
          {isNotGranted() && !beamGranted && (
            <Button
              sx={{padding: '1px', minWidth: '30px'}}
              size="small"
              variant="contained"
              color="warning"
              onClick={handleBeamsStart}
            >
              <NotificationsActiveIcon fontSize='small' />
            </Button>
          )}
        </div>
        { title === 'Notifications' && device.type === 'mobile' && <RouterLink style={{marginRight: '10px'}} to={'/'}>
          <HomeIcon/>
        </RouterLink> }
        {title && (
          <Typography sx={{ margin: "0 auto", fontSize: '2rem !important' }} variant="h3">
            {title}
          </Typography>
        )}
        <NotificationBellOrganism />
        <AvatarAtom dropdownMenu={true} />
      </Toolbar>
    );
  };

  const getDrawerChoices = (pages: Array<MenuModel>) => {
    return pages.map(({ name, route }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: route,
            color: "inherit",
            style: { textDecoration: "none" },
            key: name,
          }}
        >
          <MenuItem>{name}</MenuItem>
        </Link>
      );
    });
  };

  const getMenuButtons = (pages: Array<MenuModel>) => {
    return pages.map(({ name, route }) => {
      return (
        <StyledButtonLink
          variant="outlined"
          {...{
            key: name,
            color: "inherit",
            to: route,
            component: RouterLink,
          }}
        >
          {name}
        </StyledButtonLink>
      );
    });
  };

  return (
    <StyledHeader
      className={user.isInspector() ? 'inspector active' : 'active'} /*scrollDirection ? scrollDirection === "down" ? 'active' : 'hidden' : 'active'*/
    >
      <Box style={{ backgroundColor: "#2058e8" }}>
        <ContainerAtom>
          {mobileView ? displayMobile() : displayDesktop()}
        </ContainerAtom>
      </Box>
    </StyledHeader>
  );
}
