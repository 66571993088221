import * as React from 'react';
import { Grid, Box, Button, CircularProgress, Alert } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectMolecule }  from '@molecules/AsyncSelect';
import { Trans } from '@lingui/macro';
import TextFieldAtom from '@atoms/TextField';
import IPersonCreateOrganism, { IFormValues } from './interface';
import createPersonSchema from '@validation/createPerson';
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import PeopleService from '@services/PeopleService';
import AttributesListMolecule from '@molecules/AttributesList';
import { IDS } from '@utils/constants';
import { fetchFilter } from '@stores/reducers/filtersReducer';
import { useAppDispatch } from '@stores/hooks';


/**
 * Including a double-layout mode ('patch' and 'normal')
 * @param mode 
 * @returns 
 */
const PersonCreateOrganism = ({ mode, person, onNewPerson, hideAttributes, onUpdate, onCancel }: IPersonCreateOrganism) => {
	
	const [ sErrors, setSErrors ] = React.useState<any | null>(null);
	const [ msg, setMsg ] = React.useState('');
	const [ success, setSuccess ] = React.useState<boolean>(false);
	const [ attributesValid, setAttributesValid ] = React.useState<boolean>(false);

    const dispatch = useAppDispatch();

	const formInstance = useForm<IFormValues>({
		defaultValues: {
			role_id: IDS.ROLES.USER,
			category_id: person ? person.category ? person.category.id : undefined : undefined,
			type_id: person ? person.type ? person.type.id : undefined : undefined,
			name: person ? person.name ? person.name : undefined : '',
			surname: person ? person.surname ? person.surname : undefined : '',
			location_id: person ? person.location ? person.location.id : undefined : undefined,
			email: person ? person.email ? person.email : undefined : '',
			phone: person ? person.phone ? person.phone : undefined : '',
			//password: '',
			//password_confirmation: '',
			attributes: []
		},
		mode: 'onChange',
		reValidateMode: 'onSubmit',
		resolver: yupResolver(createPersonSchema(mode)),
	});
	
	const { mutate: createAssetMutation, isLoading } = useMutation(
		(formValues:IFormValues) => (mode === 'patch' && person
			? PeopleService.put(formValues, person.id)
			: PeopleService.create(formValues)).then((res: any) => {
			if(!res.hasErrors()) {
				dispatch(fetchFilter('users'));
				if(!person) {
					setMsg(res.getMsgString())
					setSuccess(true);
				}
				toast(res.getMsgString(), {
					type: 'success'
				});
				if(onNewPerson && !person) {
					onNewPerson(res.getData())
				}
				if(person) { // updated
					onUpdate(res.getData())
				}
			} else {
				setMsg(res.getMsgString())
				setSErrors(res.getErrors())
				toast(res.getMsgString(), {
					type: 'error'
				});
			}
		})
	);

	const {
		control,
		setValue,
		formState: { isValid },
		handleSubmit: handleHookSubmit,
	} = formInstance;

	const handleSubmit = (data: IFormValues) => {
		const values = {...data};
		setSErrors([]);
		setMsg('');
		createAssetMutation(values);
	}

	const getAttributesList = (values: any) => {
		setValue('attributes', values)
	}

	return (
		<Box sx={{ width: '100%' }}>
			<form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
				<FormProvider {...formInstance }>
					{
						success ? (
							<React.Fragment>
								{
									msg ? 
										<Box mt={2}><Alert severity="success">{ msg }</Alert></Box>
									: null
								}
							</React.Fragment>
						) : (
						<React.Fragment>
							<Box>
								<Grid container mt={1} spacing={2}>
									<Grid item md={6}>
										<TextFieldAtom
											controlName={'name'}
											required={true}
											variant={'outlined'}
											label={<Trans>Name</Trans>}/>
											{
												sErrors && sErrors['name'] ? 
													<Alert severity='error' icon={false}>
														{ sErrors['name'] }
													</Alert>
												: null
											}
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<TextFieldAtom
											controlName={'surname'}
											required={true}
											variant={'outlined'}
											label={<Trans>Surname</Trans>}/>
											{
												sErrors && sErrors['surname'] ? 
													<Alert severity='error' icon={false}>
														{ sErrors['surname'] }
													</Alert>
												: null
											}
									</Grid>
								</Grid>
								<Grid container mt={1} spacing={2}>
									<Grid item md={6}>
										<TextFieldAtom
											controlName={'email'}
											required={true}
											variant={'outlined'}
											label={<Trans>Email</Trans>}/>
											{
												sErrors && sErrors['email'] ? 
													<Alert severity='error' icon={false}>
														{ sErrors['email'] }
													</Alert>
												: null
											}
									</Grid>
									<Grid item md={6}>
										<TextFieldAtom
											controlName={'phone'}
											variant={'outlined'}
											label={<Trans>Phone</Trans>}/>
											{
												sErrors && sErrors['phone'] ? 
													<Alert severity='error' icon={false}>
														{ sErrors['phone'] }
													</Alert>
												: null
											}
									</Grid>			
								</Grid>
								
								{/*
									mode !== 'patch' ? 
										<Grid container mt={1} spacing={2}>
											<Grid item xs={12} sm={12} md={6}>
												<TextFieldAtom
													controlName={'password'}
													required={true}
													type={'password'}
													variant={'outlined'}
													label={<Trans>Password</Trans>}/>
													{
														sErrors && sErrors['password'] ? 
															<Alert severity='error' icon={false}>
																{ sErrors['password'][0] }
															</Alert>
														: null
													}
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<TextFieldAtom
													controlName={'password_confirmation'}
													required={true}
													type={'password'}
													variant={'outlined'}
													label={<Trans>Repeat password</Trans>}/>
													{
														sErrors && sErrors['password_confirmation'] ? 
															<Alert severity='error' icon={false}>
																{ sErrors['password_confirmation'] }
															</Alert>
														: null
													}
											</Grid>
										</Grid>
									: null
								*/}
								<Grid container mt={1} spacing={2}>
									<Grid item xs={12} sm={12} md={6}>
										<SelectMolecule
											control={control}
											controlName="location_id"
											sError={sErrors ? sErrors['location_id'] : undefined}
											storeCollection="locations"
											optionValue={'id'}
											variant={'outlined'}
											label={<Trans>Department</Trans>}
										></SelectMolecule>
										{ /*
											<SelectMolecule
												control={control}
												controlName="role_id"
												required={true}
												sError={sErrors ? sErrors['role_id'] : undefined}
												optionValue={'id'}
												storeCollection="roles"
												variant={'outlined'}
												label={<Trans>Role</Trans>}
											></SelectMolecule>
								        */}
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<SelectMolecule
											control={control}
											controlName="type_id"
											sError={sErrors ? sErrors['type_id'] : undefined}
											optionValue={'id'}
											storeCollection="types.users"
											variant={'outlined'}
											label={<Trans>Seniority</Trans>}
										></SelectMolecule>
									</Grid>
								</Grid>
								<Grid container mt={1} spacing={2}>
									<Grid item xs={12} sm={12} md={6}>
										<SelectMolecule
											control={control}
											controlName="category_id"
											listId={'people-create-role'}
											storeCollection="categories.users"
											variant={'outlined'}
											optionValue={'id'}
											optionLabel={'name'}
											label={<Trans>Role</Trans>}
										></SelectMolecule>
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<></>
									</Grid>
								</Grid>
							</Box>
							{
								! hideAttributes ?
									<Box mt={1}>
										<AttributesListMolecule
											onFormChange={getAttributesList}
											notifyValidation={setAttributesValid}
											modelType={'users'}/>
									</Box>
								: null
							}
							<Box mt={2}>	
								{
									sErrors && msg ? 
										<Alert severity="error">{ msg }</Alert>
									: null
								}
								<Box mt={2} sx={{textAlign:'right'}}>
									{
										onCancel ?
											<Button
												sx={{marginRight: '5px'}}
												variant="outlined"
												className='cancel-btn'
												onClick={onCancel}
												disabled={isLoading}
											>
												<Trans>Cancel</Trans>
											</Button>
										: null
									}
									<Button type="submit" disabled={!isValid || mode !== 'patch' && !attributesValid} variant="contained">
									{
										isLoading ?
											<CircularProgress />
										: mode === 'patch' ? <Trans>Update person</Trans> : <Trans>Create person</Trans>
									}
									</Button>
								</Box>
							</Box>
						</React.Fragment>)
					}
				</FormProvider>
			</form>
		</Box>
	);
}

export default PersonCreateOrganism;