import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Typography, Card, Grid, CardContent, Paper } from '@mui/material';
import AssetsService from '@services/AssetsService';
import SpinnerAtom from '@atoms/Spinner';
import DocumentsOrganism from '@organisms/Documents';
import NotesOrganism from '@organisms/Notes';
import IAssetDetailOrganism from './interface';
import { Trans } from '@lingui/macro';
import { AssetsModel } from '@models/AssetsModel';
import TabsMolecule from '@molecules/Tabs';
import AssetDetailSpecs from '@organisms/AssetDetail/Specs';
import MaintenancesOrganism from '@organisms/Maintenances';


const AssetDetailOrganism = ({ id, onUpdate }: IAssetDetailOrganism) => {
  const [asset, setResults] = useState<AssetsModel | undefined>(undefined);

  const { isLoading, data } = useQuery(
    `asset-detail${id}`,
    () => AssetsService.get(id).then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  const assetUpdated = (asset: AssetsModel) => {
	if(onUpdate) {
		onUpdate(asset);
	}
  }

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data.data);
    }
  }, [isLoading, data]);

  const assetDetailPanel = () => {
    return (
		<>
			<Box>
				<Typography variant='h4' color="text.secondary" gutterBottom>
					<Trans>Asset details</Trans>
				</Typography>
        		<Card variant='outlined' elevation={0} sx={{ minWidth: 275 }}>
					<CardContent sx={{maxWidth:'300px'}}>
						<Typography variant='h5' color="text.secondary" gutterBottom>
							{ asset?.name }
						</Typography>
						<Grid container>
							<Grid item sm={6}>
								<Typography variant="body2" color="text.secondary">
									{ asset?.category.name }
								</Typography>
							</Grid>
							<Grid item sm={6}>
								<Typography variant="body2">
									{ asset?.location.name }
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				<TabsMolecule
					tabsLabels={[
						{label: 'Specs', child: asset ? <AssetDetailSpecs onUpdate={assetUpdated} asset={asset}/> : null},
						{label: 'Notes', child: <Paper variant='outlined' sx={{marginTop: '15px'}}><Box><NotesOrganism model_id={id} model_type={'assets'}/></Box></Paper>},
						{label: 'Maintenances', child: <Box mt={2}><MaintenancesOrganism model_id={id} model_type={'assets'}/></Box>},
						{label: 'Docs', value: 'week', child: <Paper variant='outlined' sx={{marginTop: '15px'}}><Box><DocumentsOrganism model_id={id} model_type={'assets'} /></Box></Paper>}
					]}/>
			</Box>
      </>
    );
  };

  return <>{asset ? assetDetailPanel() : <SpinnerAtom />}</>;
};

export default AssetDetailOrganism;
