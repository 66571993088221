import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MediaHandler from './MediaHandler';
import Pusher from 'pusher-js';
import Peer from 'simple-peer';
import { useSelector } from 'react-redux';


export default class VideoPeer2Peer extends Component {

    // const pusherInstance = useSelector((state: any) => state.app.pusherInstance);

    constructor() {
        super();

        this.state = {
            hasMedia: false,
            otherUserId: null
        };

        this.videoRefs = {};

        this.user = JSON.parse(localStorage.getItem('user')).user;
        this.user.stream = null;
        this.peers = {};

        this.mediaHandler = new MediaHandler();
        this.setupPusher();

        this.callTo = this.callTo.bind(this);
        this.setupPusher = this.setupPusher.bind(this);
        this.startPeer = this.startPeer.bind(this);
    }

    componentWillMount() {
        this.mediaHandler.getPermissions()
            .then((stream) => {
                this.setState({hasMedia: true});
                this.user.stream = stream;

                try {
                    this.myVideo.srcObject = stream;
                } catch (e) {
                    this.myVideo.src = URL.createObjectURL(stream);
                }

                this.myVideo.play();
            })
    }

    setupPusher() {
      const tokenAuth = JSON.parse(localStorage.getItem("user")).token;

        this.pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
          auth: {
            headers: {
              Authorization: `Bearer ${tokenAuth}`,
            },
          },
          cluster: "eu",
          forceTLS: true,
          encrypted: true,
          authEndpoint: `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}pusher/auth`,
        });

        this.channel = this.pusher.subscribe('presence-video-channel');

        this.channel.bind("pusher:subscription_succeeded", (members) => {
          console.log(members, 'subscription_succeeded');
        });
        this.channel.bind("pusher:member_added", (members) => {
          console.log(members, 'members added');
        });
        this.channel.bind("pusher:member_removed", (members) => {
          console.log(members, 'members removed');
        });

        this.channel.bind(`client-signal-${this.user.id}`, (signal) => {
          if (signal.isAnswer) {
            let peer = this.peers[signal.userId];

            // if peer is not already exists, we got an incoming call
            if(peer === undefined) {
                this.setState({otherUserId: signal.userId});
                peer = this.startPeer(signal.userId, false, true);
            }

            peer.signal(signal.data);
          } else {
            const text = `Clicca OK per accettare la chiamata di ${signal.user_info}`;
            if (window.confirm(text)) {
              let peer = this.peers[signal.userId];

              // if peer is not already exists, we got an incoming call
              if(peer === undefined) {
                  this.setState({otherUserId: signal.userId});
                  peer = this.startPeer(signal.userId, false, true);
              }

              peer.signal(signal.data);
            }
          }
        });
    }

    startPeer(userId, initiator = true, isAnswer = false) {
        const peer = new Peer({
            initiator,
            stream: this.user.stream,
            trickle: false
        });

        peer.on('signal', (data) => {
            this.channel.trigger(`client-signal-${userId}`, {
                type: 'signal',
                userId: this.user.id,
                user_info: this.user.name,
                data: data,
                isAnswer
            });
        });

        peer.on('stream', (stream) => {
          try {
            this.partnerVideo.srcObject = stream;
          } catch (e) {
            this.partnerVideo.src = URL.createObjectURL(stream);
          }

          this.partnerVideo.play();
        });

        peer.on('close', () => {
            let peer = this.peers[userId];
            if(peer !== undefined) {
                peer.destroy();
            }

            this.peers[userId] = undefined;
        });

        return peer;
    }

    callTo(userId) {
        this.peers[userId] = this.startPeer(userId);
    }

    render() {
        return (
            <div className="App">
                {[1,2,3,4].map((userId) => {
                    return this.user.id !== userId ? <button key={userId} onClick={() => this.callTo(userId)}>Call {userId}</button> : null;
                })}

                <div className="video-container">
                    <video className="my-video" muted ref={(ref) => {this.myVideo = ref;}}></video>
                    <video className="user-video" ref={(ref) => {this.partnerVideo = ref;}}></video>
                </div>
            </div>
        );
    }
}

if (document.getElementById('app')) {
    ReactDOM.render(<VideoPeer2Peer />, document.getElementById('app'));
}
