import { CoreBaseService } from '@core/services/Base.service';

// Reference model
import { NotesModel } from '@models/NotesModel';

class NotesService extends CoreBaseService {
  constructor() {
    super(NotesModel);
  }
}

export default new NotesService();
