import { memo, useEffect } from 'react';
import { useAppDispatch } from '@stores/hooks';
import { fetchFilters } from '@stores/reducers/filtersReducer';
import { fetchMenu } from '@stores/reducers/menuReducers';

const PrivateMiddleLayer = (props: any) => {
    // Get filters
    const dispatch = useAppDispatch();

    // Run only once
    useEffect(() => {
        dispatch(fetchFilters({ resources: ['assets', 'categories', 'roles', 'users', 'activities', 'types', 'customers', 'partners', 'locations', 'statuses'] }));
        dispatch(fetchMenu({}));
    }, []);

    return <>{props.children}</>;
};

export default memo(PrivateMiddleLayer);
