import { useEffect, useState, Fragment, useRef, useCallback } from 'react';
import { useQuery } from 'react-query';
import { Box, Grid, Card, CardContent, Typography } from '@mui/material';
import { ActivityModel } from '@models/ActivityModel';
import ActivitiesService from '@services/ActivitiesService';
import SpinnerAtom from '@atoms/Spinner';
import { Trans, t } from '@lingui/macro';
import { useParams } from "react-router-dom";
import InspectorActivityMobMenu from '@molecules/InspectorActivityMobMenu';
import { set } from '@stores/reducers/titleReducer';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import { StyledCheckButton } from './styled';
import ChipAtom from '@atoms/Chip';
import { Link } from 'react-router-dom';
import { messageService } from '@utils/messagesService';
import { useDevice } from '@hooks';
import DatePrintAtom from '@atoms/DatePrint';
// Pages
import InspectorActivityDocsPage from '@pages/inspector/activity/docs';
// import InspectorActivityCallPage from '@pages/inspector/activity/call';
// import InspectorActivityChatPage from '@pages/inspector/activity/chat';
import InspectorActivityDetailsPage from './details';
import InspectorActivityEvaluatePage from './evaluate';
import { useLocation } from 'react-router-dom'
import { startChat } from '@stores/reducers/chatReducer';


const InspectorActivityPage = () => {

    const device = useDevice();
    const location = useLocation();
    const { id } = useParams();
    const [ activity, setResults ] = useState<ActivityModel | undefined>(undefined);
    const [ currentTab, setCurrentTab ] = useState('');
    const [ defaultTab, setDefaultTab ] = useState('details');
    const dispatch = useDispatch();
    const [ refreshMenuKey, setTefreshMenu ] = useState(0);
    const confirm = useConfirm();
    const activitiesLink = useRef<any>();
    
    messageService.sendMessage('show-back-action');
    messageService.sendMessage('footer-relative');

    useEffect(() => {
        if(location.hash === '#chat') {
            dispatch(startChat({activity_id: id}));
        }
        const subS = messageService.getMessage().subscribe(message => {
            if (message && message.text === 'back' && activitiesLink.current) {
                activitiesLink.current.click();
            }
        });
        return () => {
            subS.unsubscribe();
        };
    }, []);

    useEffect(() => {
        dispatch(set('Activity'))
    }, [dispatch])

    useEffect(() => {
        
    }, [currentTab])

    const { isLoading, data, refetch } = useQuery(
        `activity-detail-${id}`,
        () => ActivitiesService.get(id).then((res: any) => res),
        {
          refetchOnWindowFocus: false,
          cacheTime: 0,
          refetchOnMount: false,
        },
    );

    useEffect(() => {
        if (!isLoading && data) {
          setResults(data.data);
        }
    }, [isLoading, data]);

    const changeStatus = (status_id: number) => {
        if(activity) {
            if(activity.nextStatus().alias === 'checkin') {
                confirm({ description: t`Are you sure you want to change the status of this activity?` })
                .then(() => {
                    ActivitiesService.changeStatus(status_id, activity.id).then((res: any) => {
                        toast(t`Status changed successfully`, { type: 'success' });
                        refetch();
                    });
                });
            } else if (activity.nextStatus().alias === 'checkout') {
                setDefaultTab('evaluate');
                setTefreshMenu(refreshMenuKey + 1)
            }
        }
    }

    const tabSelectionHandler = useCallback((alias: string) => {
        setCurrentTab(alias);
    }, []);

    const handleEvaluated = () => {
        refetch();
        setCurrentTab('details');
    }

    return (
        <>
            <Box sx={{marginBottom: '80px'}}>
                <Link ref={activitiesLink} to={'/inspector/activities'}></Link>
                {
                    <Fragment>
                        <InspectorActivityMobMenu
                            onSelection={tabSelectionHandler}
                            activity={activity}
                            defaultTab={defaultTab}
                            key={refreshMenuKey}
                            id={id} />
                        {
                            activity ?
                                <Fragment>
                                    <Fragment>
                                        <Box mb={1}>
                                            <Card variant='outlined' elevation={0} sx={{ minWidth: 275 }}>
                                                <CardContent>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6} sm={6} md={6}>
                                                            <Typography variant='h5' color="text.secondary" gutterBottom>
                                                                {
                                                                    activity?.date ? 
                                                                        <DatePrintAtom format='DD/MM/YYYY'>{ activity.date }</DatePrintAtom>
                                                                    : '--'
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} md={6} sx={{textAlign:'right'}}>
                                                            { activity.status ? <ChipAtom variant='filled' color={activity.status.class} label={activity.status.name} /> : '--' }
                                                        </Grid>
                                                    </Grid>
                                                    <Typography variant="h5" component="div"></Typography>
                                                    <Grid container>
                                                        <Grid item sm={6} xs={6}>
                                                            <Typography variant="body2" color="text.secondary">
                                                                <Trans>Client:</Trans> { activity?.customer ? activity?.customer?.name : '--' }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        activity.lastCheckin('created_at') && <Grid container mt={2}>
                                                            <Grid item sm={6} xs={6}>
                                                                <b><Trans>Last check in</Trans></b>
                                                            </Grid>
                                                            <Grid item sm={6} xs={6}>
                                                                <b><DatePrintAtom format='DD/MM/YYYY HH:mm'>{  activity.lastCheckin('created_at') }</DatePrintAtom></b>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </CardContent>
                                            </Card>
                                        </Box> 
                                        <Box mt={2}>
                                            {
                                                activity.nextStatus() && currentTab !== 'evaluate' ? 
                                                    <StyledCheckButton 
                                                        onClick={() => changeStatus(activity.nextStatus().id)}
                                                        fullWidth 
                                                        variant="contained"
                                                        color={activity.nextStatus().class}>
                                                        { activity.nextStatus().name }
                                                    </StyledCheckButton>
                                                : null
                                            }
                                        </Box>
                                    </Fragment>
                                        {
                                            (() => {
                                            switch (currentTab) {
                                                case "details": return <InspectorActivityDetailsPage activity={activity} />;
                                                case "docs": return <InspectorActivityDocsPage activity={activity} />;
                                                // case "chat": return <InspectorActivityChatPage activity={activity} />;
                                                // case "call": return <InspectorActivityCallPage activity={activity} />;
                                                case "evaluate": return <InspectorActivityEvaluatePage activity={activity} onSave={handleEvaluated}/>;
                                            }
                                          })()
                                        }
                                </Fragment>
                            : <SpinnerAtom size={'50px'}></SpinnerAtom> 
                        }
                    </Fragment>
                }
            </Box>
        </>
    );
};

export default InspectorActivityPage;
