import { useEffect, useState, Fragment } from 'react';
import { useQuery } from 'react-query';
import { Box, Grid, Card, CardContent, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ActivityModel } from '@models/ActivityModel';
import ActivitiesService from '@services/ActivitiesService';
import SpinnerAtom from '@atoms/Spinner';
import DocumentsOrganism from '@organisms/Documents';
import NotesOrganism from '@organisms/Notes';
import IActivityDetailOrganism from './interface';
import { StyledFuncWrapper } from './styled';
import { Trans, t } from '@lingui/macro';
import StatusesMolecule from '@molecules/Statuses';
import ChipAtom from '@atoms/Chip';
import ActivityDetailResumeOrganism from './Details';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import { useUser } from '@hooks';
import DatePrintAtom from '@atoms/DatePrint';

const ActivityDetailOrganism = ({ id, onUpdate }: IActivityDetailOrganism) => {

  const [activity, setResults] = useState<ActivityModel | undefined>(undefined);
  const [ editGeneric, setEditGeneric ] = useState<boolean>(false);
  const [ infoIsExpanded, setInfoIsExpanded ] = useState<boolean>(true);
  const user = useUser();

  const { isLoading, data, refetch } = useQuery(
    `activity-detail${id}`,
    () => ActivitiesService.get(id).then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  const clickEditGeneric = (event: any, edit:boolean) => {
	setEditGeneric(edit);
	if(infoIsExpanded) {
		event.stopPropagation();
	}
  }

  const onInfoModified = (activity: ActivityModel) => {
	setEditGeneric(false);
	refetch();
  }

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data.data);
	  if(onUpdate) {
		  onUpdate(data.data);
	  }
    }
  }, [isLoading, data]);

  const accordionChangeStatus = (expanded: boolean) => {
	setInfoIsExpanded(expanded);
  }

  const activityDetailPanel = () => {
    return (
		<>
			<Box mb={1}>
				<Typography variant='h4' color="text.secondary" gutterBottom>
					<Trans>Activity details</Trans>
				</Typography>
				<Card variant='outlined' elevation={0} sx={{ minWidth: 275 }}>
					<CardContent sx={{maxWidth:'300px'}}>
						<Typography variant='h5' color="text.secondary" gutterBottom>
							{
								activity?.date ? 
									<DatePrintAtom format='DD/MM/YYYY'>{ activity.date }</DatePrintAtom>
								: '--'
                          	}
						</Typography>
						<Typography variant="h5" component="div"></Typography>
						<Grid container>
							<Grid item sm={6}>
								<Typography variant="body2" color="text.secondary">
									{ activity?.customer ? activity?.customer?.name : '--' }
								</Typography>
							</Grid>
							<Grid item sm={6}>
								<Typography variant="body2">
									{ activity?.partner?.name }
								</Typography>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item sm={12}>
								<Typography variant="body2">
									<Trans>Owner</Trans>: &nbsp;{ activity?.user?.name } { activity?.user?.surname }
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Box>
			<Grid container spacing={1}>
				<Grid item sm={12} md={7}>
					<StyledFuncWrapper>
						<Accordion onChange={(event: any, expanded: boolean) => accordionChangeStatus(expanded)} defaultExpanded={true} elevation={0}>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<span style={{display: 'flex'}}>
									<span><Typography variant='h5'><b><Trans>Generic</Trans></b></Typography></span>
								</span>
								{
									user.isAdmin() ?
										<Fragment>
											{
												!editGeneric ? 
													<Tooltip title={t`Edit activity`} placement="top">
														<IconButton onClick={(event: any) => clickEditGeneric(event, true)}>
															<EditIcon color='success'></EditIcon>
														</IconButton>
													</Tooltip>
												: <Tooltip title={t`Cancel`} placement="top">
													<IconButton onClick={(event: any) => clickEditGeneric(event, false)}>
														<CancelIcon color='success'></CancelIcon>
													</IconButton>
												</Tooltip>
											}
										</Fragment>
									: <></>
								}
							</AccordionSummary>
							<AccordionDetails>
								<ActivityDetailResumeOrganism
									onCancel={() => setEditGeneric(false)}
									isEdit={editGeneric}
									activity={activity}
									onSave={onInfoModified} />
							</AccordionDetails>
						</Accordion>
					</StyledFuncWrapper>
				</Grid>
				<Grid item sm={12} md={5}>
					{
						user.isAdmin() ?
							<Fragment>
								<StyledFuncWrapper>
									<Accordion defaultExpanded={true} elevation={0}>
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content">
											<span><Typography variant='h5'><b><Trans>Notes</Trans></b></Typography></span>
										</AccordionSummary>
										<AccordionDetails>
											<NotesOrganism model_id={id} model_type="activities" />
										</AccordionDetails>
									</Accordion>
								</StyledFuncWrapper>
								<StyledFuncWrapper>
									<Accordion defaultExpanded={activity?.status_logs.length ? true : false} elevation={0}>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<span><Typography variant='h5'>
												<b><Trans>Status</Trans></b></Typography>
											</span>
											&nbsp;
											<span>{activity?.status ? <ChipAtom label={activity.status.name} color={activity.status.class} size='small' /> : ''}</span>
										</AccordionSummary>
										<AccordionDetails>
											{ activity && <StatusesMolecule activity={activity} status_logs={activity?.status_logs}/> }
										</AccordionDetails>
									</Accordion>
								</StyledFuncWrapper>
								<StyledFuncWrapper>
									<Accordion elevation={0}>
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
											<span><Typography variant='h5'><b><Trans>Docs</Trans></b></Typography></span>
										</AccordionSummary>
									<AccordionDetails>
										<DocumentsOrganism model_id={id} model_type="activities" />
									</AccordionDetails>
									</Accordion>
								</StyledFuncWrapper>
							</Fragment>
						: <></>
					}
				</Grid>
        	</Grid>
      </>
    );
  };

  return <>{activity ? activityDetailPanel() : <SpinnerAtom />}</>;
};

export default ActivityDetailOrganism;
