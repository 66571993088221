import { CoreBaseService } from '@core/services/Base.service';
import AttributesService from '@services/AttributesService';

// Reference model
import { AssetsModel } from '@models/AssetsModel';

class AssetsService extends CoreBaseService {
    constructor() {
        super(AssetsModel);
    }

    getTotals() {
        return this.sub('totals').get();
    }

    setAttributes(assetId: number, attributes: any) {
        return AttributesService.create({
            model_id: assetId,
            model_type: 'assets',
            values: attributes
        });
    }
}

export default new AssetsService();
