import { memo } from 'react';
import type { IDropzoneAttachmentsProps } from './interface';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@atoms/Chip';

const DropzoneAttachments = ({ isDisabled, files, handleDelete }: IDropzoneAttachmentsProps) => (
  <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
    {files.map(({ id, name }) => (
      <Tooltip key={id} title={name} placement="top">
        <Chip sx={{ mr: 1, maxWidth: '140px' }} label={name} disabled={isDisabled} onDelete={() => handleDelete(id)} />
      </Tooltip>
    ))}
  </Stack>
);

export default memo(DropzoneAttachments);
