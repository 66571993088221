import { CoreBaseService } from '@core/services/Base.service';

// Reference model
import { QuestionModel } from '@models/QuestionModel';


class QuestionsService extends CoreBaseService {

    constructor() {
        super(QuestionModel);
    }

    list(resource: string) {
        return this.sub(`${resource}`).get();
    }
  
}

export default new QuestionsService();