import { CoreBaseService } from '@core/services/Base.service';

// Reference model
import { ActivityModel } from '@models/ActivityModel';


class ActivitesService extends CoreBaseService {

    constructor() {
        super(ActivityModel);
    }

    getTotals() {
        return this.sub('totals').get();
    }

    copyActivity(id: number) {
        return this.sub(`${id}/copy`).create({});
    }

    jobs() {
        return this.sub('jobs').get();
    }

    changeStatus(status_id: number, activity_id: number) {
        return this.sub(`${activity_id}/change_status`).update({status_id}, -1);
    }
  
}

export default new ActivitesService();