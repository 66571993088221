import { zIndex } from '@theme/Variables';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { remCalc } from '@utils/helpers';


export const StyledBody = styled(Box)(() => ({
  zIndex: `${zIndex.flat} !important`,
  marginTop: remCalc(20),
  backgroundColor: 'rgb(248, 249, 250)',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}));