import { useState, Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { toast } from 'react-toastify';
import { Grid, Box, Typography, Paper, Button } from '@mui/material';
import Modal from '@molecules/Modal';
import ProviderCreateOrganism from '@organisms/ProviderCreate';
import LayoutModal from '@layouts/modal';
import PaginatedListMolecule from '@molecules/PaginatedList';
import { StyledFilterContainer, StyledHeaderContainer, StyledGridItem } from './styled';
import ClientsService from '@services/ClientsService';
import AddIcon from '@mui/icons-material/Add';
import { ClientModel } from '@models/ClientModel';
import ProviderFilterOrganism from '@organisms/ProviderFilter';
import ProviderRow from '@molecules/ProviderRow';
import { IDS } from '@utils/constants';
import { useConfirm } from 'material-ui-confirm';


const ProvidersPage = () => {

    const [ refetchKey, setRefetchKey ] = useState<any>(0);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ drawerDetail, setDrawerDetail ] = useState<any>({});
    const [ filter, setFilter ] = useState<any>({search: '', type_id: IDS.CUSTOMERS.PROVIDER});
    const [ newRow, setNewRows ] = useState<ClientModel | undefined>(undefined);
    const [ toDelete, setToDeleteFromList ] = useState<number | undefined>(undefined);
    const [ updatedItem, setUpdated ] = useState<ClientModel | undefined>(undefined);
    const confirm = useConfirm();

    const newProviderCreated = (client: ClientModel) => {
        setNewRows(client);
    }

    const onProviderUpdate = (client: ClientModel) => {
        setUpdated(client);
    }

    const handleActionSelect = (param: any) => {
        if (param.label === 'Edit') {
            setModalOpen(true)
            setDrawerDetail(param.data.provider)
          } else if (param.label === 'Delete') {
            confirm({ description: 'This action is permanent!' })
            .then(() => { 
                ClientsService.delete(param.data.provider.id).then((res: any) => {
                if (!res.hasErrors()) {
                    setToDeleteFromList(param.data.provider.id)
                    toast(res.getMsgString(), {
                        type: 'success',
                    });
                    setRefetchKey(refetchKey + 1);
                } else {
                    toast(res.getMsgString(), {
                        type: 'error',
                    });
                }
                });
            });
          }
    }

    return (
        <>
            <Fragment>
                <Modal open={modalOpen} set_open={setModalOpen}>
                    <LayoutModal title={ !! drawerDetail ? <Trans>Update provider</Trans> : <Trans>New provider</Trans>}>
                        <ProviderCreateOrganism onUpdate={onProviderUpdate} onNew={newProviderCreated} user={drawerDetail} mode={drawerDetail ? 'patch' : ''} />
                    </LayoutModal>
                </Modal>
            </Fragment>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant={'h4'}>
                    <Trans>Settings/Providers</Trans>
                </Typography>
                <Box sx={{textAlign:'right'}} mb={2}>
                    <Button variant='outlined' onClick={() => { setDrawerDetail(null); setModalOpen(true)}}>
                        <AddIcon></AddIcon> Create provider
                    </Button>
                </Box>
            </Box>
            <Box mt={2}>
                <Paper elevation={0} sx={{padding: '10px'}}>
                    <StyledFilterContainer mb={3} sx={{maxWidth: '30%', textAlign: 'right'}}>
                        <Box>
                            <ProviderFilterOrganism
                                setFilter={setFilter}
                                filter={filter} />
                        </Box>
                    </StyledFilterContainer>
                    <PaginatedListMolecule
                        filter={filter}
                        newRow={newRow}
                        updated={updatedItem}
                        idToDelete={toDelete}
                        renderFunc={(item: ClientModel) => {
                            return <ProviderRow provider={item} onActionSelected={handleActionSelect} />;
                        }}
                        header={
                            <StyledHeaderContainer sx={{ paddingY: '10px' }}>
                                <Grid container>
                                    <StyledGridItem sx={{paddingLeft: '5px'}} item md={6} xs={12}>
                                        <Trans>Name</Trans>
                                    </StyledGridItem>
                                    <StyledGridItem item md={2} xs={12}>
                                        <Trans>Email</Trans>
                                    </StyledGridItem>
                                    <StyledGridItem item md={2} xs={12}>
                                        <Trans>Phone</Trans>
                                    </StyledGridItem>
                                    <StyledGridItem item md={2} xs={12}></StyledGridItem>
                                </Grid>
                            </StyledHeaderContainer>
                        }
                        service={ClientsService}/>
                </Paper>
            </Box>
        </>
    );
};

export default ProvidersPage;
