import * as React from 'react';
import { Grid, Box, Button, CircularProgress, Alert } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectMolecule }  from '@molecules/AsyncSelect';
import { Trans } from '@lingui/macro';
import TextFieldAtom from '@atoms/TextField';
import IAssetCreateOrganism, { IFormValues } from './interface';
import createAssetSchema from '@validation/createAsset';
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import AssetsService from '@services/AssetsService';
import SwitchMolecule from '@molecules/Switch';
import AttributesListMolecule from '@molecules/AttributesList';
import { fetchFilter } from '@stores/reducers/filtersReducer';
import { useAppDispatch } from '@stores/hooks';

/**
 * Including a double-layout mode ('patch' and 'normal')
 * @param mode 
 * @returns 
 */
const AssetCreateOrganism = ({ mode, asset, onNewAsset, onCancel }: IAssetCreateOrganism) => {
	
	const [ sErrors, setSErrors ] = React.useState(null);
	const [ msg, setMsg ] = React.useState('');
	const [ success, setSuccess ] = React.useState<boolean>(false);
	const [ attributesValid, setAttributesValid ] = React.useState<boolean>(true);
	
	const dispatch = useAppDispatch();

	const formInstance = useForm<IFormValues>({
		defaultValues: {
			type_id: asset ? asset.type_id ? asset.type_id : undefined : '',
			category_id: asset ? asset.category_id ? asset.category_id : undefined : undefined,
			name: asset ? asset.name ? asset.name : undefined : undefined,
			location_id: asset ? asset.location_id ? asset.location_id : undefined : '',
			status_id: asset ? asset.status_id ? asset.status_id : undefined : '',
			available: asset ? asset.available ? asset.available : false : false,
			attributes: []
		},
		mode: 'onChange',
		reValidateMode: 'onSubmit',
		resolver: yupResolver(createAssetSchema),
	});
	
	const { mutate: createAssetMutation, isLoading } = useMutation(
		(formValues:IFormValues) => (mode === 'patch' && asset
			? AssetsService.put(formValues, asset.id)
			: AssetsService.create(formValues)).then((res: any) => {
			if(mode !== 'patch') {
				setMsg(res.getMsgString())
			}
			if(!res.hasErrors()) {
				dispatch(fetchFilter('assets'));
				if(mode !== 'patch') {
					setSuccess(true);
				}
				toast(res.getMsgString(), {
					type: 'success'
				});
				if(onNewAsset) {
					onNewAsset(res.getData());
				}
			} else {
				setSErrors(res.getErrors())
				toast(res.getMsgString(), {
					type: 'error'
				});
			}
		})
	);

	const getAttributesList = (values: any) => {
		setValue('attributes', values)
	}

	const {
		control,
		setValue,
		formState: { isValid },
		handleSubmit: handleHookSubmit,
	} = formInstance;

	const handleSubmit = (data: IFormValues) => {
		const values = {...data};
		createAssetMutation(values);
	}

	return (
		<Box sx={{ width: '100%' }}>
			<form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
				<FormProvider {...formInstance }>
					{
						success ? (
							<React.Fragment>
								{
									msg ? 
										<Box mt={2}><Alert severity="success">{ msg }</Alert></Box>
									: null
								}
							</React.Fragment>
						) : (
						<React.Fragment>
							<Box>
								<Grid container mt={1} spacing={2}>
									<Grid item xs={12} sm={12} md={6}>
										<TextFieldAtom
											controlName={'name'}
											required={true}
											variant={'outlined'}
											label={<Trans>Name</Trans>}/>
											{
												sErrors && sErrors['name'] ? 
													<Alert severity='error' icon={false}>
														{ sErrors['name'] }
													</Alert>
												: null
											}
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<SelectMolecule
											control={control}
											sError={sErrors ? sErrors['type_id'] : undefined}
											controlName="type_id"
											required={true}
											emptyValue={''}
											storeName={'activities'}
											storeCollection="types.assets"
											optionValue={'id'}
											variant={'outlined'}
											label={<Trans>Make</Trans>}
										></SelectMolecule>
									</Grid>
								</Grid>
								<Grid container mt={1} spacing={2}>
									<Grid item xs={12} sm={12} md={6}>
										<SelectMolecule
											control={control}
											controlName="category_id"
											required={true}
											emptyValue={''}
											sError={sErrors ? sErrors['category_id'] : undefined}
											optionValue={'id'}
											storeCollection="categories.assets"
											variant={'outlined'}
											label={<Trans>Category</Trans>}
										></SelectMolecule>
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<SelectMolecule
											control={control}
											controlName="status_id"
											required={true}
											emptyValue={''}
											sError={sErrors ? sErrors['status_id'] : undefined}
											storeName={'activities'}
											optionValue={'id'}
											storeCollection="statuses.assets"
											variant={'outlined'}
											label={<Trans>Status</Trans>}
										></SelectMolecule>
									</Grid>
								</Grid>
								<Grid container mt={1} spacing={2}>
									<Grid item xs={12} sm={12} md={6}>
										<SelectMolecule
											control={control}
											controlName="location_id"
											emptyValue={''}
											required={true}
											sError={sErrors ? sErrors['location_id'] : undefined}
											storeCollection="locations"
											optionValue={'id'}
											variant={'outlined'}
											label={<Trans>Department</Trans>}
										></SelectMolecule>
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<SwitchMolecule
											controlName={'available'}
											control={control}
											label={'Available'}
										/>
										{
											sErrors && sErrors['available'] ? 
												<Alert severity='error' icon={false}>
													{ sErrors['available'] }
												</Alert>
											: null
										}
									</Grid>
								</Grid>
							</Box>
							<Box mt={1}>
								{
									mode !== 'patch' && <AttributesListMolecule
										onFormChange={getAttributesList}
										notifyValidation={setAttributesValid}
										modelType={'assets'}/> 
								}
							</Box>
							<Box mt={2}>	
								{
									sErrors ? 
										<Alert severity="error">{ msg }</Alert>
									: null
								}
								<Box mt={2} sx={{textAlign:'right'}}>
									{
										onCancel &&
											<Button sx={{marginRight: '5px'}} onClick={(event: any) => onCancel(event)} type="button" className='cancel-btn' variant="outlined">
											{
												<Trans>Cancel</Trans>
											}
											</Button>
									}
									<Button type="submit" disabled={!isValid || !attributesValid} variant="contained">
									{
										isLoading ?
											<CircularProgress />
										: mode === 'patch' ? <Trans>Update asset</Trans> : <Trans>Create asset</Trans>
									}
									</Button>
								</Box>
							</Box>
						</React.Fragment>)
					}
				</FormProvider>
			</form>
		</Box>
	);
}

export default AssetCreateOrganism;