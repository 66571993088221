export const ENVIRONMENT = {
  local: process.env.NODE_ENV === "development",
  production: process.env.NODE_ENV === "production",
};

export const LOCAL_STORAGE_KEYS = {
  LOCALE: "lang",
  USER: "user",
};

export const ADMIN_ID = 3;

export const PAGES = [
  { label: "Activities", href: "/activities" },
  { label: "Assets", href: "/assets" },
  { label: "People", href: "/people" },
  { label: "Settings", href: "/settings/users" },
];

export const IDS = {
  ROLES: {
    USER: 1,
    ADMIN: 2,
  },
  CUSTOMERS: {
    CLIENT: 11,
    PROVIDER: 12,
  },
};
