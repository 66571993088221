import * as React from 'react';
import { Grid, Box, Button, CircularProgress, Alert, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectMolecule }  from '@molecules/AsyncSelect';
import { Trans } from '@lingui/macro';
import TextFieldAtom from '@atoms/TextField';
import IActivityCreateOrganism, { IFormValues } from './interface';
import createActivitySchema from '@validation/createActivity';
import { FormProvider, useForm } from "react-hook-form";
import DateFieldMolecule from '@molecules/DateField';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import ActivitiesService from '@services/ActivitiesService';
import SwitchMolecule from '@molecules/Switch';
import store from '@stores/store';
import moment from 'moment';
import { AutocompleteMolecule } from '@molecules/AsyncAutocomplete';
import { IDS } from '@utils/constants';
import { UserModel } from '@models/UserModel';
import { ClientModel } from '@models/ClientModel';


/**
 * Including a double-layout mode ('patch' and 'normal')
 * @param mode 
 * @returns 
 */
const ActivityDetailFormOrganism = ({ mode, activity, onSave, onCancel }: IActivityCreateOrganism) => {
	
	const [ sErrors, setSErrors ] = React.useState(null);
	const [ msg, setMsg ] = React.useState('');
	const [ success, setSuccess ] = React.useState<boolean>(false);
	const [ userSelected, setUserSelected ] = React.useState<any>(null);
	const [ assetSelected, setAssetSelected ] = React.useState<any>(null);

	const formInstance = useForm<IFormValues>({
		defaultValues: {
			type_id: activity ? activity.type_id ? activity.type_id : undefined : '',
			category_id: activity ? activity.category_id ? activity.category_id : undefined : undefined,
			date: activity ? activity.date ? activity.date : undefined : undefined,
			user_id: activity ? activity.user_id ? activity.user_id : undefined : undefined,
			asset_id: activity ? activity.asset_id ? activity.asset_id : '' : '',
			customer_id: activity ? activity.customer_id ? activity.customer_id : undefined : undefined,
			client_job_id: activity ? activity.client_job_id ? activity.client_job_id : undefined : undefined,
			job_id: activity ? activity.job_id ? activity.job_id : undefined : undefined,
			partner_id: activity ? activity.partner_id ? activity.partner_id : undefined : undefined,
			is_purchase: activity ? activity.is_purchase ? true : false : false,
			notes: ''
		},
		mode: 'onChange',
		reValidateMode: 'onSubmit',
		resolver: yupResolver(createActivitySchema),
	});
	
	const { mutate: createActivityMutation, isLoading } = useMutation(
		(formValues:IFormValues) => ActivitiesService.put(formValues, activity.id).then((res: any) => {
			if(!res.hasErrors()) {
				if(mode !== 'patch') {
					setSuccess(true);
					setMsg(res.getMsgString())
				}
				toast(res.getMsgString(), {
					type: 'success'
				});
				if(onSave) {
					onSave(res.getData())
				}
			} else {
				setSErrors(res.getErrors())
				toast(res.getMsgString(), {
					type: 'error'
				});
			}
		})
	);

	const {
		watch,
		getValues,
		setValue,
		control,
		formState: { isValid },
		handleSubmit: handleHookSubmit,
	} = formInstance;

	const userId = watch('user_id');
	const assetId = watch('asset_id');
	const inspector = watch('is_purchase');

	const handleSubmit = (data: IFormValues) => {
		const values = {...data};
		if(data.date) {
			values.date = moment(data.date).format('YYYY/MM/DD');
		}
		createActivityMutation(values);
	}

	React.useEffect(() => {
		if(!inspector) {
			setValue('partner_id', null);
		}
	},
	[inspector, setValue]);
	
	React.useEffect(() => {
		if(store.getState().filters.filters.assets) {
			setAssetSelected(store.getState().filters.filters.assets?.find((item: any) => item.id === assetId));
		}
	}, [assetId]);

	React.useEffect(() => {
		if(store.getState().filters.filters.assets) {
			setUserSelected(store.getState().filters.filters.users?.find((item: any) => item.id === userId));
		}
	}, [userId]);

	return (
		<Box sx={{ width: '100%' }}>
			{
				success ? (
					<React.Fragment>
						{
							msg ? 
								<Box mt={2}><Alert severity="success">{ msg }</Alert></Box>
							: null
						}
					</React.Fragment>
				) : (
				<React.Fragment>
					{ /* Step 1 */ }
					<form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
						<FormProvider {...formInstance }>
							<Grid container>
								<Grid item md={6}>
									<Box>
										<Typography>Internal JOB ID: { activity?.job_id ? activity?.job_id : '--' }</Typography>
									</Box>
								</Grid>
								<Grid item md={6}>
									<SelectMolecule
										control={control}
										sError={sErrors ? sErrors['category_id'] : undefined}
										controlName="category_id"
										storeCollection="categories.activities"
										optionValue={'id'}
										variant={'outlined'}
										label={<Trans>Status</Trans>}
									></SelectMolecule>
								</Grid>
							</Grid>
							<Box>
								<Grid container mt={1} spacing={2}>
									<Grid item md={6}>
										<AutocompleteMolecule
											control={control}
											controlName="user_id"
											listId={'activities-filter-owner'}
											storeCollection="users"
											variant={'outlined'}
											except={(user: UserModel) => user.role ? user.role.id !== IDS.ROLES.USER : false}
											optionValue={'id'}
											emptyValue={{full_name: ''}}
											getOptionLabel={(user:any) => `${user.full_name}`}
											optionLabel={'name'}
											label={<Trans>Owner</Trans>}
										></AutocompleteMolecule>
									</Grid>
									<Grid item sm={12} md={6}>
										<Grid container mt={1}>
											<Grid item sm={12} md={6}>
												<Typography variant='caption'>
													<Trans>Role</Trans>
												</Typography>
												<Typography>{ userSelected?.category ? userSelected.category.name : '--' }</Typography>
											</Grid>
											<Grid item sm={12} md={6}>
												<Typography variant='caption'>
													<Trans>Seniority</Trans>
												</Typography>
												<Typography>{ userSelected?.type?.name }</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container mt={1} spacing={2}>
									<Grid item sm={12} md={6}>
										<AutocompleteMolecule
											control={control}
											controlName="asset_id"
											sError={sErrors ? sErrors['asset_id'] : undefined}
											listId={'activities-edit-asset'}
											storeCollection="assets"
											variant={'outlined'}
											optionValue={'id'}
											emptyValue={{name: ''}}
											getOptionLabel={(user:any) => `${user.name}`}
											optionLabel={'name'}
											label={<Trans>Asset</Trans>}
										></AutocompleteMolecule>
									</Grid>
									<Grid item sm={12} md={6}>
										<Grid container mt={1}>
											<Grid item md={6}>
												<Typography variant='caption'>
													<Trans>Category</Trans>
												</Typography>
												<Typography>{ assetSelected?.category?.name}</Typography>
											</Grid>
											<Grid item sm={12} md={6}>
												<Typography variant='caption'>
													<Trans>Department</Trans>
												</Typography>
												<Typography>{ assetSelected?.location?.name}</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container mt={1} spacing={2}>
									<Grid item sm={12} md={6}>
										<SelectMolecule
											required={true}
											control={control}
											sError={sErrors ? sErrors['type_id'] : undefined}
											controlName="type_id"
											storeName={'activities'}
											storeCollection="types.activities"
											optionValue={'id'}
											variant={'outlined'}
											label={<Trans>Activity type</Trans>}
										></SelectMolecule>
									</Grid>
									<Grid item sm={12} md={6}>
										<DateFieldMolecule
											control={control}
											controlName={'date'}
											inputFormat={'dd/MM/yyyy'}
											label={<Trans>Date</Trans>}
											sError={sErrors ? sErrors['date'] : undefined}
										></DateFieldMolecule>
									</Grid>
								</Grid>
								<Grid container mt={1} spacing={2}>
									<Grid item sm={12} md={6}>
										<TextFieldAtom
											controlName={'job_id'}
											variant={'outlined'}
											label={<Trans>Job id</Trans>}/>
											{
												sErrors && sErrors['job_id'] ? 
													<Alert severity='error' icon={false}>
														{ sErrors['job_id'] }
													</Alert>
												: null
											}
									</Grid>
									<Grid item sm={12} md={6}></Grid>
								</Grid>
								<Grid container mt={1} spacing={2}>
									<Grid item sm={12} md={6}>
										<AutocompleteMolecule
											control={control}
											controlName="customer_id"
											sError={sErrors ? sErrors['customer_id'] : undefined}
											listId={'activities-edit-customer'}
											storeCollection="customers"
											variant={'outlined'}
											optionValue={'id'}
											except={(client: ClientModel) => client.type ? client.type.id !== IDS.CUSTOMERS.CLIENT : false}
											emptyValue={{name: ''}}
											getOptionLabel={(user:any) => `${user.name}`}
											optionLabel={'name'}
											label={<Trans>Client</Trans>}
										></AutocompleteMolecule>
									</Grid>
									<Grid item sm={12} md={6}>
										<TextFieldAtom
											controlName={'client_job_id'}
											variant={'outlined'}
											label={<Trans>Client job id</Trans>}/>
											{
												sErrors && sErrors['client_job_id'] ? 
													<Alert severity='error' icon={false}>
														{ sErrors['client_job_id'] }
													</Alert>
												: null
											}
									</Grid>
								</Grid>
								<Grid container spacing={2} mt={1}>
									<Grid item md={6} sx={{display: 'flex', alignItems: 'center'}}>
										<SwitchMolecule
											controlName={'is_purchase'}
											control={control}
											label={'Purchase inspector'}
										/>
										{
											sErrors && sErrors['is_purchase'] ? 
												<Alert severity='error' icon={false}>
													{ sErrors['is_purchase'] }
												</Alert>
											: null
										}
									</Grid>
									<Grid item md={6}>
										{
											getValues('is_purchase') ? 
												<AutocompleteMolecule
													control={control}
													controlName="partner_id"
													sError={sErrors ? sErrors['partner_id'] : undefined}
													listId={'activities-edit-partner'}
													storeCollection="partners"
													variant={'outlined'}
													optionValue={'id'}
													emptyValue={{name: ''}}
													getOptionLabel={(partner:any) => `${partner.name}`}
													optionLabel={'name'}
													label={<Trans>Partner</Trans>}
												></AutocompleteMolecule>
											: null
										}
									</Grid>
								</Grid>
							</Box>
							{ /* Step 2 */ }
							<Box mt={2}>
								{
									mode !== 'patch' ?
										<Grid container mt={1} spacing={2}>
											<Grid item md={6}>
												<TextFieldAtom
													controlName={'client_job_id'}
													variant={'outlined'}
													label={<Trans>Client job id</Trans>}/>
													{
														sErrors && sErrors['client_job_id'] ? 
															<Alert severity='error' icon={false}>
																{ sErrors['client_job_id'] }
															</Alert>
														: null
													}
											</Grid>
											<Grid item sm={12} md={6}>
												<SwitchMolecule
													controlName={'is_purchase'}
													control={control}
													label={'Purchase inspector'}
												/>
												{
													sErrors && sErrors['is_purchase'] ? 
														<Alert severity='error' icon={false}>
															{ sErrors['is_purchase'] }
														</Alert>
													: null
												}
											</Grid>
										</Grid>
									: null
								}
								{
									mode !== 'patch' ? 
										<Grid container mt={1} spacing={2}>
											<Grid item md={12} lg={12}>
												<TextFieldAtom
													controlName={'notes'}
													maxRows={4}
													minRows={2}
													variant={'outlined'}
													label={<Trans>Notes</Trans>}
													multiline={true}
												/>
												{
													sErrors && sErrors['notes'] ? 
														<Alert severity='error' icon={false}>
															{ sErrors['notes'] }
														</Alert>
													: null
												}
											</Grid>
										</Grid>
									: null
								}
								{
									sErrors ? 
										<Alert severity="error">{ msg }</Alert>
									: null
								}
								<Box mt={2} sx={{textAlign:'right'}}>
									{
										onCancel && <Button onClick={() => onCancel()} sx={{marginRight: '5px'}} className='cancel-btn' variant="contained">
										{
											<Trans>Cancel</Trans>
										}
										</Button>
									}
									<Button type="submit" disabled={!isValid} variant="contained">
									{
										isLoading ?
											<CircularProgress />
										: <Trans>Update activity</Trans>
									}
									</Button>
								</Box>
							</Box>
						</FormProvider>
					</form>
				</React.Fragment>)
			}
		</Box>
	);
}

export default ActivityDetailFormOrganism;