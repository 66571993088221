import IDateFieldMolecule from './interface';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import { Controller } from "react-hook-form";
import ConnectForm from '@utils/ConnectForm';
import { UseFormReturn, FieldValues } from "react-hook-form";

/**
 * Please note:
 * in order to make it work while using a react-hook-form
 * you must wrap this component in a <FormProvider {...formMethods}> provided by react-hook-form
 * import { FormProvider, useForm } from "react-hook-form";
 * <FormProvider {...formMethods}>
 *  <DateFieldMolecule />
 * </FormProvider>
 * @param IAsyncSelectMolecule 
 * @returns 
 */
const DateFieldMolecule = ({ controlName, label, control, inputFormat, sError }: IDateFieldMolecule) => {

    return (
        <ConnectForm>
            {({ register, setValue, formState }: UseFormReturn<FieldValues, any>) => 
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <>
                                <DatePicker
                                    label={ label }
                                    onChange={(e:any) => setValue(controlName, e, { shouldValidate: true }) }
                                    inputFormat={inputFormat}
                                    value={value ? value : null}
                                    renderInput={(params) => 
                                        <TextField disabled={true} {...register(controlName)} {...params} fullWidth />
                                    }
                                />
                                {
                                    !!formState.errors && formState.errors[controlName] && formState.errors[controlName]?.message ? 
                                        <FormHelperText>{!!formState.errors && formState.errors[controlName] && formState.errors[controlName]?.message as String}</FormHelperText>
                                    : null
                                }
                                {
                                    sError ? 
                                        <Alert severity='error' icon={false}>
                                            { sError }
                                        </Alert>
                                    : null
                                }
                            </>
                          )}
                          name={controlName}
                          control={control}
                    />
                </LocalizationProvider>
            }
        </ConnectForm>
    );
}

DateFieldMolecule.defaultProps = {
  inputFormat: 'yyyy/MM/dd',
  controlName: null,
  label: '',
  control: null
}

export default DateFieldMolecule;