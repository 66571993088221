import * as React from 'react';
import { Grid, Box, Button, CircularProgress, Alert } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from '@lingui/macro';
import TextFieldAtom from '@atoms/TextField';
import IClientCreateOrganism, { IFormValues } from './interface';
import createClientSchema from '@validation/createClient';
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import ClientsService from '@services/ClientsService';
import { IDS } from '@utils/constants';
import { fetchFilter } from '@stores/reducers/filtersReducer';
import { useAppDispatch } from '@stores/hooks';

/**
 * Including a double-layout mode ('patch' and 'normal')
 * @param mode 
 * @returns 
 */
const ClientCreateOrganism = ({ mode, client, onNewClient, onUpdate, hideAttributes }: IClientCreateOrganism) => {
	
	const [ sErrors, setSErrors ] = React.useState(null);
	const [ msg, setMsg ] = React.useState('');
	const [ success, setSuccess ] = React.useState<boolean>(false);

	const dispatch = useAppDispatch();

	const formInstance = useForm<IFormValues>({
		defaultValues: {
			type_id: IDS.CUSTOMERS.CLIENT,
			name: client ? client.name ? client.name : undefined : '',
			email: client ? client.email ? client.email : undefined : '',
			phone: client ? client.phone ? client.phone : undefined : ''
		},
		mode: 'onChange',
		reValidateMode: 'onSubmit',
		resolver: yupResolver(createClientSchema),
	});
	
	const { mutate: createAssetMutation, isLoading } = useMutation(
		(formValues:IFormValues) => (mode === 'patch' && client
			? ClientsService.put(formValues, client.id)
			: ClientsService.create(formValues)).then((res: any) => {
			setMsg(res.getMsgString())
			if(!res.hasErrors()) {
				dispatch(fetchFilter('customers'));
				setSuccess(true);
				toast(res.getMsgString(), {
					type: 'success'
				});
				if(onNewClient && !client) {
					onNewClient(res.getData())
				}
				if(onUpdate && client) {
					onUpdate(res.getData())
				}
			} else {
				setSErrors(res.getErrors())
				toast(res.getMsgString(), {
					type: 'error'
				});
			}
		})
	);

	const {
		formState: { isValid },
		handleSubmit: handleHookSubmit,
	} = formInstance;

	const handleSubmit = (data: IFormValues) => {
		const values = {...data};
		createAssetMutation(values);
	}

	return (
		<Box sx={{ width: '100%' }}>
			<form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
				<FormProvider {...formInstance }>
					{
						success ? (
							<React.Fragment>
								{
									msg ? 
										<Box mt={2}><Alert severity="success">{ msg }</Alert></Box>
									: null
								}
							</React.Fragment>
						) : (
						<React.Fragment>
							<Box>
								<Grid container mt={1} spacing={2}>
									<Grid item md={6}>
										<TextFieldAtom
											controlName={'name'}
											required={true}
											variant={'outlined'}
											label={<Trans>Name</Trans>}/>
											{
												sErrors && sErrors['name'] ? 
													<Alert severity='error' icon={false}>
														{ sErrors['name'] }
													</Alert>
												: null
											}
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<TextFieldAtom
											controlName={'email'}
											required={true}
											variant={'outlined'}
											label={<Trans>Email</Trans>}/>
											{
												sErrors && sErrors['email'] ? 
													<Alert severity='error' icon={false}>
														{ sErrors['email'] }
													</Alert>
												: null
											}
									</Grid>
								</Grid>
								<Grid container mt={1} spacing={2}>
									<Grid item xs={12} sm={12} md={6}>
										<TextFieldAtom
											controlName={'phone'}
											variant={'outlined'}
											label={<Trans>Phone</Trans>}/>
											{
												sErrors && sErrors['phone'] ? 
													<Alert severity='error' icon={false}>
														{ sErrors['phone'] }
													</Alert>
												: null
											}
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										
									</Grid>
								</Grid>
							</Box>
							<Box mt={2}>	
								{
									sErrors ? 
										<Alert severity="error">{ msg }</Alert>
									: null
								}
								<Box mt={2} sx={{textAlign:'right'}}>
									<Button type="submit" disabled={!isValid} variant="contained">
									{
										isLoading ?
											<CircularProgress />
										: client ? <Trans>Update client</Trans> : <Trans>Create client</Trans>
									}
									</Button>
								</Box>
							</Box>
						</React.Fragment>)
					}
				</FormProvider>
			</form>
		</Box>
	);
}

export default ClientCreateOrganism;