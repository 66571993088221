import { CoreBaseService } from '@core/services/Base.service';
import { MaintenanceModel } from '@models/MaintenanceModel';

class MaintenancesService extends CoreBaseService {
    constructor() {
        super(MaintenanceModel);
    }
}

export default new MaintenancesService();
