import { useState, ChangeEvent, memo, useCallback } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import type { INote } from '@interfaces/INote';
import type { INotesOrganismProps } from './interfaces';
import { t, Trans } from '@lingui/macro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import NoteRowItem from '@organisms/NoteRowItem';
import NotesService from '@services/NotesService';
import { Wrapper, GroupActionButton } from './styled';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Alert } from '@mui/material';
import SpinnerAtom from '@atoms/Spinner';
import ArticleIcon from '@mui/icons-material/Article';
import { useConfirm } from 'material-ui-confirm';
import { useUser } from '@hooks';


const NotesOrganism = ({ model_id, model_type }: INotesOrganismProps) => {
	
	const user = useUser();

	const { data, refetch, isLoading } = useQuery<unknown, unknown, INote[]>(
		`activity-notes${model_id}`,
		() => NotesService.get('', { model_id, model_type }).then((res: any) => res.data),
		{ refetchOnWindowFocus: false, cacheTime: 0, refetchOnMount: true },
	);

	const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
	const [newNote, setNewNote] = useState<string>('');
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const items = data || [];
	const rowsCount = items.length;
	const selectedItemsLength = selectedItems.length;
	const hasSelectedItems = !!selectedItems.length;
	const confirm = useConfirm();

	const handleDelete = useCallback(
		(item: INote) => {
			confirm({ description: 'This action is permanent!' })
			.then(() => {
				NotesService.delete(item.id)
				.then((res: any) => {
					toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });
					refetch();
				})
				.catch((res: any) => {
					toast(t`Generic error`, { type: 'error' });
				});
			});
		},
		[refetch],
	);

	const addNote = () => {
		NotesService.create({text: newNote, model_id, model_type})
		.then((res: any) => {
			refetch();
			setIsCreateMode(false);
			toast(res.getMsgString(), {
				type: res.hasErrors() ? 'error' : 'success'
			})
		})
	}

  const handleMultipleDelete = () => {
	confirm({ description: 'This action is permanent!' })
	.then(() => {
		if (selectedItemsLength === 0) {
			return;
		}

		if (selectedItemsLength === 1) {
			handleDelete(items.find(({ id }) => id === selectedItems[0])!);
		}

		Promise.all(selectedItems.map((id) => NotesService.delete(id)))
			.then(() => {
				toast(t`Notes deleted successfully`, { type: 'success' });
				setSelectedItems([]);
			})
			.catch(() => {
				toast(t`Generic error`, { type: 'error' });
			})
			.finally(() => {
				refetch();
			});
		});
  	};

	const toggleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedItems(items.map(({ id }) => id));
		} else {
			setSelectedItems([]);
		}
 	};

	const toggleSelectSingleItem = useCallback((event: ChangeEvent<HTMLInputElement>, id: number) => {
		if (event.target.checked) {
			setSelectedItems((prevState) => [...prevState, id]);
		} else {
			setSelectedItems((prevState) => prevState.filter((selectedId) => selectedId !== id));
		}
	}, []);

	const handleOpenNewNote = () => {
		setIsCreateMode(true);
		setSelectedItems([]);
	};

	return (
		<Box sx={{ flexGrow: 1, maxWidth: { /*md: 752,*/ xs: '100%' } }}>
			<Box sx={{ display: 'grid', gridTemplateColumns: '100%' }}>
				{isCreateMode ? (
					<Box p={1}>
						<TextareaAutosize
							maxRows={6}
							onChange={(e) => setNewNote(e.target.value)}
							minRows={3}
							placeholder="Insert note"
							style={{ width: '100%' }}
					/>
					<Box sx={{textAlign: 'right'}}>
						<Button 
							sx={{marginRight: '5px'}}
							onClick={() => setIsCreateMode(false)}
							className='cancel-btn'
							variant="outlined"
							color="error">
								<Trans>CANCEL</Trans>
						</Button>
						{
							newNote ? 
								<Button onClick={addNote} variant="contained" color="success"><Trans>ADD</Trans></Button>
							: null
						}
					</Box>
				  </Box>
				) : (
				<Wrapper>
					<Stack direction="row" spacing={2} sx={{marginTop: '10px'}}>
						{
							user.can('notes.create') ? 
								<GroupActionButton sx={{margin:'0 auto', maxWidth: '50%'}} color="primary" onClick={handleOpenNewNote}>
									<ArticleIcon></ArticleIcon>&nbsp;<Trans>ADD NOTE</Trans>
								</GroupActionButton>
							: null
						}
						{
							hasSelectedItems && user.can('notes.delete') ?
								<GroupActionButton isVisible={hasSelectedItems} color="error" onClick={handleMultipleDelete}>
									<Trans>Delete</Trans>
								</GroupActionButton>
							: null
						}
					</Stack>
					{
						isLoading && <Box mt={1}><SpinnerAtom></SpinnerAtom></Box>
					}
					{
						!isLoading && !items.length ?
							<Alert sx={{margin: '20px'}} severity='info'><Trans>There are no notes</Trans></Alert>
						: null
					}
					{
						items && items.length ? 
							<TableContainer sx={{ my: 2, maxHeight: 440, width: '100%', maxWidth: '100%' }}>
								<Table stickyHeader size="small">
									<TableHead>
										<TableRow>
											{
												user.can('notes.delete') && <TableCell padding="checkbox">
													<Checkbox
														indeterminate={selectedItemsLength > 0 && selectedItemsLength < rowsCount}
														checked={rowsCount > 0 && selectedItemsLength === rowsCount}
														inputProps={{ 'aria-label': t`select all` }}
														onChange={toggleSelectAll}
													/>
											</TableCell>
											}
											<TableCell align="center">
												{/*<Trans>Note</Trans>*/}
											</TableCell>
											{
												user.can('notes.edit') || user.can('notes.delete') ?
													<TableCell align="center">
														{/*<Trans>Actions</Trans>*/}
													</TableCell>
												: <></>
											}
										</TableRow>
									</TableHead>
									<TableBody sx={{ width: 'inherit' }}>
									{items.map((item) => (
										<NoteRowItem
											onNoteModified={refetch}
											key={item.id}
											isSelected={selectedItems.some((id) => id === item.id)}
											note={item}
											toggleSelect={toggleSelectSingleItem}
											handleDelete={handleDelete}
										/>
									))}
									</TableBody>
								</Table>
							</TableContainer>
						: null
					}
				</Wrapper>
				)}
			</Box>
		</Box>
	);
};

export default memo(NotesOrganism);
