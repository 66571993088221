
import { CoreBaseModel } from '@core/models/Base.model';
import { INote } from '@interfaces/INote';

export class NotesModel extends CoreBaseModel implements INote {
    
    id: number;
    created_at: string;
    text: string;
    documents: any;
    model_id: number;
    model_type: string;
    visible: boolean;
    user: any;
    user_id: number;

    constructor(data: any) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    static getResourceName() {
        return 'notes';
    }

    readableDate(format = 'dd/mm/yy'): string {
        const date = new Date(this.created_at);
        format = format.replace('dd', String(date.getDate()));
        format = format.replace('mm', String(date.getMonth()));
        format = format.replace('yy', String(date.getFullYear()));
        return format;
    }

}