import { styled } from '@mui/system';

interface ILogoProps {
}

export const StyledProviderRow = styled('div')((props: ILogoProps) => ({
  padding: '12px 0px',
  '& .MuiGrid-root': {
    display: 'flex',
    alignItems: 'center'
  },
  fontSize: '13px',
  '& p': {
    fontSize: '13px'
  },
  '& button': {
    fontSize: '13px'
  }
  // marginBottom: ({ theme }: ILogoProps) => theme.spacing(3),
}));
