import { useState, useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Drawer, Box } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import CallIcon from '@mui/icons-material/Call';
import FolderIcon from '@mui/icons-material/Folder';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { StyledPaper } from './styled';
import DrawerLayout from '@layouts/drawer';
import ActivityChat from '@organisms/ActivityChat';
import { ADMIN_ID } from '@utils/constants';
import { useSelector } from 'react-redux';
import LayoutModal from '@layouts/modal';
import Modal from '@molecules/Modal';
import VideoCallOrganism from '@organisms/VideoCall';


const InspectorActivityMobMenu = ({ id, activity, onSelection, defaultTab }: any) => {

    const [value, setValue] = useState('recents');
    const [ videoCallFullscreen, setVideoCallFullscreen ] = useState(false);
    const [ tab, setTab ] = useState(defaultTab);
    const [ showChat, setShowChat ] = useState(false);
    const [ showVideoCall, setShowVideoCall ] = useState(false);
    const presenceChannel = useSelector((state: any) => state.app.presenceChannel);
    
    const handleChange = (event: any, newValue: string) => {
        setValue(newValue);
    };

    const closeVideChatDrawer = () => {
        setShowVideoCall(false);
        presenceChannel.trigger(`client-signal-${activity?.user.id}`, {
            type: 'message',
            action: 'modal-closed'
        });
      }

    const select = (alias: string) => {
        // If previous tab was 'call', send pusher notification. TODO (optimize this, trigger ONLY if call was suspended)
        if(tab === 'call') {
            presenceChannel.trigger(`client-signal-${ADMIN_ID}`, {
                type: 'message',
                action: 'modal-closed'
            });
        }
        if(alias === 'chat') {
           return setShowChat(true);
        }
        if(alias === 'call') {
            return setShowVideoCall(true);
        }
        return setTab(alias);
    }

    useEffect(() => {
        if(onSelection) {
            onSelection(tab);
        }
    }, [tab, onSelection]);

    return (
        <>
            { 
                activity && id ? 
                    <StyledPaper elevation={3}>
                        <Drawer
                            sx={{width: '100vw'}}
                            anchor={'right'}
                            open={showChat}
                            >
                                <DrawerLayout title={
                                    <Box>
                                        <Box>
                                            <Trans>Activity</Trans>: { activity?.job_id }
                                        </Box>
                                        <Box>
                                            <Trans>Inspector</Trans>: { activity?.user?.full_name }
                                        </Box>
                                    </Box>
                                } onCloseClick={() => setShowChat(false)}>
                                    { activity && showChat && <ActivityChat activity={activity}/> }
                                </DrawerLayout>
                        </Drawer>
                        <Modal fullscreen={videoCallFullscreen} open={showVideoCall ? true : false} set_open={closeVideChatDrawer}>
                            <LayoutModal title={<Box>Call the admin</Box>}>
                                {
                                    showVideoCall && <Box>
                                        <VideoCallOrganism
                                            onHangUp={closeVideChatDrawer}
                                            iAmInspector={true}
                                            isFullScreen={videoCallFullscreen}
                                            onRequestFullscreen={() => setVideoCallFullscreen(videoCallFullscreen ? false : true)}
                                            activity={activity}
                                        />
                                    </Box>
                                }
                            </LayoutModal>
                        </Modal>
                        <BottomNavigation sx={{ width: 500 }} value={value} onChange={handleChange}>
                            {
                                [{alias: 'details', route: `/inspector/activity/${ id }/details`, label: t`Details`, icon: <FindInPageIcon />},
                                {alias: 'chat', route: `/inspector/activity/${ id }/chat`, label: t`Chat`, icon: <MessageIcon />},
                                {alias: 'call', route: `/inspector/activity/${ id }/call`, label: t`Call`, icon: <CallIcon />},
                                {alias: 'docs', route: `/inspector/activity/${ id }/docs`, label: t`Docs`, icon: <FolderIcon />}].map((item: any, index: number) => {
                                    const off = (!activity.checkedIn() && (item.alias === 'call'));
                                    return (
                                        <BottomNavigationAction
                                            key={index}
                                            sx={{ opacity: off ? .5 : 1}}
                                            disabled={off}
                                            onClick={() => select(item.alias)}
                                            label={item.label}
                                            className={tab === item.alias ? 'Mui-selected' : ''}
                                            showLabel={true}
                                            value={item.label}
                                            icon={item.icon}
                                        />
                                    )
                                })
                            }
                        </BottomNavigation>
                    </StyledPaper>
                : null
            }
        </>
    );
};

export default InspectorActivityMobMenu;
