
import { CoreBaseModel } from '@core/models/Base.model';
import { PersonModel } from './PersonModel';



export class MaintenanceModel extends CoreBaseModel {
    
    date_at: string;
    user: PersonModel;
    user_id: number;
    id: number;
    created_at: string;
    model_id: number;
    model_type: number;
    updated_at: string;
    notes: string;
    status: {alias: string, class: "default" | "info" | "success" | "warning" | "error" | "primary", id: number, name: string};

    constructor(data: any) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    readableDate(format = 'dd/mm/yy'): string {
        const date = new Date(this.date_at);
        format = format.replace('dd', String(date.getDate()));
        format = format.replace('mm', String(date.getMonth()));
        format = format.replace('yy', String(date.getFullYear()));
        return format;
    }

    static getSubTypesList() {
        return [
            { object: 'user', model: PersonModel },
        ];
    }

    static getResourceName() {
        return 'maintenances';
    }

}