import { Grid, Paper, Typography, Box } from '@mui/material';
import { StyledActivityRow } from './styled';
import IPersonRow from './interface';
import MultiButtonMolecule from '@molecules/MultiButtonMolecule';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

const PeopleRowMolecule = ({ person, onActionSelected }: IPersonRow) => {

  const actionSelected = (item: any) => {
    if(onActionSelected) {
      onActionSelected(item, person);
    }
  }

  return (
    <>
        <Paper sx={{border: '0px'}} elevation={0} variant='outlined'>
			<StyledActivityRow>
				<Grid container sx={{alignItems: 'center'}}>
					<Grid item sx={{display: 'flex', alignItems: 'center'}} md={2} xs={12}>
						<Box mr={1}>
							<Typography>{ person.active ? <CheckCircleOutlinedIcon color='primary' /> : <ErrorOutlinedIcon color='warning'/> }</Typography>
						</Box>
					</Grid>
					<Grid item md={2} xs={12}>
						{ person.full_name ? person.full_name : '--' }
					</Grid>
					<Grid item md={2} xs={12}>
						{ person.category ? person.category.name : '--' }
					</Grid>
					<Grid item md={2} xs={12}>
						{ person.type ? person.type.name : '--'}
					</Grid>
					<Grid item md={2} xs={12}>
						{ person.location ? person.location.name : '--' }
					</Grid>
					<Grid sx={{textAlign: 'right'}} item md={2} xs={12}>
						<MultiButtonMolecule onSelected={actionSelected} options={[
							{label: 'Details', id: 1, data: { personId: person.id }},
							{label: 'Delete', id: 4, data: { personId: person.id }}]}
						/>
					</Grid>
				</Grid>
			</StyledActivityRow>
		</Paper>
    </>
  )
}


export default PeopleRowMolecule;