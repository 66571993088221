import { styled } from '@mui/system';
import { Button } from '@mui/material';

export const PageHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledCheckButton = styled(Button)`
  position: fixed;
  bottom: 60px;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  border: 15px solid #ebebeb;
  box-shadow: 0px 0px 5px 0px #989898;
`;