import Box from '@mui/material/Box';
import { StyledBody } from './styled';
import Icon from '@atoms/Icon';


interface ILayoutDrawer {
  title?: string | JSX.Element;
  children: any;
  onCloseClick?: () => void;
}

const DrawerLayout = ({ children, title, onCloseClick }: ILayoutDrawer) => {

  const close = () => {
    if(onCloseClick) {
      onCloseClick();
    }
  }

  return (
    <>
      <StyledBody>
        {
          title ? <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box>
              { title }
            </Box>
            <Box sx={{float: 'right'}}>
              <Icon on_click={close} icon="light-icon-circle-x"/>
            </Box>
          </Box>
          : <Box sx={{float: 'right'}}>
          <Icon on_click={close} icon="light-icon-circle-x"/>
        </Box>
        }
        { children }
        <br/>
      </StyledBody>
    </>
  )
}


export default DrawerLayout;