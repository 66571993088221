import { Fragment, useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import SpinnerAtom from '@atoms/Spinner';
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { set } from '@stores/reducers/titleReducer';
import { useQuery, useMutation } from 'react-query';
import { QuestionModel } from '@models/QuestionModel';
import QuestionsService from '@services/QuestionsService';
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import RatingMolecule from '@molecules/RatingInput';
import { Trans, t } from '@lingui/macro';
import AnswersService from '@services/AnswersService';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import ActivitiesService from '@services/ActivitiesService';
import { ActivityModel } from '@models/ActivityModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { useConfirm } from 'material-ui-confirm';

interface IEvaluatePage {
    activity: ActivityModel;
    onSave: () => void;
}

const InspectorActivityEvaluatePage = ({ activity, onSave }: IEvaluatePage) => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const [ questions, setResults ] = useState<Array<QuestionModel>>([]);

    useEffect(() => {
        dispatch(set('Checkout'))
    }, [dispatch])

    const { isLoading, data } = useQuery(
        `activity-questions-${id}`,
        () => QuestionsService.list('activities').then((res: any) => res),
        {
          refetchOnWindowFocus: false,
          cacheTime: 0,
          refetchOnMount: false,
        },
    );

    useEffect(() => {
        if (!isLoading && data) {
          setResults(data.data);
        }
    }, [isLoading, data]);

    return (
        <>
            {
                questions.length ? 
                    <Box mt={3}>
                        <EvaluationForm onSave={onSave} activity={activity} questions={questions} model_id={id}/>
                    </Box>
                : <SpinnerAtom size={'50px'}></SpinnerAtom> 
            }
        </>
    );
};


const EvaluationForm = ({ questions, model_id, activity, onSave }: any) => {

    const [ evaluated, setEvaluated ] = useState(false);
    const confirm = useConfirm();

    let configuration: ObjectShape = {};
    const formSchema: any = {};
    formSchema['vote'] = Yup.number().min(1).required();

    configuration['rating'] = Yup.array()
        .of(Yup.object()
        .shape(formSchema))
        .required("Must have fields")
        .min(1, "Minimum of 1 field")

    const yupSchema = Yup.object().shape(configuration);

    const formMethods = useForm({
        resolver: yupResolver(yupSchema)
    });

    const { control, handleSubmit: handleHookSubmit, formState: { isValid } } = formMethods;

    const { fields, append } = useFieldArray({
        control,
        name: "rating",
    });

    useEffect(() => {
        questions.forEach((question: QuestionModel) => {
            append({vote: 0, question_id: question.id, model_id, model_type: 'activities'})
        });
    }, [questions]);

    const { mutate: evaluateMutation, isLoading } = useMutation(
		(formValues: any) => (AnswersService.create(formValues)).then((res: any) => {})
	);

    const handleSubmit = (answers: any) => {
        confirm({ description: t`Are you sure you want to check out this activity?` })
        .then(() => {
            Promise.all(answers.rating.map((answer: any) => evaluateMutation(answer)))
                .then(() => {
                    toast(t`Check out of the activity performed successfully`, { type: 'success' });
                })
                .catch(() => {
                    toast(t`Generic error`, { type: 'error' });
                })
                .finally(() => {
                    // Change status
                    if(activity && activity.nextStatus()) {
                        ActivitiesService.changeStatus(activity.nextStatus().id, model_id).then((res: any) => {
                            setEvaluated(true);
                            onSave();
                        });
                    } else {
                        onSave();
                    }
                });
        });
    }

    return (
        <>
            {
                evaluated ? 
                    <Box mt={3}>
                        {/** TODO stavo intossicato de droga? */}
                        {/*<Navigate to={`/inspector/activity/${model_id}/details?a=checkedout`} />*/}
                    </Box>
                : <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
                {
                    questions && <Fragment>
                        {fields.map((field :any, index: number) => {
                            return (
                                <Box key={index} sx={{margin:'0 auto', textAlign:'center', marginBottom: '20px'}} >
                                    <Typography>{ questions[index].title }</Typography>
                                    <FormProvider {...formMethods}>
                                        <RatingMolecule size={'2.5rem'} control={control} controlName={`rating.${index}.vote`}></RatingMolecule>
                                    </FormProvider>
                                </Box>
                            )
                        })}
                    </Fragment>
                }
                    <Box sx={{margin:'0 auto', textAlign:'center', marginBottom: '20px'}} >
                        <Button disabled={!isValid} type="submit" variant="contained">
                        {
                            isLoading ?
                                <CircularProgress />
                            : <Trans>Check out</Trans>
                        }
                        </Button>
                    </Box>
                </form>
            }
        </>
    )

}

export default InspectorActivityEvaluatePage;
