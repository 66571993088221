import { CoreBaseService } from '@core/services/Base.service';

// Reference model
import { AuthModel } from '@models/AuthModel';


class AuthService extends CoreBaseService {

    constructor() {
        super(AuthModel);
    }
  
}

export default new AuthService();