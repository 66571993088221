
import { CoreBaseModel } from '@core/models/Base.model';


class StatusLogModel extends CoreBaseModel {

    created_at: string;
    id: number;
    model_id:  number;
    model_type: string;
    status: {id: number, alias: string, name: string, class: "default" | "info" | "primary" | "secondary" | "error" | "success" | "warning" | undefined}
    status_id: number;
    updated_at: string;

    constructor(data: any) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

}


export default StatusLogModel;