
import { Fragment } from 'react';
import LayoutBase from '@layouts/base';
import { Outlet } from 'react-router-dom';


const SettingsPage = () => {
    return (
        <Fragment>
            <LayoutBase><Outlet/></LayoutBase>
        </Fragment>
    )
};

export default SettingsPage;
