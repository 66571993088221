import { CoreBaseModel } from '@core/models/Base.model';

export class ClientModel extends CoreBaseModel {

    id: number;
    name: string;
    last_name: string;
    email: string;
    type: any;
    type_id: number;
    phone: string;

    constructor(data: any) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    static getResourceName() {
        return 'customers';
    }
}
