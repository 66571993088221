
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Paper } from '@mui/material';

export const StyledNewRowBox = styled(Box)((props) => ({
    marginTop: '10px',
    '.MuiPaper-root': {
        marginBottom: '2px'
    }
}));


export const StyledContainer = styled(Paper)((props) => ({
    border: '1px solid #DDDDDD',
    padding: '5px',
    '& .row': {
        borderBottom: '1px solid #DDDDDD'
    }
}));
