import { styled } from '@mui/system';

interface ILogoProps {
}

export const StyledNotificationRow = styled('div')((props: ILogoProps) => ({
  padding: '16px 24px',
  fontSize: '13px',
  marginBottom: '7px',
  '& svg': {
    color: 'rgb(204, 204, 204)'
  },
  '& p': {
    fontSize: '13px'
  },
  '& button': {
    fontSize: '13px'
  },
  '& .date *': {
    fontWeight: 'bold',
    fontSize: '15px'
  },
  '&:hover': {
    cursor: 'pointer',
    background: '#eeeeee'
  }
  // marginBottom: ({ theme }: ILogoProps) => theme.spacing(3),
}));
