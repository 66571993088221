import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IPeopleQualificationsOrganism from './interface';
import { Trans } from '@lingui/macro';
import { StyledAttributesBox } from './styled';
import AttributesGroupFormOrganism from '@organisms/AttributesGroupForm';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import AttributesService from '@services/AttributesService';
import { useQuery } from 'react-query';
import Alert from '@mui/material/Alert';
import { AttributeModel } from '@models/AttributeModel';
import SpinnerAtom from '@atoms/Spinner';
import IconButton from '@mui/material/IconButton';

// Accepted models
import { PeopleModel } from '@models/PeopleModel';
import { AssetsModel } from '@models/AssetsModel';


const AttributesOrganism = ({ model, model_type }: IPeopleQualificationsOrganism) => {

	const [ attrToEdit, setAttrToEdit ] = useState<PeopleModel | AssetsModel | null>(null);
    const [ attributes, setAttributes ] = useState<any>([]);
	const [ modelData, setPersonData ] = useState<PeopleModel | undefined | AssetsModel>(undefined);
	const [ accStatuses, setAccStatuses ] = useState<any>({});

	useEffect(() => {
		setPersonData(model)
	}, [model])

	const { data, isLoading } = useQuery(`attributes-${model_type}`, () => AttributesService.get('', {model_type}), {
		refetchOnWindowFocus: false,
		refetchOnMount: false
	});

	/**
	 * On Save update the interface
	 * @param newGroupData
	 */
	const onGroupModified = (newGroupData: AttributeModel) => {
		if(modelData) {
			modelData.attributes = newGroupData.attributes;
			setPersonData(modelData);
		}
		setAttrToEdit(null);
	}

	useEffect(() => {
		if(!isLoading && data) {
			if(model) {
                // @ts-ignore
                setAttributes(data.data);
			}
		}
	}, [data, isLoading, model])

	useEffect(() => {
		if(modelData) {
			const statuses: any = {}
			attributes.forEach((group: any) => {
				const personAssignedGroup = modelData.getAttributes(group.alias);
				statuses[group.id] = {expanded: personAssignedGroup.length ? true : false}
			});
			setAccStatuses(statuses);
		}
	}, [attributes, modelData])

	const accordionChangeStatus = (expanded: boolean, groupId: number) => {
		accStatuses[groupId].expanded = expanded;
	}

	const editGroup = (e:any, group: any) => {
		setAttrToEdit(group);
		if(group && !accStatuses[group.id].expanded) {
			return 0;
		} else {
			// any case stop propagation
			return e.stopPropagation();
		}
	}

	return <>
		{
			!isLoading && modelData && attributes ? 
				attributes.map((group: any, groupIndex: number) => {
                    const personAssignedGroup = modelData.getAttributes(group.alias);
					return (
						<StyledAttributesBox key={groupIndex}>
							<Accordion onChange={(event: any, expanded: boolean) => accordionChangeStatus(expanded, group.id)} defaultExpanded={personAssignedGroup.length ? true : false} elevation={0}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${groupIndex}a-content`} id={`panel${groupIndex}a-header`}>
									<Typography variant='h5'>
										<b>
											{ group.name }
										</b>
									</Typography>
									<Typography variant='caption'>({personAssignedGroup.length})</Typography>
									<Box sx={{textAlign:'right', fontSize: '12px'}}>
										{
											attrToEdit && attrToEdit.id === group.id ?
												<Tooltip title={<Trans>Cancel</Trans>} placement="top">
													<IconButton onClick={(e: any) => editGroup(e, null)}><CancelIcon></CancelIcon></IconButton>
												</Tooltip>
											: <Tooltip title={<React.Fragment><Trans>Modify the</Trans> {group.name}</React.Fragment>} placement="top">
													<IconButton onClick={(e: any) => editGroup(e, group)}><EditIcon color='success'></EditIcon></IconButton>
											</Tooltip>
										}
									</Box>
								</AccordionSummary>
								<AccordionDetails>
									<Box>
										{
											(!attrToEdit || attrToEdit.id !== group.id) ?
                                                personAssignedGroup.length ?
                                                    personAssignedGroup.map((attributesGroup: any) => {
														return <Box sx={{display: 'flex'}}>
															{
																attributesGroup.map((attribute: any, itemIndex: number) => {
																	return (
																		<Box mt={1} sx={{marginRight: '40px'}} key={itemIndex}>
																			<Typography><b>{ attribute.name }</b></Typography>
																			<Typography>{ attribute.value }</Typography>
																		</Box>
																	)

																})
															}	
														</Box>
                                                    })
                                                : <Box mb={1}><Alert severity='warning'><Trans>There are no attributes set for this group</Trans></Alert></Box>
											: null
										}
									</Box>
									{
										attrToEdit && attrToEdit.id === group.id ?
											<AttributesGroupFormOrganism
												showTitle={false}
												model_id={modelData.id}
												model_type={model_type}
												onSave={onGroupModified}
												model={modelData}
                                                personAssignedGroup={personAssignedGroup}
												group={group}/>
										: null
									}
								</AccordionDetails>
						</Accordion>
					</StyledAttributesBox>)
				})
			: <Box>
				<SpinnerAtom></SpinnerAtom>
			</Box>
		}
    </>;
};

export default AttributesOrganism;
