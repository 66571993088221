import { ChangeEvent, useEffect, useState } from 'react';
import IPaginatedList from './interface';
import { Box, Pagination, Alert } from '@mui/material';
import SpinnerAtom from '@atoms/Spinner';
import { Trans } from '@lingui/macro';
import { StyledContainer } from './styled';

const PaginatedListMolecule = (props: IPaginatedList) => {
    const { service, newRow, idToDelete, updated, filter, header, renderFunc, perPage, showPaginationOnTop } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [results, setResult] = useState<Array<any>>([]);
    const [lastPage, setLastPage] = useState(undefined);
    const [isLoading, setLoading] = useState(true);

    const list = (page?: number, loading = true) => {
        if(loading)
            setLoading(true);
        page = page || 1;
        setCurrentPage(page);
        service.get('', { ...filter, page, perpage: perPage }).then((res: any) => {
            if(loading)
                setLoading(false);
            setResult(res.getData().data);
            setLastPage(res.getData().last_page);
        });
    };

    useEffect(() => {
        list(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        list(currentPage, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refetchKey]);

    const handlePageClick = (event: ChangeEvent<unknown>, page: number) => {
        list(page);
    };

    useEffect(() => {
        if(newRow) {
            results.unshift(newRow);
            const newA = [...results];
            setResult(newA)
        }
    }, [newRow])

    useEffect(() => {
        if(idToDelete) {
            setResult(results.filter((item: any) => item.id !== idToDelete));
        }
    }, [idToDelete])

    useEffect(() => {
        if(updated) {
            const toUpdateIndex = results.findIndex((item: any) => item.id === updated.id);
            results[toUpdateIndex] = updated;
            setResult([...results]);
        }
    }, [updated])

    return (
        <>
            <StyledContainer elevation={0}>
                {showPaginationOnTop && results.length && !isLoading && (
                    <Box mb={2}>
                        <Pagination
                            count={lastPage}
                            page={currentPage}
                            onChange={handlePageClick}
                            variant="outlined"
                            shape="rounded"
                        />
                    </Box>
                )}
                <Box sx={{padding: '2px'}}>
                    { !isLoading && header}
                    {/*newRows && (
                        <StyledNewRowBox>
                            {newRows.map((item: any, index: number) => {
                                return <Box mb={1} key={index}>{renderFunc(item)} </Box>
                            })}
                        </StyledNewRowBox>
                    )*/}
                    {isLoading ? (
                        <SpinnerAtom />
                    ) : (
                        results?.map((item: any, index: number) => {
                            return (
                                <Box className='row' mb={1} key={index}>
                                    {renderFunc(item)}
                                </Box>
                            );
                        })
                    )}
                </Box>
                {!isLoading &&
                    (results.length ? (
                        <Box mt={2}>
                            <Pagination
                                count={lastPage}
                                page={currentPage}
                                onChange={handlePageClick}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Box>
                    ) : (
                        <Alert severity="warning">
                            <Trans>No documents were found</Trans>
                        </Alert>
                    ))}
                </StyledContainer>
        </>
    );
};

PaginatedListMolecule.defaultProps = {
    perPage: 5,
    listStateVar: 'list',
    showPaginationOnTop: false,
};

export default PaginatedListMolecule;
