
import { CoreBaseModel } from '@core/models/Base.model';
import { ICategory } from '@interfaces/ICategory';
import { IType } from '@interfaces/IType';

export class PersonModel extends CoreBaseModel {

    date: string;
    attributes: Array<any>;
    asset: any;
    active: boolean;
    role: any;
    role_id: number;
    location: any;
    location_id: number;
    phone: string;
    type: IType;
    type_id: number;
    category: ICategory;
    category_id: number;
    created_at: string;
    id: number;
    deleted_at: null
    email: string;
    surname: string;
    name: string;
    full_name: string;
    updated_at: string;

    constructor(data: any) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    static getResourceName() {
        return 'users';
    }

    getAttributes(alias?: string) {
        if(alias) {
            const group = this.attributes.find((group: any) => group.alias === alias);
            if(group) return group.attributes;
            return [];
        } else {
            return this.attributes;
        }
    }

}