import { useFormContext } from "react-hook-form";

interface IConnectForm {
  children: any; // ?
}

const ConnectForm = ({ children }: IConnectForm) => {
  const methods = useFormContext();
  return children({ ...methods });
};

export default ConnectForm;