import { memo } from 'react';
import { UseFormReturn, FieldValues } from 'react-hook-form';
import type { CustomMemoType } from '@appTypes/GlobalInterfaces';
import type { ITextFieldAtom } from './interface';
import TextField from '@mui/material/TextField';
import ConnectForm from '@utils/ConnectForm';

const TextFieldAtom = <N extends string = string>({ controlName, helperText, ...restProps }: ITextFieldAtom<N>) => (
    <ConnectForm>
        {({ register, formState }: UseFormReturn<FieldValues, any>) => (
            <TextField
                helperText={
                    helperText ? helperText : !!formState.errors && formState.errors[controlName] && formState.errors[controlName]?.message as String
                }
                {...restProps}
                {...register(controlName)}
            />
        )}
    </ConnectForm>
);

TextFieldAtom.defaultProps = {
    sx: { width: '100%' },
    multiline: false,
    maxRows: 2,
    variant: 'outlined',
    label: 'Text field',
    minRows: 1,
};

export default (memo as CustomMemoType)(TextFieldAtom);
