import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { ENVIRONMENT } from '@helpers/constants';
import counterReducer from '@stores/reducers/counterReducer';
import authReducer from '@stores/reducers/authReducer';
import chatReducer from '@stores/reducers/chatReducer';
import activitiesReducer from '@stores/reducers/activitiesReducer';
import filtersReducers from '@stores/reducers/filtersReducer';
import menuReducers from '@stores/reducers/menuReducers';
import titleReducers from '@stores/reducers/titleReducer';
import appReducer from '@stores/reducers/appReducer';
import notificationsReducers from '@stores/reducers/notificationsReducer';

const store = configureStore({
  devTools: ENVIRONMENT.local,
  reducer: {
    counter: counterReducer,
    notifications: notificationsReducers,
    auth: authReducer,
    app: appReducer,
    chat: chatReducer,
    activities: activitiesReducer,
    filters: filtersReducers,
    menu: menuReducers,
    title: titleReducers
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false }),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
