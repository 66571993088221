import { createSelector } from '@reduxjs/toolkit';
import type { IFilterState } from '@stores/reducers/filtersReducer';
import type { ICategory } from '@interfaces/ICategory';
import type { IType } from '@interfaces/IType';
import { RootState } from '@stores/store';

export const selectFilters = (state: RootState) => state.filters;

export const filtersSelector = createSelector(selectFilters, (filters) => filters);

export const filtersCategoriesSelector = (resourceName: keyof NonNullable<IFilterState['filters']['categories']>) =>
    createSelector(filtersSelector, (filters): ICategory[] => filters.filters?.categories?.[resourceName] || []);

export const filtersTypeSelector = (resourceName: keyof NonNullable<IFilterState['filters']['types']>) =>
    createSelector(filtersSelector, (filters): IType[] => filters.filters?.types?.[resourceName] || []);

export const filtersRolesSelector = (resourceName: keyof NonNullable<IFilterState['filters']['categories']>) =>
    createSelector(filtersSelector, (filters): IType[] => filters.filters?.categories?.[resourceName] || []);

export const filterSelector = (resourceName: keyof NonNullable<IFilterState['filters']>) =>
    createSelector(filtersSelector, (filters): IType[] => filters.filters?.locations || []);

