
import React, { useState, useEffect, memo } from 'react';
import IAsyncSelectMolecule, { ISelectMolecule } from './interface';
import { InputLabel, Select, MenuItem, FormControl, FormHelperText } from '@mui/material';
import { useQuery } from 'react-query';
import { Controller } from "react-hook-form";
import ConnectForm from '@utils/ConnectForm';
import { UseFormReturn, FieldValues } from "react-hook-form";
import { Trans } from '@lingui/macro';
import Skeleton from 'react-loading-skeleton';
import { GetSubObjectByString } from '@utils/helpers';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

/**
 * Please note:
 * in order to make it work while using a react-hook-form
 * you must wrap this component in a <FormProvider {...formMethods}> provided by react-hook-form
 * import { FormProvider, useForm } from "react-hook-form";
 * <FormProvider {...formMethods}>
 *  <AsyncSelectMolecule controlName="name" label='Pick a name' />
 * </FormProvider>
 * @param IAsyncSelectMolecule 
 * @returns 
 */
const AsyncSelectMolecule = ({ 
    service, 
    serviceMethod,
    label, 
    disabled,
    filters, 
    controlName,
    helperText,
    required,
    sError,
    storeName,
    storeCollection, // can be for instance -> 'types' or 'types.activites' to get a sub list
    cacheTime,
    except,
    variant,
    emptyValue,
    listId,
    optionLabel,
    multiple,
    optionValue,
    control }: IAsyncSelectMolecule) => {

    const [ result, setResult ] = useState<Array<any>>([]);

    const { isLoading, data } = useQuery(listId, () => service ? (!serviceMethod ? service.get() : service[serviceMethod]()) : filters, {
        refetchOnWindowFocus: false,
        cacheTime: cacheTime,
        refetchOnMount: false
    });
    
    useEffect(() => {
        if(!isLoading && data) {
            if(except) {
                // TODO !except.includes(optionValue ? item[optionValue] : --> ITEM ? )
                if(typeof except === 'function') {
                    data.data = data.data.filter((item: any) => {
                        return !except(item);
                    });
                }
                if(Array.isArray(except)) {
                    data.data = data.data.filter((item: any) => {
                        return !except.includes(optionValue ? item[optionValue] : item);
                    });
                }
            }
            // @ts-ignore
            setResult(data.data);
        }
    }, [isLoading, data]);

    return (
        <FormControl fullWidth>
            {
                /*isLoading ? 
                    <Skeleton height={'3.36rem'}/>
                :*/ 
                    <React.Fragment>
                        <InputLabel>{ label } {required ? '*' : ''}</InputLabel>
                        <ConnectForm>
                            {({ register, formState }: UseFormReturn<FieldValues, any>) => 
                                <Controller
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <React.Fragment>
                                                <Select 
                                                    multiple={multiple}
                                                    {...register(controlName)}
                                                    label={`${label}`}
                                                    disabled={disabled}
                                                    required={required}
                                                    variant={variant}
                                                    value={value ? value : ''}>
                                                        <MenuItem value={emptyValue !== undefined ? emptyValue : ''}>
                                                            <em><Trans>None</Trans></em>
                                                        </MenuItem>
                                                    {
                                                        result.map((item: any) => {
                                                            return (
                                                                <MenuItem key={item.id} value={ !! optionValue ? item[optionValue] : item }>
                                                                    { typeof optionLabel == 'string' ? item[optionLabel ? optionLabel : 'label'] : optionLabel ? optionLabel(item) : 'no-label' }
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                                {
                                                    helperText ? helperText : !!formState.errors && formState.errors[controlName] && formState.errors[controlName]?.message ? 
                                                        <FormHelperText>{helperText ? <Box sx={{ color: 'red', fontSize: '12px'}}>helperText</Box> : !!formState.errors && formState.errors[controlName] && formState.errors[controlName]?.message as String}</FormHelperText>
                                                    : null
                                                }
                                        </React.Fragment>
                                    )}
                                    name={controlName}
                                    control={control}
                                />}
                        </ConnectForm>
                        {
                            sError ? 
                                <Alert severity='error' icon={false}>
                                    { sError }
                                </Alert>
                            : null
                        }
                    </React.Fragment>     
            }
        </FormControl>
    );
}

AsyncSelectMolecule.defaultProps = {
    cacheTime: 0,
    listId: String(Math.random()),
    variant: 'filled',
    optionLabel: 'name',
    optionValue: null
}

export default memo(AsyncSelectMolecule);


/**
 * Select Molecule
 * @param param0 
 * @returns 
 */
const SelectMoleculeB = ({ 
    label,
    controlName,
    storeCollection, // can be for instance -> 'types' or 'types.activites' to get a sub list
    cacheTime,
    variant,
    disabled,
    helperText,
    required,
    emptyValue,
    multiple,
    sError,
    storeName,
    listId,
    except,
    optionLabel,
    optionValue,
    control }: ISelectMolecule) => {

    const filters = useSelector(
        (state: any) => storeCollection ? GetSubObjectByString(state.filters.filters, storeCollection): null);

    return (
        filters ? 
            <AsyncSelectMolecule
                label={label}
                controlName={controlName}
                multiple={multiple}
                cacheTime={cacheTime}
                except={except}
                disabled={disabled}
                emptyValue={emptyValue}
                helperText={helperText}
                variant={variant}
                required={required}
                storeName={storeName}
                listId={`cached-list_${Math.random() * 1000}`}
                optionLabel={optionLabel}
                optionValue={optionValue}
                control={control}
                sError={sError}
                filters={{data: filters}} />
        : null
    );
}

SelectMoleculeB.defaultProps = {
    cacheTime: 0,
    listId: `cached-list_${Math.random() * 1000}`,
    variant: 'filled',
    optionLabel: 'name',
    optionValue: null
}

export const SelectMolecule = memo(SelectMoleculeB)