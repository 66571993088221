import React, { memo } from 'react';
import NotificationsService from '@services/NotificationsService';
import { NotificationModel } from '@models/NotificationModel';
import SpinnerAtom from '@atoms/Spinner';
import { COLORS } from '@theme/Colors';
import ChatNotification from '../NotificationTypes/Chat';
import { Trans } from '@lingui/macro';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Box, MenuItem, Alert } from '@mui/material';


const NotificationsList = ({ onNotificationClick }: any) => {

    const { isLoading, data } = useQuery('notifications', () => NotificationsService.get('', {perpage: 5, page: 1}), {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        refetchOnMount: false
    });

    return (
        <React.Fragment>
            {
                isLoading && <SpinnerAtom/>
            }
            {
                // @ts-ignore
                data && !isLoading && data.getData().data.map((notification: NotificationModel, index: number) => {
                    return ( 
                        <MenuItem key={index}>
                            { notification.isChat() && <ChatNotification onClick={onNotificationClick} notification={notification}/> }
                        </MenuItem> 
                    )
                })
            }
            {
                // @ts-ignore
                !isLoading && data && !data.getData().data.length ? <Alert variant='info'><Trans>No notifications</Trans></Alert> : null
            }
            {
                ! isLoading ?
                    <Link to={'/notifications'}>
                        <Box sx={{padding: '15px', background: COLORS.blue.primary, color: 'white', textAlign: 'center'}}>
                            <Trans>Show all</Trans>
                        </Box>
                    </Link>
                : null
            }
        </React.Fragment>
    );
};


export default memo(NotificationsList);