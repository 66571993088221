import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { StyledActivityRow } from './styled';
import ChipAtom from '@atoms/Chip';
import IActivityRow from './interface';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import DatePrintAtom from '@atoms/DatePrint';


const ActivityRowInspectorMolecule = ({ activity, onActionSelected, hideMultButton }: IActivityRow) => {

  return (
    <>
        <Paper sx={{ marginBottom: '0.6rem'}} elevation={0} variant='outlined'>
            <Link to={`/inspector/activity/${activity.id}/details`}>
              <StyledActivityRow>
                  <Grid container sx={{alignItems: 'left'}}>
                      <Grid item sx={{textAlign:'left', display: 'flex', alignItems: 'center'}} mb={1} md={6} xs={6}>
                        <Box  mr={1}>
                          <Typography variant='h5'>
                            {
                              activity.date ? 
                                <DatePrintAtom format='DD/MM/yyyy'>{ activity.date }</DatePrintAtom>
                              : '--'
                            }
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sx={{textAlign:'right'}} md={6}>
                        <Typography variant='body2'>{ activity.customer?.name }</Typography>
                        <Typography variant='body2'>{ activity.job_id }</Typography>
                      </Grid>
                  </Grid>
                  <Grid container sx={{alignItems: 'left'}}>
                      <Grid item sx={{textAlign:'left', display: 'flex', alignItems: 'center'}} mb={1} md={6} xs={6}>
                        <Box  mr={1}>
                          <Typography variant='body1'>
                            {
                              activity.asset ? activity.asset.name : '--'
                            }
                            &nbsp;-&nbsp;
                            {
                              activity.asset ? activity.asset.type.name : '--'
                            }
                          </Typography>
                        </Box>
                      </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={{alignItems: 'left'}}>
                      <Grid item sx={{textAlign:'left', display: 'flex', alignItems: 'center'}} mb={1} md={6} xs={6}>
                        { activity.status ? <Tooltip placement='top' title={activity.status?.name}>
                          <ChipAtom style={{width: '100%'}} color={activity.status.class} variant='filled' label={activity.status.name} /></Tooltip> : '--' }
                      </Grid>
                      <Grid item xs={6} sx={{textAlign:'right'}} md={6}>
                        {
                          activity.type && <ChipAtom label={activity.type.name} />
                        }
                      </Grid>
                  </Grid>
              </StyledActivityRow>
            </Link>
        </Paper>
    </>
  )
}


export default ActivityRowInspectorMolecule;