import { styled } from '@mui/system';
import { Box } from '@mui/material';


export const StyledChatBubbleContainer = styled(Box)(() => ({
    position: 'fixed',
    right: '15px',
    bottom: '55px',
    zIndex: 99,
    textAlign: 'center'
}));