import { CoreBaseModel } from '@core/models/Base.model';
import { UserModel } from './UserModel';

export class NotificationModel extends CoreBaseModel {

    notification_id:number;
    notification: {id: number, model: any, user: UserModel, created_at: string, type: any, model_type: string, model_id: number, user_id:number, type_id: number};
    read_at: string;
    text: string;
    user_id: string;

    constructor(data: any) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    static getResourceName() {
        return 'notifications';
    }

    isChat() {
        return this.notification.type.alias === 'chat_message';
    }

}
