import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';


export const StyledCertificationCard = styled(Box)(({ theme }) => ({
    fontSize: '14px',
    '& .pencil-icon': {
        display: 'none'
    },
    '& .status-icon':{
        display: 'block'
    },
    '&:hover': {
        cursor: 'pointer',
        '& .pencil-icon': {
            display: 'block'
        },
        '& .status-icon':{
            display: 'none'
        }
    }
}));

export const StyledList = styled(Box)(({ theme }) => ({

}));


export const StyledListGrid = styled(Box)(({ theme }) => ({
}));

