import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CoreResponseModel } from '@core/models/Response.model';
import MenuService from '@services/MenuService';

export interface IFilterState {
    isLoading: boolean;
    items: [],
    error: any;
}

const initialState: IFilterState = {
    isLoading: false,
    items: [],
    error: null,
};

export const fetchMenu = createAsyncThunk(
    'menu/get',
    async (filter: object, { rejectWithValue }): Promise<any> => {
        try {
            const res = (await MenuService.get(undefined, filter, true)) as CoreResponseModel;
            if (res.hasErrors()) {
                // @ts-ignore
                throw new Error({
                    msg: res.getMsgString ? res.getMsgString() : 'Errore',
                    fieldErrors: res.getErrors(),
                });
            }
            return res.getData();
        } catch (error: any) {
            console.log('error.message', error.msg);
            throw rejectWithValue(error);
        }
    },
);

export const menuReducers = createSlice({
    name: 'menu',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenu.pending, (state) => {
                state.error = null;
                state.isLoading = true;
            })
            .addCase(fetchMenu.fulfilled, (state, { payload }) => {
                state.items = payload;
            })
            .addCase(fetchMenu.rejected, (state, { payload }) => {
                state.error = payload; // msg, fieldErrors
                state.isLoading = false;
            })
    },
});

export default menuReducers.reducer;
