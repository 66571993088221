import { memo } from 'react';
import type { IDocumentRowItemProps } from './interface';
import { t } from '@lingui/macro';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import LightTooltip from '@atoms/LightTooltip';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useUser } from '@hooks';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


const DocumentRowItem = ({ isSelected, item, toggleSelect, handleDownload, handleDelete, handleView }: IDocumentRowItemProps) => {

  const user = useUser();
  const extension: string | undefined = item.file.split('.').pop();
  const viewAllowedExtensions = ['jpeg', 'jpg', 'png', 'bmp', 'gif', 'pdf'];

  return (
  <TableRow selected={isSelected} hover key={item.id}>
    <TableCell padding="checkbox">
      {
        user.can('documents.delete') && <Checkbox checked={isSelected} onChange={(e) => toggleSelect(e, item.id)} />
      }
    </TableCell>
    <TableCell sx={{ width: '72px', display: 'none' }}>
      <Avatar sx={{width: '30px', height: '30px'}}>
        <FolderIcon fontSize='small'/>
      </Avatar>
    </TableCell>
    <TableCell sx={{ width: '100%', padding: 0 }}>
      <Tooltip title={item.file}>
        <Box sx={{paddingBottom: '6px', overflow:{ xs: 'scroll', md: 'hidden'}, maxWidth: {xs: '150px', md: '100%'}}}>
          {item.file}
        </Box>
      </Tooltip>
    </TableCell>
    <TableCell sx={{ pr: 1, width: '104px', display: 'flex' }} size="small">
      {
        user.can('documents.download') && <LightTooltip title={t`Download`} followCursor>
          <IconButton aria-label={t`download`} onClick={() => handleDownload(item)}>
            <DownloadIcon/>
          </IconButton>
        </LightTooltip>
      }
      {
        handleView && extension && viewAllowedExtensions.includes(extension) && <LightTooltip title={t`View`} followCursor>
          <IconButton aria-label={t`view`} onClick={() => handleView(item)}>
            <RemoveRedEyeIcon/>
          </IconButton>
        </LightTooltip>
      }
      {
        user.can('documents.delete') && <LightTooltip title={t`Delete`} followCursor>
          <IconButton aria-label={t`delete`} onClick={() => handleDelete(item)}>
            <DeleteIcon />
          </IconButton>
        </LightTooltip>
      }
      
    </TableCell>
  </TableRow>)
}

export default memo(DocumentRowItem);
