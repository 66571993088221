
import LogoImg from '@resources/images/group_marchiodark.png';
import { Link } from 'react-router-dom';
import { StyledImg } from './styled';
import ILogo from './interface';


const LogoMolecule = ({ href, size }: ILogo) => {
    return (
        <Link to={href ? href : ''}>
            <StyledImg
                size={size}
                style={{ maxWidth: size === 'big' ? '130px' : size === 'medium' ? '65px' : '45px'}}
                alt='' src={LogoImg}>
            </StyledImg>
        </Link>
    );
};

LogoMolecule.defaultProps = {
    size: 'small'
}
 
export default LogoMolecule;
 