import { Grid, Paper, Button } from '@mui/material';
import { Trans } from '@lingui/macro';
import { SelectMolecule } from '@molecules/AsyncSelect';
import IActivitiesFilterOrganism from './interface';
import { FormProvider, useForm} from "react-hook-form";
import TextFieldAtom from '@atoms/TextField';

interface IFormValues {
  search: string;
  type_id: number | string | null;
}

const NotificationsFilterOrganism = ({ onFormChange }: IActivitiesFilterOrganism) => {

    const formMethods = useForm<IFormValues>({
      mode: 'onChange',
      defaultValues: {
        type_id: undefined,
        search: undefined
      },
      reValidateMode: 'onSubmit'
    })

	const {
		handleSubmit: handleHookSubmit,
	} = formMethods;

	const handleSubmit = (data: IFormValues) => {
		if(onFormChange) {
			onFormChange(formMethods.getValues());
		}
	}

    return (
        <>
			<form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
				<FormProvider {...formMethods} >
					<Grid container spacing={1}>
						<Grid item md={12} xs={12}>
							<Paper elevation={0} variant="outlined">
								<Grid container p={1} spacing={1}>
									<Grid item xs={12} sm={3} md={3}>
										<SelectMolecule
											control={formMethods.control}
											controlName="type_id"
											emptyValue={null}
											optionValue={'id'}
											variant={'outlined'}
											label={'Type'}
											storeCollection="types.notifications_confs"
											optionLabel={'name'}
										></SelectMolecule>
									</Grid>
									<Grid item xs={12} sm={3} md={7}>
										<TextFieldAtom
											controlName="search"
											variant={'outlined'}
											label={<Trans>Search</Trans>}
										></TextFieldAtom>
									</Grid>
									<Grid className='align-center' item xs={12} sm={3} md={2}>
										<Button fullWidth type='submit' variant='contained' color={'info'}>
											Filter
										</Button>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
			</FormProvider>
          </form>
        </>
    )
}

export default NotificationsFilterOrganism;